import "./Header.css"
import images from "../../Asset/Img/image";

function Header() {


  return (
    <div className="header-container">
      <div className="header-content">

        <div className="scroll-icon-container">

          <div className="scroll-icon">
            <img src={images.headerMauseScroll} alt="scroll-icon" />
          </div>

        </div>
      </div>
    </div>
  )
}

export default Header