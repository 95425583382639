import React, { useEffect, useRef } from 'react'
import Sidebar from '../sidebar/Sidebar'
import { BrowserRouter, Route, Link } from 'react-router-dom'
import Routers from '../Routers'
import './layout.css'
import Topnav from '../topnav/TopNav'
import { useSelector, useDispatch } from 'react-redux'
import RoutersLogin from '../RoutersLogin'
import { setUser, authState } from '../../redux/features/AuthSlice'
import { setColor, setMode, } from '../../redux/features/themeSlice'
import images from '../../assets/images/website-login.png'

const Layout = (props) => {

    const themeReducer = useSelector(state => state.theme)

    const authReducer = useSelector(authState)

    const dispatch = useDispatch()

    useEffect(() => {
        const deneme = () => {
            const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')
            const themeColor = localStorage.getItem('colorMode', 'theme-mode-light')
            const user = localStorage.getItem('user')
            dispatch(setUser(JSON.parse(user)))
            dispatch(setColor(themeColor))
            dispatch(setMode(themeClass))
        }
        deneme()
    }, [dispatch])

    const user = authReducer.user

    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const refSiderbar = useRef()

    const toogle = () => {
        ref1.current.classList.toggle('pageSwitcherItem-active')
        ref2.current.classList.toggle('pageSwitcherItem-active')
        ref3.current.classList.toggle('formTitleLink-active')
        ref4.current.classList.toggle('formTitleLink-active')
    }

    return (
        <BrowserRouter>
            {user && <Route render={(props) => (
                <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>

                    <Sidebar refSidebar={refSiderbar} {...props} />
                    <div className='layout__content'>
                        <Topnav sidebarRef={refSiderbar} />
                        <div className="layout__content-main">
                            <Routers />
                        </div>
                    </div>
                </div>
            )} />}
            {!user && <Route render={() => (
                <div>
                    <div className='row login__page'>
                        <div className="col-6  col-sm-12 appAside" >
                            <img className='login-image' src={images} alt="logins" />
                        </div>
                        <div className="col-6  col-sm-12 appForm">
                            <div className="pageSwitcher">
                                <Link ref={ref1} onClick={() => toogle()} to="/" className='pageSwitcherItem pageSwitcherItem-active'>Sign In</Link>
                                {/* <Link ref={ref2}  onClick={()=>toogle() } to="/singup" className='pageSwitcherItem '>Sign Up</Link> */}
                            </div>
                            <div className="formTitle">
                                <Link ref={ref3} onClick={() => toogle()} to="/" className='formTitleLink formTitleLink-active'>Sign In</Link>{" "}
                                {/* or{" "} */}
                                {/* <Link ref={ref4} onClick={()=>toogle() } to="/singup" className="formTitleLink ">Sign Up</Link> */}
                            </div>
                            <div className='Login__form__content'>
                                <RoutersLogin />
                            </div>
                        </div>
                    </div>
                </div>
            )} />
            }
        </BrowserRouter>
    )
}

export default Layout
