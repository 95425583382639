import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'



export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:3002/api/' }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (form) => ({
                url: 'user/getUser',
                method: 'POST',
                body: form
            })
        })
    }),
})

export const { useLoginMutation } = authApi