import "../assets/css/categories.css";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { db } from "../base";

import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  onSnapshot,
  query,
  where,
  getDoc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { Alert, Button, Snackbar } from "@mui/material";
import { Modal } from "../components/modal/Modal";
import FullPageLoading from "../components/fullpageloading/FullPageLoading";

export const Categories = () => {
  const { register, handleSubmit, formState } = useForm({});
  const {
    // register: register2,
    // handleSubmit: handleSubmit2,
    // reset: reset2,
    // formState: formState2,
  } = useForm({});

  const [videoCatagory, setVideoCatagory] = useState("");

  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [videoCatagoryData, setVideoCatagoryData] = useState([]);

  // Düzenleme işlemi için seçilen kategoriyi tutacak state
  const [editingCategory, setEditingCategory] = useState(null);

  // Silme işlemi için seçilen kategoriyi tutacak state
  const [selectedTransferCategory, setSelectedTransferCategory] = useState("");

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseModal = () => {
    setEditingCategory(null);
    setEditModalOpen(false);
  };

  const handleEditClick = (category) => {
    setEditingCategory(category);
    setEditModalOpen(true);
  };

  // Delete modalı kapama fonksiyonu
  const handleCloseDeleteModal = () => {
    setEditingCategory(null);
    setDeleteModalOpen(false);
    setSelectedTransferCategory(""); // Seçili yeni kategoriyi sıfırlayın
  };

  // Kategori silme işlemi için modal açma fonksiyonu
  const handleDeleteClick = (category) => {
    setEditingCategory(category);
    setDeleteModalOpen(true);
  };

  /* alert */
  const [alertMessage, setAlertMessage] = useState("");
  const [snackShow, setSnackShow] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
    setAlertMessage("");
  };

  /* Ekleme ve silme işlemlerinde index düzenlemesi için */
  const updateCategoryIndex = async (categoryRef, newIndex) => {
    try {
      // Kategorileri sıralayalım
      const categoriesQuery = query(collection(db, "categoryAds"));
      const categoryDocs = await getDocs(categoriesQuery);
      const orderedCategories = categoryDocs.docs
        .map((categoryDoc) => ({
          id: categoryDoc.id,
          data: categoryDoc.data(),
        }))
        .sort((a, b) => a.data.index - b.data.index);

      const batch = writeBatch(db);
      orderedCategories.forEach((category, index) => {
        const { id } = category;

        // Güncellenen kategoriyi atlamak için kontrol yapalım
        if (id === categoryRef.id) {
          batch.update(categoryRef, { index: newIndex });
        } else {
          batch.update(doc(db, "categoryAds", id), { index: index + 1 });
        }
      });

      await batch.commit();
      console.log("Kategori sıralamaları güncellendi.");
    } catch (error) {
      console.error(
        "Kategori sıralamaları güncellenirken bir hata oluştu:",
        error
      );
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);
    // const customersRef = collection(db, "categoryAds");

    // addDoc(customersRef, {
    //   icon: data.icon,
    //   name: data.name,
    // });
    try {
      setAddLoading(true);

      const categoryRef = collection(db, "categoryAds");
      const categoriesQuery = query(collection(db, "categoryAds"));
      const categoryDocs = await getDocs(categoriesQuery);

      // Mevcut kategorilerin sayısını alalım
      const categoryCount = categoryDocs.size;

      // Yeni kategori için bir index belirleyelim
      // Eğer hiç kategori yoksa veya mevcut kategorilerin sayısı 0 ise yeni kategoriye index 1 verelim,
      // aksi halde mevcut kategorilerin sayısına 1 ekleyerek yeni kategoriye index değerini belirleyelim
      const newIndex = categoryCount === 0 ? 1 : categoryCount + 1;

      // Kategoriyi ekleyelim
      await addDoc(categoryRef, {
        name: data.name,
        index: newIndex,
        visibility: true,
        icon: data.icon,
      });
      setAlertMessage("Kategori başarıyla eklendi");
      setSnackShow(true);
      console.log("Kategori başarıyla eklendi:", data);

      // Kategori sıralamalarını güncelleyelim
      await updateCategoryIndex(categoryRef, newIndex);
    } catch (error) {
      console.error("Kategori eklenirken bir hata oluştu:", error);
    }

    setAddLoading(false);
  };

  // const onSubmit2 = async (data) => {
  //   const q = query(
  //     collection(db, "categoryAds"),
  //     where("name", "==", data.videoCatagory)
  //   );
  //   console.log(q);
  //   const query2 = await getDocs(q);
  //   query2.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     deleteDoc(doc.ref);
  //     // doc.ref.delete();
  //     // console.log(doc.id, " => ", doc.data());
  //   });
  // };

  // Düzenleme işlemi onaylandığında yapılacak işlemler
  const handleSaveChanges = async () => {
    if (editingCategory) {
      setEditLoading(true);

      try {
        const categoryRef = doc(db, "categoryAds", editingCategory.id);
        const categorySnapshot = await getDoc(categoryRef);
        const currentCategoryData = categorySnapshot.data();
        const currentCategoryIndex = currentCategoryData.index;

        // Yalnızca güncellenmek istenen alanları belirterek güncelleme işlemini yapın
        const categoryData = {
          name: editingCategory.name,
          index: editingCategory.index ? parseInt(editingCategory.index) : 0,
          visibility: editingCategory.visibility
            ? editingCategory.visibility
            : false,
          icon: editingCategory.icon,
        };

        await updateDoc(categoryRef, categoryData);

        setAlertMessage("Kategori başarıyla güncellendi");
        setSnackShow(true);
        console.log("Kategori başarıyla güncellendi:", editingCategory);

        // Kategori indeksi değişmişse diğer kategorilerin sıralamasını güncelle
        if (currentCategoryIndex !== editingCategory.index) {
          const categoriesQuery = query(collection(db, "categoryAds"));
          const categoryDocs = await getDocs(categoriesQuery);

          const batch = writeBatch(db);

          categoryDocs.forEach((categoryDoc) => {
            const categoryData = categoryDoc.data();
            const categoryIndex = categoryData.index;

            // Güncellenen kategoriyi atlamak için kontrol yapalım
            if (categoryDoc.id !== editingCategory.id) {
              if (currentCategoryIndex < editingCategory.index) {
                // Eğer düzenlenen kategori aşağıya taşınıyorsa
                if (
                  categoryIndex > currentCategoryIndex &&
                  categoryIndex <= editingCategory.index
                ) {
                  batch.update(categoryDoc.ref, { index: categoryIndex - 1 });
                }
              } else {
                // Eğer düzenlenen kategori yukarıya taşınıyorsa
                if (
                  categoryIndex < currentCategoryIndex &&
                  categoryIndex >= editingCategory.index
                ) {
                  batch.update(categoryDoc.ref, { index: categoryIndex + 1 });
                }
              }
            }
          });

          await batch.commit();
          console.log("Kategori sıralamaları güncellendi.");
        }
      } catch (error) {
        console.error("Kategori güncellenirken bir hata oluştu:", error);
      }

      setEditLoading(false);
      handleCloseModal();
    }
  };

  // Silme işlemi onaylandığında yapılacak işlemler
  const handleDeleteConfirmed = async () => {
    if (editingCategory && selectedTransferCategory) {
      try {
        setDeleteLoading(true);
        // Kategorisini değiştireceğimiz videoları bulun
        const videosQuery = query(
          collection(db, "videoAds"),
          where("category", "==", editingCategory.name)
        );

        const videoDocs = await getDocs(videosQuery);

        // Videoların kategori alanını güncelleyin ve belgeyi silin
        const promises = videoDocs.docs.map((videoDoc) => {
          return updateDoc(doc(db, "videoAds", videoDoc.id), {
            category: selectedTransferCategory,
          });
        });

        // Tüm güncelleme işlemleri tamamlandığında silme işlemini yapın
        await Promise.all(promises);
        await deleteDoc(doc(db, "categoryAds", editingCategory.id));
        setAlertMessage(
          `Kategori başarıyla silindi ve videolar ${selectedTransferCategory} kategorisine taşındı`
        );
        setSnackShow(true);
        console.log(
          "Kategori başarıyla silindi ve videolar taşındı:",
          editingCategory
        );

        // Kategori silindiğinde kategorilerin index numaralarını güncelle
        const categoryRef = doc(db, "categoryAds", editingCategory.id);
        const newIndex = editingCategory.index + 1;
        await updateCategoryIndex(categoryRef, newIndex);

        handleCloseDeleteModal();
      } catch (error) {
        console.error("Kategori silinirken bir hata oluştu:", error);
      }
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "categoryAds"), (snapshot) => {
      let admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setVideoCatagory(admins);

      let categoriesResponse = snapshot.docs.map((category) => {
        let categoryData = {
          ...category.data(),
          id: category.id,
        };
        return categoryData;
      });
      setVideoCatagoryData(
        categoriesResponse.sort((a, b) => Number(a.index) - Number(b.index))
      );
    });
    return unsub;
  }, []);

  return (
    <div>
      <div className="row">
        <div
          className="card full-width ds-flex flex-column"
          style={{ overflow: "auto" }}
        >
          <h2 className="card-title">Kategori Ekle</h2>
          <div className="col-8 ">
            <div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="category-add-form"
              >
                <div className="input-container">
                  <div className="formlabel">Name : </div>
                  <input
                    className="frominput"
                    {...register("name", { required: true, minLength: 1 })}
                  ></input>
                  {formState.errors.email && (
                    <span>This field is required</span>
                  )}
                </div>
                <div className="input-container">
                  <div className="formlabel">Icon : </div>
                  <input
                    className="frominput"
                    {...register("icon", { required: true, minLength: 1 })}
                  ></input>
                  {formState.errors.email && (
                    <span>This field is required</span>
                  )}
                </div>

                <input className="denemes" type="submit" />
              </form>
            </div>
          </div>
        </div>

        {/* <div className="card full-width ds-flex">
          <div className="formuseradd">
            <form onSubmit={handleSubmit2(onSubmit2)}>
              <div className="form-field">
                <label>Video Katagori</label>
                <div id="customers-selected">
                  <select {...register2("videoCatagory", { required: true })}>
                    <option></option>
                    {videoCatagory &&
                      videoCatagory.map((e, i) => (
                        <option key={i}>{e?.name}</option>
                      ))}
                  </select>
                  {formState2.errors.videoCatagory && <span>Zorunlu alan</span>}
                </div>
              </div>
              <input className="denemes" value={"Sil"} type="submit" />
            </form>
          </div>
        </div> */}

        <div
          className="card full-width ds-flex flex-column"
          style={{ overflow: "auto", marginBottom: "2rem" }}
        >
          <h2 className="card-title">Kategoriler</h2>
          <table className="categories-table">
            <thead>
              <tr className="categories-header">
                <th>Adı</th>
                <th>Sırası</th>
                <th>İcon</th>
                <th>Görünme Durumu</th>
                <th>Düzenle</th>
              </tr>
            </thead>
            <tbody>
              {videoCatagoryData &&
                videoCatagoryData.map((category) => {
                  return (
                    <tr className="categories-row" key={category.id}>
                      <td>{category.name ? category.name : "-"}</td>
                      <td>{category.index ? category.index : "-"}</td>
                      <td>{category.icon ? category.icon : "-"}</td>
                      <td>
                        {category.visibility === true
                          ? "Görünüyor"
                          : category.visibility === false
                          ? "Gizli"
                          : "-"}
                      </td>
                      <td>
                        <div className="buttons">
                          <button
                            className="button-1"
                            onClick={() => handleEditClick(category)}
                          >
                            Düzenle
                          </button>

                          <button
                            className="button-1"
                            onClick={() => handleDeleteClick(category)}
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* Düzenleme Modal */}
        {editModalOpen && (
          <Modal
            children={
              <div className="editing-category-modal">
                {editingCategory && (
                  <form>
                    <h2>Kategoriyi Düzenle</h2>
                    <div className="input-container">
                      <div className="formlabel">Adı:</div>
                      <input
                        className="frominput"
                        value={editingCategory.name}
                        onChange={(e) =>
                          setEditingCategory({
                            ...editingCategory,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-container">
                      <div className="formlabel">Sırası:</div>
                      <input
                        className="frominput"
                        value={editingCategory.index || ""}
                        onChange={(e) =>
                          setEditingCategory({
                            ...editingCategory,
                            index: e.target.value
                              ? parseInt(e.target.value)
                              : null,
                          })
                        }
                      />
                    </div>
                    <div className="input-container">
                      <div className="formlabel">İkon Kodu:</div>
                      <input
                        className="frominput"
                        value={editingCategory.icon || ""}
                        onChange={(e) =>
                          setEditingCategory({
                            ...editingCategory,
                            icon: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="toggleButton" className="formlabel">
                        Görünme Durumu:
                      </label>
                      <div className="full-width">
                        <label htmlFor="toggleButton">
                          <div className="toggle">
                            <input
                              type="checkbox"
                              id="toggleButton"
                              className="form-input"
                              checked={editingCategory.visibility}
                              onChange={(e) =>
                                setEditingCategory({
                                  ...editingCategory,
                                  visibility: e.target.checked,
                                })
                              }
                            />
                            <span id="toggleButton" className="slider"></span>
                          </div>
                          <span className="visibility-status">
                            {editingCategory.visibility ? "Görünüyor" : "Gizli"}
                          </span>
                          {formState.errors.videoAdsTitle && (
                            <div>Zorunlu alan</div>
                          )}
                        </label>
                      </div>
                    </div>
                    <div>
                      <Button
                        onClick={handleSaveChanges}
                        className="edit-button"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            }
            close={handleCloseModal}
          ></Modal>
        )}

        {/* Delete Modal */}
        {deleteModalOpen && (
          <Modal
            children={
              <div className="delete-category-modal">
                <h3> Kategoriyi Sil </h3>
                <p>
                  Sildiğiniz kategoriye ait videolar hangi kategoriye
                  aktarılmasını istersiniz?
                </p>
                <div className="form-field">
                  <label htmlFor="transferCategory" className="formlabel">
                    Transfer Edeceğiniz Kategori:
                  </label>
                  <select
                    id="transferCategory"
                    value={selectedTransferCategory}
                    onChange={(e) =>
                      setSelectedTransferCategory(e.target.value)
                    }
                  >
                    <option value="">Seçiniz</option>
                    {videoCatagoryData.map(
                      (category) =>
                        category.id !== editingCategory?.id && (
                          <option key={category.id} value={category.name}>
                            {category.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
                {!selectedTransferCategory && (
                  <span style={{ color: "red" }}>
                    Lütfen bir kategori seçin!
                  </span>
                )}
                <div className="confirm-buttons">
                  <button
                    className={`button-1 ${
                      selectedTransferCategory ? "" : "disabled-button"
                    } `}
                    onClick={handleDeleteConfirmed}
                    disabled={!selectedTransferCategory}
                  >
                    Sil
                  </button>
                  <button className="button-1" onClick={handleCloseDeleteModal}>
                    İptal
                  </button>
                </div>
              </div>
            }
            close={handleCloseDeleteModal}
          ></Modal>
        )}
      </div>
      {(addLoading || editLoading || deleteLoading) && <FullPageLoading />}

      <Snackbar
        open={snackShow}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {alertMessage ? alertMessage : ""}
        </Alert>
      </Snackbar>
    </div>
  );
};
