import "./AdvertisingActivity.css";

import React, { useEffect, useState, useMemo } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../base";
import { Link } from "react-router-dom";

function AdvertisingActivity({ userData }) {
  const [activeTab, setActiveTab] = useState(1);
  const [advertData, setAdvertData] = useState([]);
  const [data, setData] = useState([]);

  const nows = useMemo(() => new Date(Date.now()), []);
  const week = useMemo(
    () => new Date(nows.getFullYear(), nows.getMonth(), nows.getDate() - 7),
    [nows]
  );
  const mounth = useMemo(
    () => new Date(nows.getFullYear(), nows.getMonth(), nows.getDate() - 30),
    [nows]
  );

  useEffect(() => {
    if (activeTab === 0) {
      setData(advertData.all);
    } else if (advertData) {
      if (activeTab === 1) {
        setData(advertData.aylik);
      } else if (activeTab === 2) {
        setData(advertData.haftalık);
      } else {
        setData(advertData.gunluk);
      }
    }
  }, [advertData, activeTab]);

  //   console.log(userData);
  // console.log(advertData);

  useEffect(() => {
    if (userData.uid !== undefined) {
      let advertisingActivity = userData.watchedList?.reduce(
        (acc, curr) => {
          //reklam detayına firma adını eklemek için
          const q = query(
            collection(db, "videoAds"),
            where("videoId", "==", `${curr.videoAdsId}`)
          );
          getDocs(q).then((data) => {
            let arry = data.docs.map((e) => e.data());
            curr.companyName = arry[0]?.companyName;

            curr.videoDetail = arry[0];
          });

          let date = new Date(curr.time).toLocaleDateString();
          let date2 = new Date(curr.time);

          if (date in acc.tarihbazli) {
            acc.tarihbazli[date].push(curr);
            acc.videoSayisi += 1;
          } else {
            acc.tarihbazli[date] = [];
            acc.tarihbazli[date].push(curr);
            acc.videoSayisi += 1;
            acc.yolculukSayisi += 1;
          }
          acc["all"].push(curr);

          if (date2 >= week && date2 <= nows) {
            //haftalık
            acc["haftalık"].push(curr);
          }
          if (date2 >= mounth && date2 <= nows) {
            acc["aylik"].push(curr);
          }
          if (date === nows.toLocaleDateString()) {
            acc["gunluk"].push(curr);
          }
          return acc;
        },
        {
          videoSayisi: 0,
          tarihbazli: {},
          yolculukSayisi: 0,
          haftalık: [],
          aylik: [],
          gunluk: [],
          all: [],
        }
      );

      // console.log(advertisingActivity);

      setAdvertData(advertisingActivity);
    }
  }, [userData, mounth, nows, week]);

  return (
    <div className="reklam-aktivitesi">
      <div className="reklam-top">
        <div className="baslik">
          Reklam Aktivitesi
          <p className="aciklama">
            İzlenen reklamlardan kazanılan puanları gösterir.
          </p>
        </div>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 0 ? "active-tab" : ""}`}
            onClick={() => setActiveTab(0)}
          >
            Tümü
          </button>
          <button
            className={`tab ${activeTab === 1 ? "active-tab" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            Aylık
          </button>
          <button
            className={`tab ${activeTab === 2 ? "active-tab" : ""}`}
            onClick={() => setActiveTab(2)}
          >
            Haftalık
          </button>
          <button
            className={`tab ${activeTab === 3 ? "active-tab" : ""}`}
            onClick={() => setActiveTab(3)}
          >
            Günlük
          </button>
        </div>
      </div>
      <div className="veri-listesi">
        <div className="reklam-tablo">
          <ul>
            {data && data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <li key={index} className="reklam-satir">
                    <div className="reklam-bilgi">
                      <div className="logo-box">
                        <img src={item.banner} alt="Logo" />
                      </div>
                      <div className="reklam-icerik">
                        <div className="reklam-baslik">
                          <h4>{item.title}</h4>
                          <small>Firma: {item.companyName}</small>
                        </div>

                        <div className="kazanilan-puan">
                          <p>{item.gain}</p>
                          <p>Kazanılan</p>
                        </div>

                        <p
                          className={`degerlendirme-${
                            item.videoRating > 5 ? "olumlu" : "olumsuz"
                          }`}
                        >
                          Değerlendirme: {item.videoRating}{" "}
                          {item.videoRating
                            ? item.videoRating > 5
                              ? "👍"
                              : "👎"
                            : "-"}
                        </p>

                        <div
                          className={`
                  durum-etiketi ${
                    item.durum
                      ? "durum-etiketi-olumlu"
                      : "durum-etiketi-olumsuz"
                  }`}
                        >
                          {item.durum ? "Oylanmış" : "Oylanmamış"}
                        </div>

                        <div className="button-groups">
                          <Link
                            to={{
                              pathname: `/evgrs/videos/${item.videoAdsId}`,
                              state: item.videoDetail,
                            }}
                            className="edit-button"
                          >
                            Detay
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="veri-yok">Veri yok!</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AdvertisingActivity;
