import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import Home from "./Home/Home";
import Footer from "./components/Footer/Footer";
import Layout from "./components/Layout/Layout";
import React from "react";
import { Dowloand } from './pages/Dowloand';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";



function Homes() {
  return (
    
    <div className="App">
  <Navbar />
  <Header />
  <Home />
  <Footer />
  </div>
  );
}


export default function App() {
  return (
    <Router>
 {/*      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route  path="/dowloand">
            <Dowloand />
          </Route>
          <Route path="/evgrs">
            <Layout />
          </Route>
          <Route path="/">
            <Homes />
          </Route>
             
        </Switch>
      {/* </div> */}
    </Router>
  );
}





// export default App;
