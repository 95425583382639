import React, { useRef, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table'
import { TableFilter } from '../../components/tablefilter/TableFilter'
import './table2.css'

const defaultPropGetter = () => ({})

function Table2({ setOrderDetailModalOpen, setSelectRow, propsColumn, propsRow, getCellProps = defaultPropGetter, localStorageName, hiddenColumnsPage = [] }) {

    const deneme = useMemo(() => propsColumn, [propsColumn])
    const deneme2 = useMemo(() => propsRow, [propsRow])
    const initialState = { hiddenColumns: hiddenColumnsPage };
    const tableInstance = useTable({ columns: deneme, data: deneme2, initialState, autoResetPage: false }, useGlobalFilter, useSortBy, usePagination)


    const forRef = () => {
        refs.current.style.display = refs.current.style.display !== 'block' ? 'block' : 'none'
    }

    const refs = useRef(null)

    const hiddenColumns = (e) => {
        var column = localStorage.getItem(localStorageName)
        var jsonColumn = JSON.parse(column) ?? {}
        if (e.isVisible) {
            var propperty = e.id
            var value = e.isVisible
            jsonColumn[propperty] = value
            localStorage.setItem(localStorageName, JSON.stringify(jsonColumn))
        } else {
            delete jsonColumn[e.id]
            localStorage.setItem(localStorageName, JSON.stringify(jsonColumn))
        }



    }

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        allColumns,
        gotoPage,
        pageCount,
        state,
        setGlobalFilter,

    } = tableInstance

    const { globalFilter } = state
    const { pageIndex } = state

    const { pathname } = useLocation()

    return (
        <div>
            {propsColumn && propsColumn.length > 0 && <div className='card table-wrapper'>
                <div className='options_table'>
                    <TableFilter filter={globalFilter} setFilter={setGlobalFilter} />
                    <form>
                        <div className="multiselect">
                            <div className="selectBox" >
                                <select className='select_filter'>
                                    <option>Kolon Filtreleme</option>
                                </select>
                                <div onClick={() => { forRef() }} className="overSelect"></div>
                            </div>
                            <div ref={refs} id="checkboxes">
                                {
                                    allColumns.map((column) => {
                                        return <div key={column.id}>
                                            <label onClick={() => hiddenColumns(column)}>
                                                <input type="checkbox"  {...column.getToggleHiddenProps()} />
                                                {column.Header}
                                            </label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </form>
                </div>

                <div >

                </div>
                <table {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                            <td {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? '>' : '<') : ''}
                                                </span>
                                            </td>
                                        ))
                                    }

                                </tr>

                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr
                                        onClick={() => pathname === "/evgrs/order" ? setOrderDetailModalOpen(true) : null}
                                        {...row.getRowProps()}
                                    >
                                        {
                                            row.cells.map(cell => {
                                                return <td
                                                    onClick={() => pathname === "/evgrs/order" ? setSelectRow(cell.row.original) : null}

                                                    {...cell.getCellProps(getCellProps(cell))}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            })
                                        }
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                <div>
                    {
                        <div className="table__pagination">
                            {
                                [...Array(pageCount).fill(0)].map((value, index) => <div key={index} onClick={() => gotoPage(index)} className={`table__pagination-item ${pageIndex === index ? 'active' : ''}`}>{index + 1}</div>)
                            }

                        </div>
                    }
                </div>

            </div>}
        </div>
    )
}

export default Table2
