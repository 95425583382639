import React, { useState, useEffect } from 'react'
import Table2 from '../components/table2/Table2'
// import { useSelector } from 'react-redux'
// import { authState } from '../redux/features/AuthSlice'
import { collection, onSnapshot, updateDoc, doc, getDoc } from "firebase/firestore";
import defauldImage from '../assets/images/favicon.png'
// import { Link } from 'react-router-dom'
import { db } from '../base'
// import { Button } from '@mui/material';
import { Modal } from '../components/modal/Modal';
import axios from 'axios';



export const Orders = () => {

    // const auth = useSelector(authState)
    const [coll, setColl] = useState([])
    const [roww, setroww] = useState([])
    const [initialColumn] = useState([])

    const [modalOpen, setmodalOpen] = useState(false)
    const [orderDetailModalOpen, setOrderDetailModalOpen] = useState(false)
    const [selectRow, setSelectRow] = useState()
    const localStorageName = 'benchesHidecolumn'
    const [docId, setDocId] = useState()
    const [userId, setuserId] = useState()
    const [txtVal, settxtVal] = useState("")
    const [orderStatusVal, setOrderStatusVal] = useState(true)
    const [productTitle, setProductTitle] = useState()


    function MyCell({ value }) {
        return <img alt='img-val' src={value !== 'null' && value !== '' ? value ?? defauldImage : defauldImage} width={32} height={32} />
    }

    function MyCell2({ row }) {
        if (row.original.state) {

            if (!row.original.status) {
                return <p className='settings-column-red-text'>Reddedildi</p>
            } else if (row.original.status === true) {
                return <p className='settings-column-green-text'>Onaylandı</p>
            }
        } else {
            return <button className='button-1' onClick={() => { setProductTitle(row.original.productTitle); setDocId(row.original.docId); setuserId(row.original.userId); setmodalOpen(true); }}>İşlem</button>
        }
        return null;
    }

    function MyCell3({ value }) {
        return <div>{new Date(value).toLocaleDateString().replace(/\./g, '/')}</div>
    }

    const deneme = async () => {
        const refDoc2 = doc(db, "productSold", docId);

        console.log({
            state: true,
            desciription: txtVal,
            status: orderStatusVal
        });

        await updateDoc(refDoc2, {
            state: true,
            desciription: txtVal,
            status: orderStatusVal
        })
        settxtVal("")
        setOrderStatusVal(true)
        setmodalOpen(false)

        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var dats = docSnap.data();

            if (orderStatusVal) {
                const messagePostObj = {
                    tkn: dats.msgToken,
                    title: `${productTitle} product`,
                    body: `${productTitle} your product purchase has been approved`,
                    route: '/MarketScreen'
                }

                axios.post('https://us-central1-vimitv-admin.cloudfunctions.net/sendMessage', messagePostObj)
            } else {
                const messagePostObj = {
                    tkn: dats.msgToken,
                    title: `${productTitle} product`,
                    body: `${productTitle} your product purchase was declined`,
                    route: '/MarketScreen'
                }

                axios.post('https://us-central1-vimitv-admin.cloudfunctions.net/sendMessage', messagePostObj)
            }

        } else {
            console.log(`UID'si ${userId} olan kullanıcı bulunamadı.`);
        }

        /* eğer reddedildiyse kullanıcının puanı iade edilir */
        if (!orderStatusVal) {

            const orderDataResponse = await getDoc(refDoc2);
            var orderData = orderDataResponse.data();

            console.log("dats : ", dats);
            const updateGain = dats.gain + orderData.productPrice;
            await updateDoc(docRef, {
                gain: updateGain
            })
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "order-text") {
            settxtVal(e.target.value)
        }

        if (e.target.name === "order-status") {

            if (e.target.value === "true") {
                setOrderStatusVal(true)
            }
            if (e.target.value === "false") {
                setOrderStatusVal(false)
            }

        }
    }


    useEffect(() => {
        var admins
        var coll = [
            { Header: '', accessor: 'productBanner', Cell: MyCell },
            { Header: 'Title', accessor: 'productTitle' },
            { Header: 'productPrice', accessor: 'productPrice' },
            { Header: 'Tarih', accessor: 'time', Cell: MyCell3 },
            { Header: 'userName', accessor: 'userName' },
            { Header: 'userid', accessor: 'userId' },
            { Header: '', accessor: 'Settinsgs', Cell: MyCell2 }]

        let ref = collection(db, 'productSold')
        // let q = query(ref, where("state", '==', false))
        const unsub = onSnapshot(ref, (snapshot) => {
            admins = snapshot.docs.map((e) => {
                let a = e.data()
                a['docId'] = e.id
                return a
            })

            setColl(coll)
            admins = admins.sort((a, b) => b.time - a.time)
            setroww(admins)
            console.log("admins : ", admins);
        })
        return unsub
    }, [])

    /* İşlem butonuna tıklandığında açılan detay modalını kapatmak için */
    useEffect(() => {
        if (modalOpen === true) {
            setOrderDetailModalOpen(false)
        }
    }, [modalOpen])

    // console.log("selectRow : ", selectRow);

    return (
        <div>
            <h2 className="page-header" >
                Ürün Satışları
            </h2>
            {
                modalOpen && <Modal close={setmodalOpen}>

                    <div className="form-field ">
                        <label >Custumers : </label>
                        <div id='modal-selected'>
                            <select name='order-status' onChange={handleChange}>
                                <option value={true}>Onayla(True)</option>
                                <option value={false}>Reddet(False)</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-field ">
                        <label >Açıklama :</label>
                        <textarea name='order-text' className='textarea' placeholder='Kod:X34123' onChange={handleChange} ></textarea>
                    </div>

                    <button className='button-1' onClick={() => deneme()} >Onay</button>

                </Modal>
            }

            {
                orderDetailModalOpen && <Modal close={setOrderDetailModalOpen}>

                    <div className="form-field ">
                        <label >Durum : </label>
                        <p>{selectRow.state ? "Onaylandı" : "Reddedildi"}</p>
                    </div>

                    <div className="form-field ">
                        <label >Açıklama :</label>
                        <p>{selectRow.desciription ? selectRow.desciription : "-"}</p>
                    </div>

                    <div className="form-field ">
                        <label >Tarih : </label>
                        <p>{new Date(selectRow.time).toLocaleDateString('tr-TR', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</p>
                    </div>

                </Modal>
            }

            <Table2 propsColumn={coll} propsRow={roww}
                localStorageName={localStorageName}
                hiddenColumnsPage={initialColumn}
                setSelectRow={setSelectRow}
                setOrderDetailModalOpen={setOrderDetailModalOpen}
            />

        </div>
    )
}
