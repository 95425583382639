import React, { useState, useEffect } from "react";
import Table2 from "../components/table2/Table2";

import { db } from "../base";
import { collection, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { Modal } from "../components/modal/Modal";

function Admins() {
  const [coll, setColl] = useState([]);
  const [roww, setroww] = useState([]);
  const [initialColumn] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const localStorageName = "benchesHidecolumn";

  function MyCell({ value }) {
    return (
      <div>{new Date(value).toLocaleDateString().replace(/\./g, "/")}</div>
    );
  }
  function MyCell2({ row }) {
    return (
      <button
        className="button-1"
        onClick={() => {
          setDeleteUserId(row.original.uid);
          // deleteUser(row.original.uid)
          setmodalOpen(true);
        }}
      >
        Delete
      </button>
    );
  }
  async function deleteUser(userId) {
    const refDoc = doc(db, "admins", userId);
    setmodalOpen(false);
    await deleteDoc(refDoc);
  }

  useEffect(() => {
    var admins;
    var coll = [
      { Header: "Id", accessor: "uid" },
      { Header: "Email", accessor: "email" },
      { Header: "time", accessor: "time", Cell: MyCell },
      { Header: "Settings", accessor: "Settinsgs", Cell: MyCell2 },
    ];
    const unsub = onSnapshot(collection(db, "admins"), (snapshot) => {
      admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setColl(coll);
      setroww(admins);
    });
    return unsub;
  }, []);

  return (
    <div>
      <h2 className="page-header">Adminler</h2>
      <Table2
        propsColumn={coll}
        propsRow={roww}
        localStorageName={localStorageName}
        hiddenColumnsPage={initialColumn}
      />
      {modalOpen && (
        <Modal close={setmodalOpen}>
          <div className="form-field ">
            <label>Uyarı : </label>
            <div>
              <p>
                {" "}
                {deleteUserId}'idli admin silinecek bu işlemi geri alamassınız
                emin misiniz ?{" "}
              </p>
            </div>
          </div>
          <button
            className="button-1"
            onClick={() => {
              deleteUser(deleteUserId);
            }}
          >
            Onay
          </button>
        </Modal>
      )}
    </div>
  );
}

export default Admins;
