import "./CustomerLastSlidersCard.css";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { db } from "../../base";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

function formatBudget(budget) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(budget);
}

function CustomerLastSlidersCard({ customer }) {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    if (customer) {
      console.log(customer);
      const q = query(
        collection(db, "tabletconfig") // "tabletconfig" belgesi içindeki "sliderConfig" koleksiyonu
      );
      getDocs(q)
        .then((data) => {
          const arry = data.docs.map((e) => e.data()); // Sadece slider verilerini döndür
          const customerSliders = arry[0].sliders.filter(
            (slider) => slider.customer === customer.name
          );
          console.log("customerSliders : ", customerSliders);
          setSliders(customerSliders);
        })
        .catch((error) => {
          console.error("Firestore sorgusu hatası:", error);
        });
    }
  }, [customer]);

  return (
    <div className="customer-last-sliders-card-container card">
      <div className="header">
        <h2>Last Sliders</h2>
        <p className="subtitle">Live Update</p>
        <div className="divider"></div>
      </div>
      <div className="slider-table-container">
        {sliders && sliders.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Slider</th>
                <th>Total Budget</th>
                <th>Given Budget</th>
                <th>Remaining Budget</th>
                <th>Click Count</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {sliders.map((slider, index) => (
                <tr key={index}>
                  <td className="slider-td">
                    <img
                      src={slider.url} // Video banner resmi
                      alt={`Slider ${index + 1}`}
                      className="slider-img"
                    />
                    <div className="slider-title">
                      <p className="slider-name">{slider.imageTitle}</p>
                      <p className="slider-category">{slider.transitionTime}</p>
                    </div>
                  </td>
                  <td className="budget">{formatBudget(slider.totalBudget)}</td>
                  <td className="budget">
                    {Number(slider.totalWatched) > 0
                      ? formatBudget(
                          parseFloat(slider.oneVideoPrice) *
                            Number(slider.totalWatched)
                        )
                      : 0}
                  </td>
                  <td className="budget">
                    {Number(slider.totalWatched) > 0
                      ? formatBudget(
                          Number(slider.totalBudget) -
                            parseFloat(slider.oneSliderPrice) *
                              Number(slider.totalWatched)
                        )
                      : 0}
                  </td>
                  <td className="click-count">
                    {slider.sliderClickCount ? slider.sliderClickCount : "-"}
                  </td>

                  <td>
                    <Link
                      to={{
                        pathname: `/evgrs/tbtslider-detail/${slider.id}`,
                        state: slider,
                      }}
                      className="details-button"
                    >
                      <i className="bx bx-right-arrow-alt"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Emty</p>
        )}
      </div>
    </div>
  );
}

export default CustomerLastSlidersCard;
