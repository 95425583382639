import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const userAddApi = createApi({
    reducerPath: 'userAddApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://us-central1-vimitv-admin.cloudfunctions.net/' }),
    endpoints: (builder) => ({
        setCustomers: builder.mutation({
            query:(body) => ({
                url:'addCustomers',
                method:'POST',
                body:{email:body.email,passworld:body.passworld,name:body.name}
                
            }),
        }),
        setAdmin:builder.mutation({
            query:(body)=>({
                url:'addAdmin',
                method:'POST',
                body:{email:body.email,passworld:body.passworld,name:body.name}
            })
        }),
        setDriver:builder.mutation({
            query:(body)=>({
                url:'addDriver',
                method:'POST',
                body:{email:body.email,passworld:body.passworld,name:body.name}
            })
        }),
    }),
})

export const {useSetCustomersMutation,useSetAdminMutation,useSetDriverMutation} = userAddApi