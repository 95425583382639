import React from "react";
import { Route, Switch } from "react-router-dom";
import { Dowloand } from "../pages/Dowloand";
import Login from "../pages/Login";
import Singup from "../pages/Singup";
function RoutersLogin() {
  return (
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/singup" component={Singup} />
      <Route path="/dowloand" component={Dowloand} />
      <Route path="*" component={Login} />
    </Switch>
  );
}

export default RoutersLogin;
