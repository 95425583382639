import { createSlice } from '@reduxjs/toolkit'

export const themeSlice = createSlice({
    name:'theme',
    
    initialState:{},


    
    reducers:{
        setMode : (state,action) => {
            state.mode = action.payload
        },
        setColor:(state,action)=>{
            state.color = action.payload
        }
        
    }

})

export const {setMode,setColor} = themeSlice.actions
export default themeSlice.reducer