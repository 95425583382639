import React, { useState } from 'react'
import './table.css'

function Table(props) {

    const initDataShow = props.limit && props.bodyData ? props.bodyData.slice(0, Number(props.limit)) : props.bodyData

    const [dataShow, setdataShow] = useState(initDataShow)
  

    let pages = 1

    let range = []

    if (props.limit !== undefined) {
        pages = Math.ceil(props.bodyData.length / Number(props.limit))
        range = [...Array(pages).keys()]
    }

    const [currPage, setCurrPage] = useState(0)

    const selectPage = page => {
        const start = Number(props.limit) * page
        const end = start + Number(props.limit)
        setdataShow(props.bodyData.slice(start, end))
        setCurrPage(page)
    }
    return (
        <div className='table-wrapper'>
            <table>
                {
                    props.headData && props.renderHead ? (
                        <thead>
                            <tr>
                                {
                                    props.headData.map((item, index) => props.renderHead(item, index))
                                }
                            </tr>
                        </thead>
                    ) : null
                }
                {
                    props.bodyData && props.renderBody ? (
                        <tbody>

                            {
                              dataShow.length !== 0  ? dataShow.map((item, index) => props.renderBody(item, index)) : 
                              props.bodyData.map((item, index) => props.renderBody(item, index))
                            }

                        </tbody>
                    ) : null
                }
            </table>
            {
                pages > 1 ? (
                    <div className="table__pagination">
                        {
                            range.map((item, index) => {
                                return <div key={index}  
                                    className={`table__pagination-item ${currPage === index ? 'active' : ''}`}
                                    onClick={()=>{selectPage(index)}}
                                    >
                                    {item + 1}
                                </div>
                            })
                        }
                    </div>
                ) : null
            }
        </div>
    )
}

export default Table
