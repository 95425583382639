/* eslint-disable react/jsx-no-target-blank */
import "./Home.css";
import images from "../Asset/Img/image";
import video from "../Asset/Video//video";
import VimiContent from "./MainPages/VimiContent";

function Home() {
  return (
    <div className="home-container">
      <div className="home-content">
        <div className="tablet-video-container" id="tablet-video-container">
         <br></br>
         <p></p>
         <br></br>
         <br></br>
          <div className="tablet-header-text">
            <h1>What is Vimi ?</h1>
            <br></br>
            <p>
            Vimi TV is a <strong>NEW GENERATION</strong> advertising platform where passengers can watch advertisements of the companies they want in the category they are interested in, thanks to the tablets attached to Rideshare vehicles such as Uber and Lyft. 
            </p>
<br/>
            <p>
            This platform provides a fun travel experience for passengers, while at the same time allowing advertisers to reach their target audience directly and establishing a connection between brands and consumers.
              </p>
              <br/>
              <p>
             Rideshare Drivers can earn additional income thanks to the tablet they buy for their vehicles at no cost.
               </p>
          </div>

          <div className="tablet-frame-container">
            <div className="tablet-frame-content">
              <div className="img-video">
                <img src={images.ipadMini} alt="ipad-mini" />
                <video loop autoPlay muted src={video.headerVideo}></video>
              </div>
            </div>

            <div className="icons-container">
              <div className="icon-item">
                <img src={images.taxiIcon} alt="taxi" />
                <p>Taxi,Uber or Lyft</p>
              </div>
              <div className="icon-item">
                <img src={images.timeIcon} color="#ffffff" alt="time" />
                <p>Enjoyable Time</p>
              </div>
              <div className="icon-item">
                <img src={images.passengerIcon} alt="passenger" />
                <p>Profitable Passenger</p>
              </div>
              <div className="icon-item">
                <img src={images.humanIcon} alt="human" />
                <p>Profitable Advertiser</p>
              </div>
            </div>
            <br/>
          </div>
        </div>

        <div className="car-container" id="car-container">
          <br/>
          <div className="car-img">
            <img src={images.carImg} alt="car" />
          </div>

          <div className="car-info-text">
            <h1>How does it works ?</h1>
            <div className="car-box-text-item">
              <h3>Connecting to the internet</h3>
              <p>
                Internet connection is provided through the built-in SIM card on
                the screen.
              </p>
              <p>
                Users do not have to pay any fees for internet access after they
                log into the system.
              </p>
            </div>

            <div className="car-box-text-item">
              <h3>Advertising</h3>
              <p>
                Advertisements are shown to users selected by the publishers
                through the system.
              </p>
              <p>
                Statistical reports, such as user preferences and vehicle
                information, can be generated and users can make payments
                contactless.
              </p>
            </div>
          </div>
        </div>
<div className="tablet-message-container" id="tablet-message-container">
<VimiContent />
    </div>
        <div className="mobile-app-container" id="mobile-app-container">
          <div className="dowload-service">
            <h2>
              Download now <br /> and start to win!
            </h2>
            <div className="appstore-googlestore">
              <a
                className="google"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.vimitv.mobile.user&gl=TR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
              <a
                className="apple"
                target="_blank"
                href="https://apps.apple.com/sa/app/vimi-tv/id1581068601"
              >
                <img src={images.appStore} alt="app" />
              </a>
            </div>
            <p>*The app may be unavailable in some countries</p>
          </div>
          <div className="mobile-img">
            <img src={images.iphone} alt="iphone" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
