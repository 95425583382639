import './assets/boxicons-2.0.7/css/boxicons.min.css'
import './assets/css/grid.css'
import './assets/css/index.css'
import './assets/css/theme.css'
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store';
import App from './App';

// import Layout from './components/Layout/Layout';
// import { BrowserRouter , Switch, Route,  } from "react-router-dom";
// import Panel from './components/panel/Panel';



// const store = createStore(rootReducer,applyMiddleware(thunx))
// store.subscribe(() => console.log(store.getState()))

// function Pages() {
//   return (

//     <BrowserRouter>
//     <Switch>
//          <Route path="/"><App /></Route>
//          <Route path="*"><App/></Route>
//          <Route path="Layout"><Layout /></Route>
//          <Route path="Panel"><Panel /></Route>
//      </Switch>
//   </BrowserRouter>
//   );
// }

/* ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Pages />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
 */

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      {/* <Pages /> */}
          </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
 
reportWebVitals();
