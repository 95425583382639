import React from "react";
import "./fullpageloading.css";
import { CircularProgress } from "@mui/material";

function FullPageLoading() {
  return (
    <div>
      {
        <div className="loading_conteiner">
          <div className="loader">
            <CircularProgress size={80} color="primary" />
          </div>
        </div>
      }
    </div>
  );
}

export default FullPageLoading;
