import "./CustomerInfoCard.css";
import React, { useEffect, useState } from "react";

import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { store, db } from "../../base";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import FullPageLoading from "../../components/fullpageloading/FullPageLoading";
import { Modal } from "../../components/modal/Modal";

function CustomerInfoCard({ customer, setCustomer }) {
  const [showDetails, setShowDetails] = useState(false);

  const [editLoading, setEditLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [newPhoto, setNewPhoto] = useState(null);

  const [videosLength, setVideosLength] = useState(0);
  const [watchedCount, setWatchedCount] = useState(0);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (customer) {
      const q = query(
        collection(db, "videoAds"),
        where("companyName", "==", `${customer?.name}`)
      );
      getDocs(q)
        .then((data) => {
          const promises = data.docs.map(async (e) => {
            const querySnapshot = await getDocs(
              collection(db, `/videoAds/${e.id}/notification`)
            );
            const clickData = querySnapshot.docs.map((doc) => doc.data());

            return {
              ...e.data(),
              videoClickCount: clickData[0]?.sendNotification,
            };
          });

          Promise.all(promises)
            .then((arry) => {
              setVideosLength(arry.length);

              // İzlenme ve tıklanma sayılarını topla
              const totalWatchedCount = arry.reduce(
                (total, video) => total + (video.totalWatched || 0),
                0
              );
              const totalClickCount = arry.reduce(
                (total, video) => total + (video.videoClickCount || 0),
                0
              );

              setWatchedCount(totalWatchedCount);
              setClickCount(totalClickCount);
            })
            .catch((error) => {
              console.error("Promise hata:", error);
            });
        })
        .catch((error) => {
          console.error("Firestore sorgusu hatası:", error);
        });
    }
  }, [customer]);

  const handleCustomerUpdate = async (
    customerName,
    customerEmail,
    customerPhone,
    customerAddress
  ) => {
    setCustomer((prevData) => ({
      ...prevData,
      name: customerName,
      email: customerEmail,
      phone: customerPhone,
      address: customerAddress,
    }));
    setEditModal(false);

    // Bu noktada Firebase veya herhangi bir API kullanarak verileri güncelleyebilirsiniz.ç
    try {
      setEditLoading(true);
      if (newPhoto) {
        // Step 2: Delete the old photo
        if (customer.pp) {
          const photoRef = ref(store, `/customers/${customer.uid}`);

          const photoSnapshot = await getDownloadURL(photoRef).catch(
            (error) => {
              if (error.code === "storage/object-not-found") {
                console.log("Resim bulunamadı, silme işlemi gerekli değil.");
                return null; // Resim bulunamadığı durumda fonksiyonu burada bitir
              }
              throw error; // Başka bir hata durumunda hatayı yukarı iletebilirsiniz
            }
          );

          if (photoSnapshot !== null) {
            await deleteObject(photoRef);
            console.log("Resim başarıyla silindi.");
          }
        }

        //Step 1: Upload the new photo and get its URL
        const storageRef = ref(store, `/customers/${customer.uid}`);
        await uploadBytes(storageRef, newPhoto);
        const newImageUrl = await getDownloadURL(storageRef);
        console.log("newImageUrl : ", newImageUrl);

        const updatedCustomerData = {
          ...customer,
          name: customerName,
          email: customerEmail,
          pp: newImageUrl,
        };

        await updateDoc(doc(db, "customers", customer.id), updatedCustomerData);

        window.location.reload();
      } else {
        const updatedCustomerData = {
          ...customer,
          name: customerName,
          email: customerEmail,
        };

        await updateDoc(doc(db, "customers", customer.id), updatedCustomerData);
      }

      console.log("User profile updated successfully!");
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
    setEditLoading(false);
  };

  return (
    <div>
      <div className="customer-info-card">
        {customer?.pp && (
          <img
            className="customer-logo"
            src={customer?.pp}
            alt={`${customer?.name} Logo`}
          />
        )}
        <h2>{customer?.name}</h2>
        <div className="customer-details">
          <div className="count-box">
            <p>
              {watchedCount ? watchedCount : 0}
              <span>{<i className="bx bx-up-arrow-alt"></i>}</span>
            </p>
            <small>İzleme Sayısı</small>
          </div>
          <div className="count-box">
            <p>
              {videosLength ? videosLength : 0}
              <span>
                <i className="bx bx-down-arrow-alt"></i>
              </span>
            </p>
            <small>Video</small>
          </div>
          <div className="count-box">
            <p>
              {clickCount ? clickCount : 0}{" "}
              <span>
                {clickCount < watchedCount ? (
                  <i className="bx bx-down-arrow-alt"></i>
                ) : (
                  <i className="bx bx-up-arrow-alt"></i>
                )}
              </span>
            </p>
            <small>Tıklanma Sayısı</small>
          </div>
        </div>
        <div className="customer-detail-button-groups">
          <button
            className="detail-button"
            onClick={() => setShowDetails(!showDetails)}
          >
            Detail{" "}
            {showDetails ? (
              <i className="bx bxs-chevron-up"></i>
            ) : (
              <i className="bx bxs-chevron-down"></i>
            )}
          </button>
          <button className="edit-button" onClick={() => setEditModal(true)}>
            Edit
          </button>
        </div>
      </div>
      <div className={`customer-more-details ${showDetails ? "show" : ""}`}>
        <hr />

        <span className="customer-type-badge">Premium User</span>

        <p>
          <strong>Firma ID</strong>
          <span>{customer.id ? customer.id : "-"}</span>
        </p>
        <p>
          <strong>Email</strong>
          <span> {customer.email ? customer.email : "-"}</span>
        </p>
        <p>
          <strong>Telefon</strong>
          <span> {customer.phone ? customer.phone : "-"}</span>
        </p>
        <p>
          <strong>Adres</strong>
          <span> {customer.address ? customer.address : "-"}</span>
        </p>
      </div>

      {editLoading && <FullPageLoading />}
      {editModal && (
        <Modal
          close={() => setEditModal(false)}
          children={
            <div className="edit-modal-container">
              <h2>Bilgileri Güncelle</h2>
              <label className="image-box" htmlFor="image-input">
                {newPhoto ? (
                  <img
                    src={URL.createObjectURL(newPhoto)}
                    alt="Firma Logosu"
                    className="edit-profile-image"
                  />
                ) : (
                  <img
                    src={customer.pp}
                    alt="Firma Logosu"
                    className="edit-profile-image"
                  />
                )}

                <div className="edit-icon">
                  <i className="bx bx-edit"></i>
                </div>
              </label>
              <input
                id="image-input"
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => setNewPhoto(e.target.files[0])}
              />
              <input
                type="text"
                placeholder="Kullanıcı Adı"
                value={customer.name}
                onChange={(e) =>
                  setCustomer({ ...customer, name: e.target.value })
                }
              />
              <input
                type="email"
                placeholder="E-Posta Adresi"
                value={customer.email}
                onChange={(e) =>
                  setCustomer({ ...customer, email: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Telefon"
                value={customer.phone}
                onChange={(e) =>
                  setCustomer({ ...customer, phone: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Adres"
                value={customer.address}
                onChange={(e) =>
                  setCustomer({ ...customer, address: e.target.value })
                }
              />
              <button
                onClick={async () =>
                  await handleCustomerUpdate(
                    customer.name,
                    customer.email,
                    customer.phone,
                    customer.address
                  )
                }
              >
                Güncelle
              </button>
            </div>
          }
        ></Modal>
      )}
    </div>
  );
}

export default CustomerInfoCard;
