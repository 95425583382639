import React, { useState, useEffect } from 'react';
import './Banner.css';
import yolcu from '../../Asset/Img/yolcu.png';
import sofor from '../../Asset/Img/sofor.png';

import reklamveren from '../../Asset/Img/reklamveren.png';

const VimiContent = () => {
    const messages = [
        { title: "Passengers", content: "Passengers are free to watch advertisements from their preferred companies. Additionally, they can earn discounts and gifts from viewing these videos. Moreover, by accumulating points, they have the option to redeem them for desired gifts through the market on our mobile application or use them to cover their taxi fares", img: yolcu, type: "mobile" },
        { title: "Advertisers", content: "With our innovative advertising model, advertisers can deliver their ads to the right users at the optimal moment, thereby achieving higher conversion rates. Utilizing advanced analytics and our proprietary AI, they can craft more effective marketing strategies.", img: reklamveren, type: "web" },
        { title: "Shared Vehicle Drivers", content: "Drivers can earn additional income through the videos watched by passengers. There is no cap on the earnings they can receive. Moreover, they can enjoy numerous discounts through our partner companies.", img: sofor, type: "mobile" }
    ];

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex(prevIndex => (prevIndex + 1) % messages.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [messages]);

    const currentMessage = messages[currentMessageIndex];

    return (
        <div className="banner">
            
            <div className="col-12 col-sm-12"><div className="text-container">
                <h2>{currentMessage.title}</h2>
                <p>{currentMessage.content}</p>
           </div>
            </div>
           {/*  <div className="col-3 col-sm-12"> <div className={`image-container ${currentMessage.type}`}>
<img src={currentMessage.img} alt={currentMessage.title} />
</div>
</div> */}
           
           
        </div>
    );
}

export default VimiContent;
