import React, { useEffect, useState, useMemo } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../base";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { Modal } from "../components/modal/Modal";
import Table from "../components/table/Table";
import AdvertisingActivity from "../components/AdvertisingActivity/AdvertisingActivity";
import UserDetailCard from "../components/UserDetailCard/UserDetailCard";
import PointDetailProgressCard from "../components/PointDetailProgressCard/PointDetailProgressCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

registerLocale("tr", tr);

export const UsersDetail = (props) => {
  const { id } = useParams();
  console.log(id);
  const [userData, setUserData] = useState({});

  const [videoSayisi, setvideoSayisi] = useState(0);
  const [yolculuk, setyolculuk] = useState(0);
  const [tabindex, settabindex] = useState(0);
  const [statedatam, setstatedatam] = useState();
  const [shopH, setShopH] = useState();
  const [reklamAktivitesi, setReklamAktivitesi] = useState([]);

  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(0, 0, 0, 0));

  const [modalOpen, setmodalOpen] = useState(false);

  useEffect(() => {
    const denem = async () => {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(() => docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    if (userData.uid === undefined) {
      denem();
    }
  }, [id]);

  const onChange = (dates) => {
    const [start, end] = dates;
    start.setHours(0, 0, 0, 0);
    if (end) {
      end.setHours(0, 0, 0, 0);
    }
    setStartDate(start);
    setEndDate(end);
  };

  const nows = useMemo(() => new Date(Date.now()), []);
  const week = useMemo(
    () => new Date(nows.getFullYear(), nows.getMonth(), nows.getDate() - 7),
    [nows]
  );
  const mounth = useMemo(
    () => new Date(nows.getFullYear(), nows.getMonth(), nows.getDate() - 30),
    [nows]
  );

  const topCustomers = {
    head: ["Product Title", "Product Price", "Time"],
  };

  const renderCustomer = (item, index) => <th key={index}>{item}</th>;

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.productTitle}</td>
      <td>{item.productPrice}</td>
      <td>{new Date(item.time).toLocaleDateString().replace(/\./g, "/")}</td>
    </tr>
  );

  async function logoutUser(driverId, userId) {
    const refDoc = doc(db, "drivers", driverId);
    const refDoc2 = doc(db, "users", userId);
    await updateDoc(refDoc, {
      matchedUserId: "null",
      matchedUserName: "null",
    });
    await updateDoc(refDoc2, {
      matchedDriverId: "null",
    });
    let data = { ...userData, matchedDriverId: "null" };
    setUserData(data);
  }

  async function shopHistory() {
    const q = query(
      collection(db, "productSold"),
      where("userId", "==", `${userData.uid}`)
    );
    getDocs(q).then((data) => {
      let arry = data.docs.map((e) => e.data());
      setShopH(arry);
    });
  }

  useEffect(() => {
    if (userData.uid !== undefined) {
      const q = query(
        collection(db, "analysis"),
        where("userId", "==", `${userData.uid}`)
      );
      getDocs(q).then((data) => {
        let arry = data.docs.map((e) => e.data());
        let datam = arry.reduce(
          (acc, curr) => {
            let date = new Date(curr.time).toLocaleDateString();
            let date2 = new Date(curr.time);
            if (date in acc.tarihbazli) {
              acc.tarihbazli[date].push(curr);
              acc.videoSayisi += 1;
            } else {
              acc.tarihbazli[date] = [];
              acc.tarihbazli[date].push(curr);
              acc.videoSayisi += 1;
              acc.yolculukSayisi += 1;
            }

            if (date2 >= week && date2 <= nows) {
              //haftalık
              acc["haftalık"].push(curr);
            }
            if (date2 >= mounth && date2 <= nows) {
              acc["aylik"].push(curr);
            }
            if (date === nows.toLocaleDateString()) {
              acc["gunluk"].push(curr);
            }
            return acc;
          },
          {
            videoSayisi: 0,
            tarihbazli: {},
            yolculukSayisi: 0,
            haftalık: [],
            aylik: [],
            gunluk: [],
          }
        );
        setyolculuk(datam.yolculukSayisi);
        setvideoSayisi(datam.videoSayisi);
        setstatedatam(datam);
      });

      let advertisingActivity = userData.watchedList?.reduce(
        (acc, curr) => {
          //reklam detayına firma adını eklemek için
          const q = query(
            collection(db, "videoAds"),
            where("videoId", "==", `${curr.videoAdsId}`)
          );
          getDocs(q).then((data) => {
            let arry = data.docs.map((e) => e.data());
            curr.companyName = arry[0]?.companyName;
          });

          let date = new Date(curr.time).toLocaleDateString();
          let date2 = new Date(curr.time);

          if (date in acc.tarihbazli) {
            acc.tarihbazli[date].push(curr);
            acc.videoSayisi += 1;
          } else {
            acc.tarihbazli[date] = [];
            acc.tarihbazli[date].push(curr);
            acc.videoSayisi += 1;
            acc.yolculukSayisi += 1;
          }

          if (date2 >= week && date2 <= nows) {
            //haftalık
            acc["haftalık"].push(curr);
          }
          if (date2 >= mounth && date2 <= nows) {
            acc["aylik"].push(curr);
          }
          if (date === nows.toLocaleDateString()) {
            acc["gunluk"].push(curr);
          }
          return acc;
        },
        {
          videoSayisi: 0,
          tarihbazli: {},
          yolculukSayisi: 0,
          haftalık: [],
          aylik: [],
          gunluk: [],
        }
      );

      console.log(advertisingActivity);

      setReklamAktivitesi(advertisingActivity);
    }

    shopHistory();
  }, [userData, mounth, nows, week]);

  return (
    <div>
      <div className="card mn-content">
        <div className="card__body ">
          <UserDetailCard
            userData={userData}
            logoutUser={logoutUser}
            videoSayisi={videoSayisi}
            yolculuk={yolculuk}
            setmodalOpen={setmodalOpen}
            shopHistory={shopHistory}
            shopH={shopH}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-7 col-md-12">
          <div className="card">
            <AdvertisingActivity userData={userData} />
          </div>
        </div>

        <div className="col-5 col-md-12">
          <PointDetailProgressCard userData={userData} shopH={shopH} />
        </div>
      </div>

      {modalOpen && (
        <Modal close={setmodalOpen}>
          <div>
            {shopH && (
              <Table
                headData={topCustomers.head}
                bodyData={shopH.map((e) => e)}
                renderHead={(item, index) => renderCustomer(item, index)}
                renderBody={(item, index) => renderBody(item, index)}
                limit={5}
              ></Table>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
