import React, { useState, useEffect } from 'react'
import Table2 from '../components/table2/Table2'
// import { useSelector } from 'react-redux'
// import { authState } from '../redux/features/AuthSlice'
import { collection, onSnapshot, } from "firebase/firestore";
import defauldImage from '../assets/images/favicon.png'
import { Link } from 'react-router-dom'
import { db } from '../base'


export const ProductList = () => {
    // const auth = useSelector(authState)
    const [coll, setColl] = useState([])
    const [roww, setroww] = useState([])
    const [initialColumn] = useState([])
    const localStorageName = 'benchesHidecolumn'
  
  
    // function MyCell({ value }) {
    //   return <img src={value !== 'null' && value !== '' ? value ?? defauldImage : defauldImage} width={32} height={32} />
    // }
    // function MyCell2({ row }) {
    //   if (row.original.matchedUserId != 'null') {
    //     return <button className='button-1' onClick={() => logoutUser(row.original.uid, row.original.matchedUserId)}>Logut User</button>
    //   }
    //   return null;
    // }
  
  


    function MyCell({ value }) {
        return <img alt={'imags'} src={value !== 'null' && value !== '' ? value ?? defauldImage : defauldImage} width={32} height={32} />
    }
    function MyCell2( {row} ) {
        // return <button className='button-1'>Details</button>
        return <Link className='button-1'  to={{ pathname:`productList/${row.original.uid}` ,state:row.original}}>Details</Link>
    }
  
    useEffect(() => {
      var admins
      var coll = [
        { Header: 'banner', accessor: 'banner',Cell:MyCell },
        { Header: 'title', accessor: 'title' },
        { Header: 'price', accessor: 'price' },
        { Header: 'quantity', accessor: 'quantity' },   
        { Header: '', accessor: 'Settinsgs',Cell: MyCell2}]
      const unsub = onSnapshot(collection(db, 'products'), (snapshot) => {
        admins = snapshot.docs.map((e) => {
          let a = e.data()
          return a
        })
        setColl(coll)
        setroww(admins)
      })
      return unsub
    }, [])

    return (
      <div>
        <h2 className="page-header" >
          Ürün Listesi
        </h2>
        <Table2 propsColumn={coll} propsRow={roww}
          localStorageName={localStorageName}
          hiddenColumnsPage={initialColumn}
        />
  
      </div>
    )
}
