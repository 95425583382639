import "./PointDetailProgressCard.css";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function PointDetailProgressCard({ userData, shopH }) {
  const [remainingRatio, setRemainingRatio] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(0);

  const [totalSpent, setTotalSpent] = useState(0);

  useEffect(() => {
    if (shopH) {
      const totalSpentRes = shopH
        .filter((shop) => shop.productPrice) // Filtreleme: productPrice değeri olanları al
        .reduce((total, shop) => total + shop.productPrice, 0); // Toplama işlemi: productPrice'ları topla

      const ratio = Math.round(
        ((userData.totalEarnedGain - totalSpentRes) /
          userData.totalEarnedGain) *
          100
      );
      setRemainingRatio(ratio);
      setTotalSpent(totalSpentRes);

      setCurrentPoint(userData.totalEarnedGain - totalSpentRes);
    }
  }, [shopH, userData]);

  const options = {
    series: [remainingRatio],
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "65%", // İlerleme çubuğunun iç kısmının boyutu
          background: "#f2f2f2", // İlerleme çubuğunun iç kısmının rengi
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
          },
          value: {
            offsetY: 10,
            fontSize: "24px",
            fontWeight: "bold",
            color: "#007bff", // İlerleme çubuğunun dolum rengi
          },
        },
      },
    },
  };

  return (
    <div className="point-detail-progress-card">
      <h2>Kullanılabilir Puan</h2>
      <ReactApexChart
        options={options}
        series={options.series}
        type="radialBar"
        height={300}
      />
      <div className="available-points">
        Kullanılabilir Puan : {currentPoint}
      </div>
      <div className="available-points">Harcanan Puan : {totalSpent} </div>
    </div>
  );
}

export default PointDetailProgressCard;
