import { configureStore} from '@reduxjs/toolkit'
import AuthReducer from './features/AuthSlice'
import themeSlice from './features/themeSlice'
import { authApi } from './services/authServices'
import companyDataReducer from './features/dataSlice'
import { companyDataApi } from './services/companyDataService'
import videoSlice from './features/videoSlice'
import { userAddApi } from './services/userAddServices'
export default configureStore({
  reducer: {
      auth:AuthReducer,
      theme:themeSlice,
      videos:videoSlice,
      [authApi.reducerPath]:authApi.reducer,
      companyData:companyDataReducer,
      [companyDataApi.reducerPath] :companyDataApi.reducer, 
      [userAddApi.reducerPath] : userAddApi.reducer 
  },
  middleware:(getDefaultMiddleware)=> getDefaultMiddleware().concat([authApi.middleware,companyDataApi.middleware,userAddApi.middleware])
})