import { createSlice } from '@reduxjs/toolkit' 

export const AuthSlice = createSlice({
    name:'Auth',
    initialState:{},
    reducers:{
        setUser : (state,action) => {
            state.user = action.payload
            // console.log("action.payload : ",action.payload);
            // kullanıcı giriş yaptığı zaman localStorage ta bilgileri tutar
            localStorage.setItem("user",JSON.stringify(action.payload))
        },
        deleteUser : (state)=>{
            state.user = null
        }
        
    }

})

export const {setUser,deleteUser} = AuthSlice.actions
export const authState = state => state.auth
export default AuthSlice.reducer