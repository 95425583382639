import React from "react";
import "./modal.css";

export const Modal = ({ children, close }) => {
  return (
    <div className="modal-container">
      <div className="modal-context">
        <div className="modal-header button-1" onClick={() => close(false)}>
          X
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};
