import React, { useState, useEffect } from "react";
import { db, store } from "../base";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from "firebase/firestore";
import defauldImage from "../assets/images/favicon.png";
import Table2 from "../components/table2/Table2";
import { Modal } from "../components/modal/Modal";

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { deleteObject, ref } from "firebase/storage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const custumerTableHead =[
//     "",
//     "name",
//     "email",
//     'phone',
//     'total orders',
//     'total speds',
//     'location'
// ]

// const renderHead = (item,index)=>(

//         <td key={index}>
//             {item}
//         </td>

// )
// const renderBody =(item,index)=>(
//     <tr key={index}>
//         <td>{item.id}</td>
//         <td>{item.name}</td>
//         <td>{item.email}</td>
//         <td>{item.phone}</td>
//         <td>{item.total_orders}</td>
//         <td>{item.total_spend}</td>
//         <td>{item.location}</td>
//     </tr>
// )

function Customers() {
  /* alert */
  const [snackShow, setSnackShow] = useState(false);
  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
  };

  const [coll, setColl] = useState([]);
  const [roww, setroww] = useState([]);
  const [initialColumn] = useState([]);

  const [modalOpen, setmodalOpen] = useState(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState();
  const [deleteCustomerName, setDeleteCustomerName] = useState();
  const [deleteCustomerCollectionId, setDeleteCustomerCollectionId] =
    useState();

  const localStorageName = "benchesHidecolumns";

  function MyCell({ value }) {
    return (
      <img
        className="img-cover"
        alt="cells"
        src={
          value !== "null" && value !== ""
            ? value ?? defauldImage
            : defauldImage
        }
        height={32}
      />
    );
  }

  function MyCell2({ row }) {
    // return <button className='button-1'>Details</button>
    return (
      <div className="button-group">
        <Link
          to={{
            pathname: `customer-detail/${row.original.id}`,
            state: row.original,
          }}
        >
          <button className="button-1">Details</button>
        </Link>
        <button
          className="button-1"
          onClick={() => {
            setDeleteCustomerId(row.original.uid);
            setDeleteCustomerName(row.original.name);
            setDeleteCustomerCollectionId(row.original.id);
            console.log(row.original);
            setmodalOpen(true);
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  useEffect(() => {
    var admins;
    var coll = [
      { Header: "", accessor: "pp", Cell: MyCell },
      { Header: "Company Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "İd", accessor: "uid" },
      { Header: "", accessor: "Settinsgs", Cell: MyCell2 },
    ];
    const unsub = onSnapshot(collection(db, "customers"), (snapshot) => {
      admins = snapshot.docs.map((e) => {
        let a = e.data();
        let b = e.id;

        return { ...a, id: b };
      });
      setColl(coll);
      setroww(admins);
    });
    return unsub;
  }, []);

  // Fonksiyon, verilen klasördeki mevcut fotoğrafı siler
  const deleteCurrentPhoto = async (folder, currentPhoto) => {
    try {
      const photoRef = ref(store, `${folder}/${currentPhoto}`);
      console.log(photoRef);
      await deleteObject(photoRef);
      console.log(`Mevcut fotoğraf silindi: ${currentPhoto}`);
    } catch (error) {
      console.error(`Mevcut fotoğrafı silerken bir hata oluştu: ${error}`);
    }
  };

  /* customer silme işlemi */
  async function deleteCustomer(
    customerId,
    customerName,
    customerCollectionId
  ) {
    try {
      const q = query(collection(db, "videoAds"));
      onSnapshot(q, async (snapshot) => {
        const docs = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data.companyName === customerName) {
            docs.push(data.videoId);
          }
        });
        console.log("docs : ", docs);
        console.log(
          "customerId : ",
          customerId,
          "docs.videoId : ",
          docs.videoId,
          "docs.id : ",
          docs.id
        );
        /* 
                doc.id = customerstaki video objelerinin idsi
                doc.videoId = objelerin içindeki videoId
                customerId = reklamverenin uid si
                */

        /* storagetaki video ve fotoğrafları siler */
        await deleteCurrentPhoto("customers", customerId);

        docs.forEach(async (document) => {
          await deleteDoc(doc(db, "videoAds", document)); // "customer" koleksiyonundan belirtilen belge silinir
          await deleteCurrentPhoto("videoBanner", document);
          await deleteCurrentPhoto("vbTabletAdsClick", document);
          await deleteCurrentPhoto("videoAds2", document);
          await deleteCurrentPhoto("videoAds2/EnVideo", document);
          await deleteCurrentPhoto("videoAds2/TrVideo", document);
          await deleteCurrentPhoto("videoAds2/RuVideo", document);
        });

        await deleteDoc(doc(db, "customers", customerCollectionId)); // "customer" koleksiyonundan belirtilen belge silinir

        console.log("Müşteri başarıyla silindi");
        setSnackShow(true);
      });
    } catch (error) {
      console.error("Hata oluştu: ", error);
    }

    setmodalOpen(false);
  }

  return (
    <div>
      <h2 className="page-header">Müşteriler</h2>
      <div className="row">
        <div className="col-12">
          {/* <Table 
                                headData = {custumerTableHead}
                                renderBody = {(item,index)=>renderBody(item,index)}
                                renderHead = {(item,index)=>renderHead(item,index)}
                                bodyData = {customersList}
                                limit='10'

                            /> */}

          <Table2
            propsColumn={coll}
            propsRow={roww}
            localStorageName={localStorageName}
            hiddenColumnsPage={initialColumn}
          />

          {modalOpen && (
            <Modal close={setmodalOpen}>
              <div className="form-field ">
                <label>Uyarı : </label>
                <div>
                  <p>
                    {" "}
                    {deleteCustomerId}'idli müşteri silinecek bu işlemi geri
                    alamassınız emin misiniz ?{" "}
                  </p>
                </div>
              </div>
              <button
                className="button-1"
                onClick={() => {
                  deleteCustomer(
                    deleteCustomerId,
                    deleteCustomerName,
                    deleteCustomerCollectionId
                  );
                }}
              >
                Onay
              </button>
            </Modal>
          )}

          <Snackbar
            open={snackShow}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Müşteri başarıyla silindi
            </Alert>
          </Snackbar>
        </div>
      </div>
      {/* <FullPageLoading /> */}
    </div>
  );
}
export default Customers;
