import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { store, db, auth } from '../base';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSetCustomersMutation, useSetAdminMutation, useSetDriverMutation } from '../redux/services/userAddServices';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import losvg from '../assets/images/spinning-loading.gif'

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, addDoc, doc, updateDoc,setDoc } from "firebase/firestore";



export const UserAdd = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [snackShow, setSnackShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const [userrole, setUserrole] = useState('user')
    const [imgUrl, setimgUrl] = useState()
    const [file, setFile] = useState()

    const selectecChange = (value) => (setUserrole(value.target.value))
    const uploadImg = (value) => {
        if (value.target.files.length !== 0) {
            setimgUrl(URL.createObjectURL(value.target.files[0]))
            setFile(value.target.files[0])
        } else {
            if (file && imgUrl) {
                setimgUrl()
                setFile()
            }
        }
    }

    const [
        createUser,
        { isLoading: isUpdating },
    ] = useSetCustomersMutation()

    const [
        createAdmin,

    ] = useSetAdminMutation()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackShow(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [
        createDriver,

    ] = useSetDriverMutation()


    const onSubmit = async (data) => {
        setLoading(true)
        if (data.role === 'Customer') {
        
            try {
                // Firebase Authentication üzerinde kullanıcı oluştur
                const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
    
                // Kullanıcının UID'sini al
                const userId = userCredential.user.uid;
    
                // Firestore'da kullanıcı bilgilerini saklamak için referans oluştur
                const customerDocRef = doc(db, "customers", userId);
    
                // Storage'de profil resmini yükle ve URL'yi al
                const storageRef = ref(store, `customers/${userId}`);
                const profilePicture = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(profilePicture.ref);
    
                // Firestore'da belgeyi oluştur/güncelle
                await setDoc(customerDocRef, {
                    uid: userId,
                    email: data.email,
                    name: data.username,
                    fullName: data.username,
                    role: data.role,
                    photoURL: downloadURL,
                    pp: downloadURL,
                });
    
                // Başarılı işlem sonrası durumları sıfırla
                reset();
                setSnackShow(true);
    
            } catch (error) {
                console.error("Error adding document: ", error);
            }
    
            setLoading(false);
        }
        if (data.role === 'Admin') {
            let res = await createAdmin({ email: data.email, passworld: data.password, name: data.username })
            if (res?.err) {

            } else {
                reset()
                setSnackShow(true)
                setLoading(false)
            }

        }
        if (data.role === 'Drivers') {
            let res = await createDriver({ email: data.email, passworld: data.password, name: data.username })
            if (res?.err) {

            } else {
                reset()
                setSnackShow(true)
                setLoading(false)
            }

        }


    };


    return (

        <div>
            {isUpdating && <div>Beklenior</div>}
            <div className="row">
                <div className="card full-width ds-flex">
                    <div className="col-8 ">
                        <div className="formuseradd">
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <div className='formlabel'>Rol</div>
                                    <select  {...register("role")} onChange={selectecChange}>
                                        <option value="User">Yolcu</option>
                                        <option value="Customer">Reklam Veren</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Drivers">Şöför</option>
                                    </select>
                                </div>
                                <div >
                                    <div className='formlabel'>İsim: </div>
                                    <input className='frominput' {...register("username", { required: true })}></input>
                                    {errors.username && <span>Zorunlu Alan*</span>}

                                </div>
                                <div>
                                    <div className='formlabel'>Email : </div>
                                    <input className='frominput' placeholder='admin@oetech.com' {...register("email", { required: true, minLength: 8 })} ></input>
                                    {errors.email && <span>Zorunlu Alan*</span>}
                                </div>
                                <div>
                                    <div className='formlabel'>Şifre : </div>
                                    <input type='password' className='frominput' {...register("password", { required: true, minLength: 8 })}  ></input>
                                    {errors.password?.type === 'minLength' && <span>Min 8 char</span>}
                                    {errors.password?.type === 'required' && <span>Zorunlu Alan*</span>}
                                </div>
                                {
                                    (userrole === 'Customer') &&
                                    <div>
                                        <div className='formlabel'>Profil Foto:</div>
                                        <div>
                                            <input type="file" onChange={uploadImg} accept="image/png, image/jpeg" />
                                        </div>
                                    </div>
                                }
                                <input className='denemes' type="submit" />
                            </form>
                        </div>
                    </div>
                    <div className='col-4 mg-top'>
                        {imgUrl && <img className='userimage' src={imgUrl} alt="" />}
                        {loading && <img className='userimage' src={losvg} alt="" />}
                    </div>
                </div>
            </div>
            <Snackbar open={snackShow} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Kullanıcı Oluşturma Başarılı!!
                </Alert>
            </Snackbar>
        </div>
    )
}
