import "../assets/css/notificationPage.css"
import React, { useEffect, useState } from 'react'

import { collection, doc, getDoc,  onSnapshot, updateDoc, } from "firebase/firestore";

import { db } from '../base'
import { useSelector } from "react-redux";
import axios from "axios";


function Notifications() {
    const themeReducer = useSelector(state => state.theme);

    const [selectedMessage, setSelectedMessage] = useState(null);
    const [reply, setReply] = useState("");
    const [ticketStatus, setTicketStatus] = useState();

    const [searchText, setSearchText] = useState("");

    const [category, setCategory] = useState("all");
    const [statusCategory, setStatusCategory] = useState("all-status");

    const [messages, setMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);

    const [allMessages, setAllMessages] = useState([]);
    const [pointMessages, setPointMessages] = useState([]);
    const [videoMessages, setVideoMessages] = useState([]);
    const [loginMessages, setLoginMessages] = useState([]);
    const [tabletMessages, setTabletMessages] = useState([]);
    const [phoneMessages, setPhoneMessages] = useState([]);
    const [otherMessages, setOtherMessages] = useState([]);

    const [onMessages, setOnMessages] = useState();

    /* mesaj verileri */
    useEffect(() => {
        const unsub = onSnapshot(collection(db, "/helpCenter"), (snapshot) => {
            const notification = snapshot.docs.map((doc) => {
                return { ...doc.data(), msgId: doc.id }
            });

            setAllMessages(() => notification.filter(item => item.isRead === false))

            const pointNotify = notification.filter(notif => notif.category === "point")
            setPointMessages(() => pointNotify.filter(item => item.isRead === false))

            const loginNotify = notification.filter(notif => notif.category === "login")
            setLoginMessages(() => loginNotify.filter(item => item.isRead === false))

            const videoNotify = notification.filter(notif => notif.category === "video")
            setVideoMessages(() => videoNotify.filter(item => item.isRead === false))

            const tabletNotify = notification.filter(notif => notif.category === "tablet")
            setTabletMessages(() => tabletNotify.filter(item => item.isRead === false))

            const phoneNotify = notification.filter(notif => notif.category === "phone")
            setPhoneMessages(() => phoneNotify.filter(item => item.isRead === false))

            const otherNotify = notification.filter(notif => notif.category === "other")
            setOtherMessages(() => otherNotify.filter(item => item.isRead === false))


            switch (category) {
                case "all":

                    setMessages(notification)

                    break;
                case "point":

                    setMessages(pointNotify)

                    break;
                case "login":

                    setMessages(loginNotify)

                    break;
                case "video":

                    setMessages(videoNotify)

                    break;
                case "tablet":

                    setMessages(tabletNotify)

                    break;
                case "phone":

                    setMessages(phoneNotify)

                    break;
                case "other":

                    setMessages(otherNotify)

                    break;
                default:
                    setMessages(notification);
                    break;
            }

        });

        return unsub;

    }, [category]);


    /* anlık sohbet mesajı dinleme */
    useEffect(() => {
        if (selectedMessage) {

            setTicketStatus(selectedMessage.status);

            const unsubscribe = onSnapshot(
                doc(collection(db, '/helpCenter'), selectedMessage.msgId),
                (doc) => {
                    setOnMessages({ ...doc.data(), msgId: doc.id });
                }
            );
            return unsubscribe;
        }
    }, [selectedMessage]);

    /* mesaj geldiğinde scroll aşağıya iner */
    useEffect(() => {
        const messageContainer = document.querySelector('.message-container');
        messageContainer.scrollTop = messageContainer.scrollHeight;
    }, [onMessages]);

    function handleSearch(e) {
        setSearchText(e.target.value)
    }

    /* mesaj arama  */
    useEffect(() => {
        const messagesData = messages.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.time) - new Date(a.time);
        });

        let statusFilter;
        switch (statusCategory) {
            case "all-status":

                statusFilter = messagesData;

                break;
            case "send":

                statusFilter = messagesData.filter(mail => mail.status === "send");

                break;

            case "review":

                statusFilter = messagesData.filter(mail => mail.status === "review");

                break;

            case "resolved":

                statusFilter = messagesData.filter(mail => mail.status === "resolved");

                break;
            default:

                statusFilter = messagesData;

                break;
        }

        const filteredMails = statusFilter.filter(mail => {

            let stringTicketId = mail.supportTicketId.toString();

            return stringTicketId.includes(searchText.toLowerCase()) || mail.userName.toLowerCase().includes(searchText.toLowerCase());

        });

        if (filteredMails) {

            if (filteredMails.length > 0) {
                setFilteredMessages(filteredMails)
            } else {
                setFilteredMessages([])
            }

        } else {
            setFilteredMessages(messagesData)
        }

    }, [searchText, messages, statusCategory]);


    /* seçilen mesaj */
    async function handleSelectMessage(message) {

        setSelectedMessage(message);


        if (!message.isRead) {
            const refDoc = doc(db, "helpCenter", message.msgId);
            /* mesajın okunma durumu güncellemesi ve durumunun inceleniyor olarak değiştirilmesi*/
            await updateDoc(refDoc, {
                isRead: true
            })
        }

    }

    useEffect(() => {

        async function setTicketStatus() {
            if (ticketStatus) {
                const refDoc = doc(db, "helpCenter", selectedMessage.msgId);
                /* mesajın okunma durumu güncellemesi ve durumunun inceleniyor olarak değiştirilmesi*/
                await updateDoc(refDoc, {
                    status: ticketStatus
                })
            }
        }

        setTicketStatus();

    }, [ticketStatus]);



    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    /* mesaj ekleme(firebase) */
    const addMessage = async (ticketId, message) => {
        const ticketDocRef = doc(db, "/helpCenter", ticketId);
        try {
            await updateDoc(ticketDocRef, {
                messages: [...onMessages.messages, message]
            });
        } catch (error) {
            console.error("Error adding message:", error);
        }
    };

    const handleReplySubmit = async (event) => {
        event.preventDefault();

        const message = {
            sender: "admin",
            sendTime: Date.now(),
            text: reply,
        }
        addMessage(selectedMessage.msgId, message);

        const docRef = doc(db, "users", selectedMessage.UserUid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dats = docSnap.data();
            const messagePostObj = {
                tkn: dats.msgToken,
                title: 'Vimi Help Center',
                body: `${selectedMessage.title} başlıklı talebiniz cevaplandı.`,
                route: '/HelpCenter'
            };

            // Sadece belirli kullanıcıya bildirim gönder
            axios.post('https://us-central1-vimitv-admin.cloudfunctions.net/sendMessage', messagePostObj);
        } else {
            console.log(`UID'si ${selectedMessage.UserUid} olan kullanıcı bulunamadı.`);
        }


        setReply("");
    };


    return (
        <div className={`gmail-container ${themeReducer.mode} ${themeReducer.color}`}>

            <form defaultChecked="all" className="gmail-categories-bar">

                <label className={`${category === "all" ? "selected-category" : ""}`} htmlFor="all">
                    {allMessages.length > 0 && (
                        <span className="badge">{allMessages.length}</span>
                    )}
                    All Problem
                </label>
                <input type="radio" name="category" id="all" value="all" onChange={(e) => setCategory(e.target.value)} />

                <label className={`${category === "point" ? "selected-category" : ""}`} htmlFor="point">
                    {pointMessages.length > 0 && (
                        <span className="badge">{pointMessages.length}</span>
                    )}
                    Point Problem
                </label>
                <input type="radio" name="category" id="point" value="point" onChange={(e) => setCategory(e.target.value)} />

                <label className={`${category === "video" ? "selected-category" : ""}`} htmlFor="video">
                    {videoMessages.length > 0 && (
                        <span className="badge">{videoMessages.length}</span>
                    )}
                    Video Problem
                </label>
                <input type="radio" name="category" id="video" value="video" onChange={(e) => setCategory(e.target.value)} />

                <label className={`${category === "login" ? "selected-category" : ""}`} htmlFor="login">
                    {loginMessages.length > 0 && (
                        <span className="badge">{loginMessages.length}</span>
                    )}
                    Login Problem
                </label>
                <input type="radio" name="category" id="login" value="login" onChange={(e) => setCategory(e.target.value)} />

                <label className={`${category === "tablet" ? "selected-category" : ""}`} htmlFor="tablet">
                    {tabletMessages.length > 0 && (
                        <span className="badge">{tabletMessages.length}</span>
                    )}
                    Tablet Problem
                </label>
                <input type="radio" name="category" id="tablet" value="tablet" onChange={(e) => setCategory(e.target.value)} />

                <label className={`${category === "phone" ? "selected-category" : ""}`} htmlFor="phone">
                    {phoneMessages.length > 0 && (
                        <span className="badge">{phoneMessages.length}</span>
                    )}
                    Phone Problem
                </label>
                <input type="radio" name="category" id="phone" value="phone" onChange={(e) => setCategory(e.target.value)} />

                <label className={`${category === "other" ? "selected-category" : ""}`} htmlFor="other">
                    {otherMessages.length > 0 && (
                        <span className="badge">{otherMessages.length}</span>
                    )}
                    Other Problem
                </label>
                <input type="radio" name="category" id="other" value="other" onChange={(e) => setCategory(e.target.value)} />
            </form>

            <div className="left-panel-container">
                <div className="left-panel-status-info">
                    <h6>Send : <span className="status-send">&nbsp;</span></h6>
                    <h6>Review : <span className="status-review">&nbsp;</span></h6>
                    <h6>Resolved : <span className="status-resolved">&nbsp;</span></h6>
                </div>
                <div className="left-panel">

                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search ticket id or name"
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="status-filter-buttons">
                        <label className={`${statusCategory === "all-status" ? "selected-status-category" : ""}`} htmlFor="all-status">
                            All
                        </label>
                        <input type="radio" name="statusCategory" id="all-status" value="all-status" onChange={(e) => setStatusCategory(e.target.value)} />

                        <label className={`${statusCategory === "send" ? "selected-status-category" : ""}`} htmlFor="send">
                            Send
                        </label>
                        <input type="radio" name="statusCategory" id="send" value="send" onChange={(e) => setStatusCategory(e.target.value)} />

                        <label className={`${statusCategory === "review" ? "selected-status-category" : ""}`} htmlFor="review">
                            Review
                        </label>
                        <input type="radio" name="statusCategory" id="review" value="review" onChange={(e) => setStatusCategory(e.target.value)} />

                        <label className={`${statusCategory === "resolved" ? "selected-status-category" : ""}`} htmlFor="resolved">
                            Resolved
                        </label>
                        <input type="radio" name="statusCategory" id="resolved" value="resolved" onChange={(e) => setStatusCategory(e.target.value)} />

                    </div>
                    <div className="message-list-container">
                        <div className="message-list">

                            {
                                filteredMessages.map((message) => (
                                    <div
                                        key={message.supportTicketId}
                                        className={`message ${selectedMessage && selectedMessage.supportTicketId === message.supportTicketId
                                            ? "selected"
                                            : ""
                                            }`}
                                        onClick={() => handleSelectMessage(message)}
                                    >{/* <span className="read-icon">*</span> */}
                                        <div className="message-icons">
                                            <i className={`bx bxs-envelope ${message.isRead ? "read-true" : "read-false"} read-icon `} />
                                            <div
                                                className={`${message.status === "send" ? "status-send" : message.status === "review" ? "status-review" : message.status === "resolved" ? "status-resolved" : ""}`}
                                            >&nbsp;</div>
                                        </div>

                                        <div className="message-info-box">
                                            <div className={`sender ${message.isRead ? "" : "read-false"}`}>{message.userName}</div>
                                            <div className="message-id">{message.supportTicketId}</div>
                                            <div className="subject">{message.title}</div>
                                            <div className="timestamp">{new Date(message.time).toLocaleString("tr-TR")}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="gmail-main">

                <div style={{ display: selectedMessage ? "inline" : "none" }} className='right-panel-container'>

                    {
                        selectedMessage &&
                        <div className="right-panel-top">
                            <h6>Ticket No : {onMessages?.supportTicketId}</h6>
                            <h3>{onMessages?.title}</h3>
                            <h6>Ticket Status : <span
                                className={`${onMessages?.status === "send" ? "status-send" : onMessages?.status === "review" ? "status-review" : onMessages?.status === "resolved" ? "status-resolved" : ""}`}
                            >&nbsp;</span> {onMessages?.status}</h6>
                        </div>
                    }

                    <div className="message-container">

                        {
                            onMessages && onMessages.messages.sort((a, b) => a.sendTime - b.sendTime) // Sort messages by send time
                                .map((message, i) => {
                                    return (
                                        <div key={i} className={`message-main ${message.sender === "admin" ? "message-me" : "message-other"}`}>
                                            <div className={`message-box`}>
                                                <div className="message-sender">{message.sender === "admin" ? "Admin" : selectedMessage.userName}({message.sender})</div>
                                                <div className="message-body">{message.text}</div>
                                                <div className="message-timestamp">{new Date(message.sendTime).toLocaleString("tr-TR")}</div>
                                            </div>
                                        </div>
                                    );
                                })

                        }

                    </div>

                    <form onSubmit={handleReplySubmit}>
                        <div className="reply-input">
                            <input
                                type="text"
                                placeholder="Reply..."
                                value={reply}
                                onChange={handleReplyChange}
                            // disabled={selectedMessage.feddback}
                            />
                        </div>

                        <div className="set-status-container">

                            <button
                                type="submit"
                            // disabled={selectedMessage.feddback}
                            >
                                Send
                            </button>

                            <div className="ticket-status-box">
                                <p>Set ticket status :</p>
                                <label className={`${ticketStatus === "send" ? "set-ticket-status-select" : ""}`} htmlFor="send-set">
                                    Send
                                </label>
                                <input type="radio" name="ticketStatus" id="send-set" value="send" onChange={(e) => setTicketStatus(e.target.value)} />

                                <label className={`${ticketStatus === "review" ? "set-ticket-status-select" : ""}`} htmlFor="review-set">
                                    Review
                                </label>
                                <input type="radio" name="ticketStatus" id="review-set" value="review" onChange={(e) => setTicketStatus(e.target.value)} />

                                <label className={`${ticketStatus === "resolved" ? "set-ticket-status-select" : ""}`} htmlFor="resolved-set">
                                    Resolved
                                </label>
                                <input type="radio" name="ticketStatus" id="resolved-set" value="resolved" onChange={(e) => setTicketStatus(e.target.value)} />
                            </div>
                        </div>
                    </form>


                </div>

                <div style={{ display: selectedMessage ? "none" : "block", width: "100%" }} className="no-message-selected">Select a message</div>

            </div>

        </div>
    );
}

export default Notifications;