import React from 'react'
import './tableFilter.css'

export const TableFilter = ({filter,setFilter}) => {
    return (
        <span>
            Search : {' '}
            <input className='seach_input' value={filter || '' } 
            onChange ={e => setFilter(e.target.value)}
            />
        </span>
    )
}
