import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'



export const companyDataApi = createApi({
    reducerPath: 'companyDataApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:3002/api/' }),
    endpoints: (builder) => ({
        getUretimData: builder.query({
            query: (sirketCodu) => ({
                url: 'urerim/getdata',
                method: 'POST',
                body: {sirket:sirketCodu},
                
                
            }),
            transformResponse:(response) =>{
                var uretim = response.data.filter((e) => e.Durum === 'URETIM')
                var durus = response.data.filter((e) => e.Durum === 'DURUS')
                var kapalı = response.data.filter((e) => e.Durum === 'KAPALI')
                var proje = response.data.filter((e) => e.Durum === 'PROJE_DENEME')
                var data = {
                    salddata: response.data,
                    states:
                        [
                            uretim,
                            durus,
                            kapalı,
                            proje
                        ]
                }
                return data
            }
        }),
        getRaporData: builder.query({
            query:(sirketCodu) => ({
                url:'urerim/getoerapor',
                method:'POST',
                body:{sirket:sirketCodu}
            })
        })
    }),
})

export const { useGetUretimDataQuery,useGetRaporDataQuery } = companyDataApi