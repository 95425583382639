import React, { useState, useEffect } from "react";
import Table2 from "../components/table2/Table2";
// import { useSelector } from 'react-redux'
// import { authState } from '../redux/features/AuthSlice'
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import defauldImage from "../assets/images/favicon.png";
import { Link } from "react-router-dom";
import { db } from "../base";
import { Modal } from "../components/modal/Modal";
import "../assets/css/users.css";

function Users() {
  const [logoutModal, setLogoutModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [editLoading, setEditLoading] = useState(false);

  //  const auth = useSelector(authState)
  const [coll, setColl] = useState([]);
  const [roww, setroww] = useState([]);
  const [initialColumn] = useState([]);
  const localStorageName = "benchesHidecolumn";

  function MyCell({ value }) {
    return (
      <img
        alt={"images"}
        src={
          value !== "null" && value !== ""
            ? value ?? defauldImage
            : defauldImage
        }
        width={32}
        height={32}
      />
    );
  }
  function MyCell2({ row }) {
    // return <button className='button-1'>Details</button>
    console.log(row.original.matchedDriverId);
    return (
      <div className="users-table-buttons">
        {row.original.matchedDriverId !== "null" ? (
          <button className="logout-btn" onClick={() => setLogoutModal(true)}>
            LOGOUT
          </button>
        ) : null}
        <Link
          className="detail-btn"
          to={{ pathname: `users/${row.original.uid}`, state: row.original }}
        >
          Details
        </Link>
      </div>
    );
  }
  useEffect(() => {
    var admins;
    var coll = [
      { Header: "", accessor: "pp", Cell: MyCell },
      { Header: "Email", accessor: "email" },
      { Header: "FullName", accessor: "fullName" },
      { Header: "VideoWached", accessor: "totalVideoWatched" },
      { Header: "Gain", accessor: "totalEarnedGain" },
      { Header: "", accessor: "Settinsgs", Cell: MyCell2 },
    ];
    const unsub = onSnapshot(collection(db, "users"), (snapshot) => {
      admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setColl(coll);
      setroww(admins);
    });
    return unsub;
  }, []);

  async function logoutUser(driverId, userId) {
    const refDoc = doc(db, "drivers", driverId);
    const refDoc2 = doc(db, "users", userId);
    await updateDoc(refDoc, {
      matchedUserId: "null",
      matchedUserName: "null",
    });
    await updateDoc(refDoc2, {
      matchedDriverId: "null",
    });
    let data = { ...userData, matchedDriverId: "null" };
    setUserData(data);
  }

  return (
    <div>
      <h2 className="page-header">Normal Kullanıcılar</h2>
      <Table2
        propsColumn={coll}
        propsRow={roww}
        localStorageName={localStorageName}
        hiddenColumnsPage={initialColumn}
      />
      {logoutModal && (
        <Modal
          close={() => setLogoutModal(false)}
          children={
            <div className="logout-modal-container">
              <p>Sürücü ile bağlantınızı koparmak istediğinize emin misiniz?</p>

              <div className="edit-buttons">
                <button onClick={() => setLogoutModal(false)}>Hayır</button>
                <button
                  onClick={async () => {
                    await logoutUser(userData.matchedDriverId, userData.uid);
                    alert("Sürücü ile bağlantınız koparıldı.");
                  }}
                >
                  Evet
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
}

export default Users;
