import React, { useState, useEffect } from 'react';
import { onSnapshot, doc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { db, store } from '../../base';
import { useParams } from 'react-router-dom';
import anon from '../../Asset/Img/anonymous.png'
import ProfileCard from './ProfileCard';

export const DriversDetails = () => {
  const { driverId } = useParams();
  const [driver, setDriver] = useState(null);
  const [driverImageURL, setDriverImageURL] = useState(null);

  useEffect(() => {
      const driverRef = doc(db, 'drivers', driverId);
      onSnapshot(driverRef, (doc) => {
        if (doc.exists()) {
          setDriver(doc.data());
  
          const imageRef = ref(store, `driverPP/${doc.data().uid}`);
          getDownloadURL(imageRef)
            .then(url => {
              setDriverImageURL(url);
            })
            .catch(error => {
              if (error.code === 'storage/object-not-found') {
                setDriverImageURL(anon);
              }
            });
        }
      });
    }, [driverId]);

  if (!driver) return <div>Loading...</div>;

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {driverImageURL && <img src={driverImageURL} alt="Driver" style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '20px' }} />}
        <div>
          <h2>{driver.fullName}</h2>
          <p>{driver.email}</p>
          {/* Diğer driver bilgilerini burada gösterebilirsiniz */}
          <p>I distinguish three main text objectives could be merely to inform people...</p>
          <p><strong>Affiliate: 78%</strong></p>
        </div>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h3>Reklam Aktivitesi</h3>
        {/* Dummy data for the table */}
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th>Reklam</th>
              <th>Kazanılan Puan</th>
              <th>Durum</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Domino's Pizza 3+1</td>
              <td>25</td>
              <td><span style={{ color: 'green' }}>Success</span></td>
              <td>[...]</td>
            </tr>
            <tr>
              <td>Yenilikler 5</td>
              <td>48</td>
              <td><span style={{ color: 'red' }}>Rejected</span></td>
              <td>[...]</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>

      {/* Other sections go here */}
      <div>
        <h3>Kullanılabilir Puan: 74%</h3>
        {/* Add your charts or other content here */}
      </div>

      <ProfileCard />
    </div>
  );
}

export default DriversDetails;
