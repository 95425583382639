import React from 'react';
import './Profile.css';

function ProfileCard() {


    















    return (
        <div className="profile-card">
            <img src="avatar-image-link-here" alt="Ender Avatar" className="profile-avatar" />
            <div className="profile-name">Ender</div>
            <div className="profile-email">ender@vimi.tv</div>
            <p>I distinguish three main text objectives could be merely to inform people...</p>
            <div className="affiliate">Affiliate: 78%</div>
            <ActivityTable />
            <div>Kullanılabilir Puan: 74%</div>
        </div>
    );
}

function ActivityTable() {
    const activities = [
        { reklam: "Domino's Pizza 3+1", puan: 25, durum: 'Success', islemler: '[...]' },
        { reklam: "Yenilikler 5", puan: 48, durum: 'Rejected', islemler: '[...]' }
    ];

    return (
        <table className="activity-table">
            <thead>
                <tr>
                    <th>Reklam</th>
                    <th>Kazanılan Puan</th>
                    <th>Durum</th>
                    <th>İşlemler</th>
                </tr>
            </thead>
            <tbody>
                {activities.map((activity, index) => (
                    <tr key={index}>
                        <td>{activity.reklam}</td>
                        <td>{activity.puan}</td>
                        <td>{activity.durum}</td>
                        <td>{activity.islemler}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default ProfileCard;
