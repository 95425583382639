import React,{useEffect} from 'react'

export const Dowloand = () => {

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    
        return "unknown";
    }

    useEffect(() => {

        if(getMobileOperatingSystem() === 'Android' ){
            window.location.assign('https://play.google.com/store/apps/details?id=com.vimitv.mobile.user&gl=TR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1')
        }
        else if(getMobileOperatingSystem() === 'iOS'){
            window.location.assign('https://apps.apple.com/sa/app/vimi-tv/id1581068601')
        }
        else{
            window.location.assign('https://vimi.tv/')
        }
        
       
    }, [])
    return (
        <div>
            sa
        </div>
    )
}
