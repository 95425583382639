import React from "react";
import { Route, Switch } from "react-router-dom";
import Admins from "../pages/Admins";
import Customers from "../pages/Customers";
import { Dashboard } from "../pages/Dashboard";
import { Drivers } from "../pages/soforler/Drivers";
import { DriversDetails } from "../pages/soforler/DriversDetails";
import Notifications from "../pages/Notifications";
import { Orders } from "../pages/Orders";
import ProducDetails from "../pages/ProducDetails";
import { Product } from "../pages/Product";
import { ProductList } from "../pages/ProductList";
import { UserAdd } from "../pages/UserAdd";
import Users from "../pages/Users";
import { UsersDetail } from "../pages/UsersDetail";
import { VideoDetail } from "../pages/VideoDetail";
import { Categories } from "../pages/Categories";
import { TbtSlader } from "../pages/TbtSlader";
import Videos from "../pages/Videos";

import { VideouUpload } from "../pages/VideouUpload";
import { TbtSliderDetail } from "../pages/TbtSliderDetail";
import CustomerDetail from "../pages/CustomerDetail";
import DashboardCustomer from "../pages/musteriler/DashboardCustomer";

import {MusteriVideolari }  from "../pages/musteriler/videolari/musteriVideolari";

function Routers() {
  return (
    <Switch>
      <Route path="/evgrs" component={Dashboard} exact />
      <Route path="/evgrs/customers" component={Customers} />
      <Route path="/evgrs/customer-detail/:id" component={CustomerDetail} />
      <Route path="/evgrs/products" component={Customers} />
      <Route path="/evgrs/admins" component={Admins} />
      <Route path="/evgrs/users" exact component={Users} />
      <Route path="/evgrs/users/:id" component={UsersDetail} />
      <Route path="/evgrs/userAdd" component={UserAdd} />
      <Route path="/evgrs/videoUpload" component={VideouUpload} />
      <Route path="/evgrs/videos" exact component={Videos} />
      <Route path="/evgrs/videos/:id" component={VideoDetail} />
      <Route path="/evgrs/product" component={Product} />
      <Route path="/evgrs/drivers" component={Drivers} />
       <Route path="/evgrs/driversdetails/:driverId" component={DriversDetails} />
      <Route path="/evgrs/order" component={Orders} />
      <Route path="/evgrs/productList" exact component={ProductList} />
      <Route path="/evgrs/productList/:id" component={ProducDetails} />
      <Route path="/evgrs/categories" component={Categories} />
      <Route path="/evgrs/tbtslider" component={TbtSlader} />
      <Route path="/evgrs/tbtslider-detail/:id" component={TbtSliderDetail} />
      <Route path="/evgrs/videolar/:id" component={MusteriVideolari} />
      <Route path="/evgrs/notifications" component={Notifications} />
      <Route path="/evgrs/dashboardCustomer" component={DashboardCustomer} />
    </Switch>
  );
}

export default Routers;
