import "../assets/css/tabletSliderPage.css";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import { store, db } from "../base";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDropzone } from "react-dropzone";

import FullPageLoading from "../components/fullpageloading/FullPageLoading";
import { Modal } from "../components/modal/Modal";
import SelectLocation from "../components/SeletLocation/SelectLocation";

const DeleteMain = ({ text, deleteBtnClick, cancelBtnClick }) => {
  return (
    <div style={{ padding: "1rem" }}>
      <p style={{ marginBottom: "1rem" }}>{text}</p>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
        <button className="button-1" onClick={deleteBtnClick}>
          Delete
        </button>
        <button className="button-1" onClick={cancelBtnClick}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export const TbtSlader = () => {
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [sliderFiles, setSliderFiles] = useState([]);
  const [snackShow, setSnackShow] = useState(false);
  const [slidersData, setSlidersData] = useState([]);

  const [modalText, setModalText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openCoordModal, setOpenCoordModal] = useState(false);
  const [openBannerLinkInput, setOpenBannerLinkInput] = useState(false);

  const [videoCoordinates, setVideoCoordinates] = useState([]);

  const [customers, setCustomers] = useState("");
  const [customersIcon, setCustomersIcon] = useState("");
  const [customersName, setCustomersName] = useState("");

  const { register, handleSubmit, reset, formState, setValue, getValues } =
    useForm({
      defaultValues: {
        coordStatus: false,
      },
    });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
  };

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "customers"), (snapshot) => {
      let admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setCustomers(admins);
    });
    return unsub;
  }, []);

  useEffect(() => {
    const fetchSlidersData = async () => {
      try {
        const docRef = doc(db, "tabletconfig", "sliderConfig");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const slidersData = docSnap.data().sliders || [];
          // Verileri ters sıralayarak en son eklenen verileri en başta gösteriyoruz
          setSlidersData(
            slidersData.sort(
              (a, b) => Number(a.sliderNumber) - Number(b.sliderNumber)
            )
          );
          const values = getValues();
          console.log(values);
          setValue("sliderNumber", slidersData.length + 1);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error fetching sliders data:", error);
      }
    };

    fetchSlidersData();
  }, [addLoading, deleteLoading]);

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setSliderFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/bmp": [".bmp"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/tiff": [".tiff"],
      "image/x-pcx": [".pcx"],
      "image/x-tiff": [".tiff"],
    },
  });

  const normalized = (value) => {
    return (
      value
        .match(/\d{1,2}/g)
        ?.join(":")
        .substr(0, 5) ?? ""
    );
  };

  const onSubmit = async (data) => {
    if (sliderFiles.length === 0) {
      alert("Lütfen en az bir dosya seçiniz");
      return;
    }
    if (
      slidersData[slidersData.length - 1].sliderNumber === data.sliderNumber
    ) {
      // alert();
      setModalText(
        "Bu numarayla kayıtlı slider var, lütfen başka numara giriniz!"
      );
      setOpenModal(true);
      return;
    }

    const imagePromises = sliderFiles.map((file) => {
      const storageRef = ref(store, `/tabletSlider/${file.name}`);
      return uploadBytes(storageRef, file);
    });
    console.log("videoCoordinates : ", videoCoordinates);
    setAddLoading(true);

    await Promise.all(imagePromises)
      .then((uploadSnapshots) => {
        const downloadUrls = uploadSnapshots.map((snap) =>
          getDownloadURL(snap.ref)
        );
        return Promise.all(downloadUrls);
      })
      .then((urls) => {
        const newSliders = sliderFiles.map((file, index) => ({
          id: uuidv4(), // UUID ile yeni bir benzersiz kimlik oluşturur
          imageName: file.name, // İmage ismi burada belirtilebilir
          imageTitle: `Slider ${data.sliderNumber}`,
          url: urls[index],
          sliderNumber: data.sliderNumber,
          displayTime: data.displayTime,
          transitionTime: data.transitionTime,
          customer: data.customer,
          oneSliderPrice: data.oneSliderPrice,
          totalBudget: data.totalBudget,
          driverBudget: data.driverBudget,
          userBudget: data.userBudget,
          sliderClickCount: 0,

          qrBannerVisibility: openBannerLinkInput,
          qrBanner: openBannerLinkInput ? data.qrBanner : "",
          coords:
            videoCoordinates && videoCoordinates.length > 0
              ? videoCoordinates
              : null,
        }));
        console.log("updatedSlidersData =", [...slidersData, ...newSliders]);
        const updatedSlidersData = [...slidersData, ...newSliders];

        updateDoc(doc(db, "tabletconfig", "sliderConfig"), {
          sliders: updatedSlidersData,
        })
          .then(() => {
            reset();
            setSliderFiles([]);
            setSnackShow(true);
          })
          .catch((e) => {
            console.log(e);
          });
      });

    setAddLoading(false);
  };

  // Fonksiyon, verilen klasördeki mevcut fotoğrafı siler
  const deleteCurrentPhoto = async (folder, currentPhoto) => {
    try {
      const photoRef = ref(store, `${folder}/${currentPhoto}`);

      await deleteObject(photoRef);

      console.log(`Mevcut fotoğraf silindi: ${currentPhoto}`);

      setOpenModal(false);
      setModalText("");
    } catch (error) {
      console.error(`Mevcut fotoğrafı silerken bir hata oluştu: ${error}`);
    }
  };

  const handleDeleteSlider = async (id, imageName) => {
    const updatedSlidersData = slidersData.filter((slider) => slider.id !== id);
    try {
      setDeleteLoading(true);
      await updateDoc(doc(db, "tabletconfig", "sliderConfig"), {
        sliders: updatedSlidersData,
      })
        .then(() => {
          setSnackShow(true);
        })
        .catch((error) => {
          console.log("Error deleting slider:", error);
        });

      await deleteCurrentPhoto("tabletSlider", imageName);
      setDeleteLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelected = (value) => {
    setCustomersName(value.target.value);
    var cc = customers.find((e) => e.name === value.target.value);
    setCustomersIcon(cc?.pp);
  };

  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className="card">
          <h2>Add Slider</h2>
          <div className="">
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <div className="file-previews">
              {sliderFiles.map((file, index) => (
                <div key={index} className="file-preview">
                  <img src={file.preview} alt={`Slider ${index + 1}`} />
                </div>
              ))}
            </div>

            <div className="form-field">
              <label>Slider Number:</label>
              <div className="full-width">
                <input
                  type="number"
                  className="form-input"
                  {...register("sliderNumber", {
                    required: true,
                  })}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = value;
                  }}
                />
                {formState.errors.sliderNumber && <div>Zorunlu alan</div>}
              </div>
            </div>

            <div className="form-field">
              <label>Display Time (in seconds):</label>
              <div className="full-width">
                <input
                  type="text"
                  className="form-input"
                  placeholder="00:05"
                  {...register("displayTime", {
                    required: true,
                    minLength: 5,
                    maxLength: 5,
                  })}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = normalized(value);
                  }}
                />
                {formState.errors.displayTime && <div>Zorunlu alan</div>}
              </div>
            </div>

            <div className="form-field">
              <label>Transition Time to Slider Page (in seconds):</label>
              <div className="full-width">
                <input
                  type="text"
                  className="form-input"
                  placeholder="00:05"
                  {...register("transitionTime", {
                    required: true,
                    minLength: 5,
                    maxLength: 5,
                  })}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = normalized(value);
                  }}
                />
                {formState.errors.transitionTime && <div>Zorunlu alan</div>}
              </div>
            </div>

            <div className="form-field">
              <label>Reklam veren Sirket </label>

              <div id="customers-selected">
                <select
                  {...register("customer", { required: true })}
                  onChange={onChangeSelected}
                >
                  <option></option>
                  {customers &&
                    customers.map((e, i) => <option key={i}>{e?.name}</option>)}
                </select>
                {formState.errors.customer && (
                  <span>Zorunlu alan</span>
                )}
              </div>
            </div>

            <div className="form-field">
              <label>Reklam veren Icon</label>
              {customersIcon && (
                <img
                  src={customersIcon}
                  className="obj-fit"
                  alt="images-logo"
                  width="100%"
                  height="100px"
                />
              )}
            </div>
            <div className="form-field">
              <label>Bir video Ücreti</label>
              <div className="dollarGroup">
                <div className="full-width">
                  <div className="ds-flex">
                    <span className="dollars">$</span>
                    <input
                      type="number"
                      step="0.001"
                      className="form-input"
                      {...register("oneSliderPrice", { required: true })}
                    />
                  </div>
                  {formState.errors.totalBudget && <div>Zorunlu alan</div>}
                </div>
              </div>
            </div>

            <div className="form-field">
              <label>Reklam toplam bütce</label>
              <div className="dollarGroup">
                <div className="full-width">
                  <div className="ds-flex">
                    <span className="dollars">$</span>
                    <input
                      type="number"
                      step="0.001"
                      className="form-input"
                      {...register("totalBudget", { required: true })}
                    />
                  </div>
                  {formState.errors.totalBudget && <div>Zorunlu alan</div>}
                </div>
              </div>
            </div>

            <div className="form-field">
              <label>Şöför Alacağı Tutar</label>
              <div className="dollarGroup">
                <div className="full-width">
                  <div className="ds-flex">
                    <span className="dollars">P</span>
                    <input
                      type="number"
                      step="0.001"
                      className="form-input"
                      {...register("driverBudget", { required: true })}
                    />
                  </div>
                  {formState.errors.driverBudget && <div>Zorunlu alan</div>}
                </div>
              </div>
            </div>

            <div className="form-field">
              <label>Kullanıcı Alacağı Tutar</label>
              <div className="dollarGroup">
                <div className="full-width">
                  <div className="ds-flex">
                    <span className="dollars">P</span>
                    <input
                      type="number"
                      step="0.001"
                      className="form-input"
                      {...register("userBudget", { required: true })}
                    />
                  </div>
                  {formState.errors.userBudget && <div>Zorunlu alan</div>}
                </div>
              </div>
            </div>

            <div className="form-field">
              <label htmlFor="toggleButtonBannerLink">Add QR Banner Link</label>
              <div className="full-width">
                <label htmlFor="toggleButtonBannerLink">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      id="toggleButtonBannerLink"
                      className="form-input"
                      onChange={() =>
                        setOpenBannerLinkInput(!openBannerLinkInput)
                      }
                    />
                    <span id="toggleButtonBannerLink" className="slider"></span>
                  </div>
                </label>
              </div>
            </div>
            {openBannerLinkInput && (
              <div className="form-field">
                <label>Slider QR Banner Link:</label>
                <div className="full-width">
                  <input
                    type="text"
                    className="form-input"
                    {...register("qrBanner", {
                      required: true,
                    })}
                    onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = value;
                    }}
                  />
                  {formState.errors.qrBanner && <div>Zorunlu alan</div>}
                </div>
              </div>
            )}

            <div className="form-field">
              <label htmlFor="toggleButton">Add Location</label>
              <div className="full-width">
                <label htmlFor="toggleButton">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      id="toggleButton"
                      className="form-input"
                      onChange={() => setOpenCoordModal(!openCoordModal)}
                    />
                    <span id="toggleButton" className="slider"></span>
                  </div>
                </label>
              </div>
            </div>

            {openCoordModal && (
              <SelectLocation
                videoCoordinates={videoCoordinates}
                setVideoCoordinates={setVideoCoordinates}
              />
            )}

            {formState.defaultValues.coordStatus && (
              <input
                className="denemes"
                type="button"
                value={addLoading ? "Loading..." : "Add Coords"}
              />
            )}

            <input
              className="denemes"
              type="submit"
              value={addLoading ? "Loading..." : "Add"}
            />
          </div>
        </form>

        <div className="card sliders-contanier">
          <h2>Sliders</h2>
          {/* Veritabanından gelen slider verilerini kartlar halinde gösteriyoruz */}
          <div className="sliders-main">
            {slidersData.map((slider) => (
              <div key={slider.id} className="slider-card">
                <div className="card-image">
                  <img src={slider.url} alt={`Slider ${slider.imageName}`} />
                </div>

                <div className="card-footer">
                  <div className="info">
                    <h3>{slider.imageTitle}</h3>
                    <p>
                      <small>Display Time : {slider.displayTime}</small>
                    </p>
                    <p>
                      <small>Transition Time : {slider.transitionTime}</small>
                    </p>
                  </div>

                  <div className="button-group">
                    <button
                      className="delete-button"
                      onClick={() => {
                        setModalText(
                          <DeleteMain
                            text={`${slider.imageTitle} isimli sliderı silmek istediğinizden emin misiniz?`}
                            cancelBtnClick={() => setOpenModal(false)}
                            deleteBtnClick={() =>
                              handleDeleteSlider(slider.id, slider.imageName)
                            }
                          />
                        );
                        setOpenModal(true);
                      }}
                    >
                      Sil
                    </button>
                    <Link to={`/evgrs/tbtslider-detail/${slider.id}`}>
                      <button className="update-button">Güncelle</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {openModal && (
        <Modal
          children={modalText}
          close={() => {
            setOpenModal(false);
            setModalText("");
          }}
        />
      )}

      {(addLoading || deleteLoading) && <FullPageLoading />}

      <Snackbar
        open={snackShow}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Slider upload Success
        </Alert>
      </Snackbar>
    </div>
  );
};
