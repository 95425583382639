import React, { useEffect, useState } from "react";
import StatusCard from "../components/status-card/StatusCard";
// import Chard from 'react-apexcharts'
// import { Link } from 'react-router-dom'
// import Table from '../components/table/Table'
// import Badge from '../components/badge/Badge'
// import { useSelector } from 'react-redux'
// import { authState } from '../redux/features/AuthSlice'
// import { videosState } from '../redux/features/videoSlice'
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../base";
import { Link } from "react-router-dom";

// const renderCustomer = (item, index) => (
//     <th key={index}>
//         {item}</th>
// );

// const renderBody = (item, index) => (
//     <tr key={index}>
//         <td>
//             {item.TezgahKodu}
//         </td>
//         <td>
//             {item.DurusAdi}
//         </td>
//         <td>
//             {item.DurusSuresi}
//         </td>
//     </tr>
// );

// const orderStatus = {
//     'DURUS': 'primary',
//     'PROJE_DENEME': 'waring',
//     'URETIM': 'success',
//     'KAPALI': 'danger'
// }

// const renderOrderHead = (item, index) => (
//     <th key={index}>
//         {item}
//     </th>
// )
// const renderOrderBody = (item, index) => (
//     <tr key={index}>
//         <td>{item.TezgahKodu}</td>
//         <td>{item.IsNo}</td>
//         <td>{item.Kalite}</td>
//         <td>{item.IskartaAdetIs}</td>
//         <td>
//             <Badge
//                 type={orderStatus[item.Durum]}
//                 data={item.Durum}
//             />

//         </td>

//     </tr>
// )
export const Dashboard = () => {
  // const auth = useSelector(authState)
  // const videos = useSelector(videosState)
  const [usersCount, setUsersCount] = useState();
  const [driversCount, setDriversCount] = useState();
  const [videoCount, setVideoCount] = useState();
  const [productCount, setProductCount] = useState();
  const [productSold, setProductSold] = useState();

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "users"), (snapshot) => {
      setUsersCount(snapshot.docs.length);
    });
    return unsub;
  }, []);
  useEffect(() => {
    const unsub = onSnapshot(collection(db, "drivers"), (snapshot) => {
      setDriversCount(snapshot.docs.length);
    });
    return unsub;
  }, []);
  useEffect(() => {
    const unsub = onSnapshot(collection(db, "videoAds"), (snapshot) => {
      setVideoCount(snapshot.docs.length);
    });
    return unsub;
  }, []);
  useEffect(() => {
    const unsub = onSnapshot(collection(db, "products"), (snapshot) => {
      setProductCount(snapshot.docs.length);
    });
    return unsub;
  }, []);
  
  useEffect(() => {
    const unsub = onSnapshot(collection(db, "productSold"), (snapshot) => {
      const productSoldAll = snapshot.docs.map((e) => {
        let a = e.data();
        a["docId"] = e.id;
        return a;
      });
      const productSoldStatusFalse = productSoldAll.filter(
        (product) => product.state === false
      );
      // console.log('productSold geldi : ',productSoldStatusFalse)

      setProductSold(productSoldStatusFalse.length);
    });
    return unsub;
  }, []);

  return (
    <div>
      <h2 className="page-header">DashBorad</h2>

      {
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="row">
              <div className="col-6 col-sm-12">
                <Link to={"/evgrs/users"}>
                  <StatusCard
                    icon={"bx bx-user"}
                    color="green"
                    count={usersCount ?? 0}
                    title={"User"}
                  />
                </Link>
              </div>
              <div className="col-6 col-sm-12">
                <Link to={"/evgrs/drivers"}>
                  <StatusCard
                    icon={"bx bxs-user-badge"}
                    color="FireBrick"
                    count={driversCount ?? 0}
                    title={"Drivers"}
                  />
                </Link>
              </div>
              <div className="col-6 col-sm-12">
                <Link to={"/evgrs/videos"}>
                  <StatusCard
                    icon={"bx bx-video-recording"}
                    color="red"
                    count={videoCount ?? 0}
                    title={"Video"}
                  />
                </Link>
              </div>
              <div className="col-6 col-sm-12">
                <Link to={"/evgrs/productList"}>
                  <StatusCard
                    icon={"bx bx-cart-alt"}
                    color="orange"
                    count={productCount ?? 0}
                    title={"Products"}
                  />
                </Link>
              </div>
              <div className="col-6 col-sm-12">
                <Link to={"/evgrs/order"}>
                  <StatusCard
                    icon={"bx bxs-cart-download"}
                    color="orange"
                    count={productSold ?? 0}
                    title={"Purchases Pending Approval"}
                  />
                </Link>
              </div>
            </div>
          </div>
          {/* <div className='col-6 col-md-12 '>
                    <div className="card full-height card-height">
                        {
                           raporData && <Chard
                                options={
                                    {
                                        color: ['#6ab04c', '#2980b9'],
                                        chart: {
                                            background: 'transparent'
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        stoke: {
                                            curve: 'smooth'
                                        },
                                        xaxis: {
                                            categories: raporData.tarih
                                        },
                                        legend: {
                                            floating: false,
                                            position: 'bottom',
                                            width:"50px",
                                            height:"18"
                                        },

                                        gird: {
                                            show: false
                                        },

                                        responsive: [
                                            {
                                              breakpoint: '800px',
                                              options: {
                                                chart: {
                                                    height: '500px'
                                                },
                                                
                                                legend: {
                                                    show:false,
                                                    floating: false,
                                                    position: "bottom",
                                                    height:"100px",
                                                    horizontalAlign:"center",
                                                    
                                                },
                                              }
                                            }
                                          ]
                                    }


                                }
                                series={
                                    Object.keys(raporData.datam).map((e) => {
                                        return {
                                            name: e.toLocaleUpperCase(),
                                            data: raporData.datam[e] ?? [0, 0, 0, 0, 0, 0]
                                        }
                                    })
                                   

                                }
                                type='line'
                                height='100%'
                            />
                        }
                    </div>
                </div> */}
          {/* <div className='col-4 col-md-12'>
                    <div className="card">
                        <div className="card__header">
                            <h3>Duruşlar</h3>
                        </div>
                        <div className="card__body">
                            {
                                <Table
                                    headData={topCustomers.head}
                                    bodyData={data.states[1].slice(0, Number(4))}
                                    renderHead={(item, index) => renderCustomer(item, index)}
                                    renderBody={(item, index) => renderBody(item, index)}
                                    
                                />
                            }
                        </div>
                        <div className="card__footer">
                            <Link to='/customers'>View All</Link>
                        </div>

                    </div>
                </div> */}
          {/* <div className="col-8 col-md-12">
                    <div className="card">
                        <div className="card__header">
                            <h3>Aktif Üretim</h3>
                        </div>
                        <div className='card__body'>
                            <Table
                                headData={lastestOrders.header}
                                bodyData={data.salddata.slice(0, Number(4)) }
                                renderHead={(item, index) => renderOrderHead(item, index)}
                                renderBody={(item, index) => renderOrderBody(item, index)}
                            />
                        </div>
                        <div className='card__footer'>
                            <Link to='/'>View all</Link>
                        </div>
                    </div>
                </div> */}
        </div>
      }
      {/* <FullPageLoading /> */}
    </div>
  );
};
