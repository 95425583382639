import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { store, db } from '../base';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {  doc, setDoc } from "firebase/firestore";

export const Product = () => {

    const { register, handleSubmit, reset, formState } = useForm({});

    const onSubmit = async (data) => {

        let s = Date.now().toString()
        let imagePromise = []

        const storageRef1 = ref(store, `product/${s}/banner`);
        let banner = uploadBytes(storageRef1, bannerFile)
        imagePromise.push(banner)

        const storageRef2 = ref(store, `product/${s}/icon`);
        let icons = uploadBytes(storageRef2, iconFile)
        imagePromise.push(icons)

        const storageRef3 = ref(store, `product/${s}/slider1`);
        let sli1 = uploadBytes(storageRef3, slader1File)
        imagePromise.push(sli1)

        const storageRef4 = ref(store, `product/${s}/slader2`);
        let sli2 = uploadBytes(storageRef4, slader2File)
        imagePromise.push(sli2)

        const storageRef5 = ref(store, `product/${s}/slader3`);
        let sli3 = uploadBytes(storageRef5, slader3File)
        imagePromise.push(sli3)

        Promise.all(imagePromise).then((values) => {
            let dowloadnurl = values.map((e => getDownloadURL(e.ref)))
            Promise.all(dowloadnurl).then((res)=>{
                setDoc(doc(db, 'products', s), {
                    banner:res[0],
                    createdBy:'s',
                    createdDate:s,
                    description:data.descriotion,
                    icon:res[1],
                    images:[
                        {image:res[2]},
                        {image:res[3]},
                        {image:res[4]}
                    ],
                    price:parseInt(data.price),
                    quantity:parseInt(data.quantity),
                    title:data.title,
                    uid:s.toString(),
                    updatedBy:'s',
                    updatedDate:s  
                }).then(() => {
                    reset({  });
                })

            })
          

        })
        
    };

    // const [imgUrl, setimgUrl] = useState()

    const [bannerFile, setBanner] = useState()
    const [iconFile, setIcon] = useState()
    const [slader1File, setSlader1] = useState()
    const [slader2File, setSlader2] = useState()
    const [slader3File, setSlader3] = useState()

    const uploadVideo = (value,filetype) => {
        if (value.target.files.length !== 0) {
            if(filetype === 'banner'){
                setBanner(value.target.files[0])
            }
            if(filetype === 'icon'){
                setIcon(value.target.files[0])
            }
            if(filetype === 'sd1'){
                setSlader1(value.target.files[0])
            }
            if(filetype === 'sd2'){
                setSlader2(value.target.files[0])
            }
            if(filetype === 'sd3'){
                setSlader3(value.target.files[0])
            }
         
        } else {
        }
    }

    return (
        <div>
            <div className="row">
                <div className="card full-width ds-flex">
                    <div className="col-8 ">
                        <div className="formuseradd">
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div>
                                    <div className='formlabel'>Title : </div>
                                    <input className='frominput'  {...register("title", { required: true, minLength: 8 })} ></input>
                                    {formState.errors.email && <span>This field is required</span>}
                                </div>

                                <div className="form-field">
                                        <label >Descriotion</label>
                                        <div className='full-width'>
                                            <textarea className='textarea' {...register("descriotion", { required: true })}></textarea>
                                            {formState.errors.descriotionEn && <div>Zorunlu alan</div>}
                                        </div>
                                    </div>


                                <div className="form-field">
                                    <label >Price</label>
                                    <div className='dollarGroup'>
                                        <div className='full-width'>
                                            <div className='ds-flex'>
                                                <span className='dollars'>$</span>
                                                <input type="number" className='form-input' {...register("price", { required: true })} />
                                            </div>
                                            {formState.errors.totalBudget && <div>Zorunlu alan</div>}
                                        </div>
                                    </div>
                                </div>


                                <div className="form-field">
                                    <label >Quantity</label>
                                    <div className='dollarGroup'>
                                        <div className='full-width'>
                                            <div className='ds-flex'>
                                                <input type="number" className='form-input' {...register("quantity", { required: true })} />
                                            </div>
                                            {formState.errors.totalBudget && <div>Zorunlu alan</div>}
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='formlabel'> banner:</div>
                                    <div>
                                        <input type="file" onChange={(e) => { uploadVideo(e, 'banner') }} accept="image/png, image/jpeg" />
                                    </div>
                                </div>

                                <div>
                                    <div className='formlabel'> İcon:</div>
                                    <div>
                                        <input type="file" onChange={(e) => { uploadVideo(e, 'icon') }} accept="image/png, image/jpeg" />
                                    </div>
                                </div>

                                <div>
                                    <div className='formlabel'> Slader 1:</div>
                                    <div>
                                        <input type="file" onChange={(e) => { uploadVideo(e, 'sd1') }} accept="image/png, image/jpeg" />
                                    </div>
                                </div>

                                <div>
                                    <div className='formlabel'> Slade 2:</div>
                                    <div>
                                        <input type="file" onChange={(e) => { uploadVideo(e, 'sd2') }} accept="image/png, image/jpeg" />
                                    </div>
                                </div>

                                <div>
                                    <div className='formlabel'> Slader 3:</div>
                                    <div>
                                        <input type="file" onChange={(e) => { uploadVideo(e, 'sd3') }} accept="image/png, image/jpeg" />
                                    </div>
                                </div>

                                <input className='denemes' type="submit" />

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
