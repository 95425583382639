import './sidebar.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import sidebar_items from '../../assets/JsonData/sidebar_routes.json';
import { useSelector } from "react-redux";
import { authState } from "../../redux/features/AuthSlice";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from '../../base';

const SideBarItem = ({ icon, title, active, onClick }) => {
    return (
        <div className={`sidebar_item ${active ? 'active' : ''}`} onClick={onClick}>
            <div className="sidebar__item-inner">
                <i className={icon}></i>
                <span>{title}</span>
            </div>
        </div>
    );
};

const Sidebar = props => {
    const user = useSelector(authState);
    const [activeMessageLength, setActiveMessageLength] = useState(null);
    const [currentUser, setCurrentUser] = useState({
        display_name: "Welcome Admin",
        role: "user_role",
        name: "fullName"
    });
    const [expandedMenus, setExpandedMenus] = useState({});

    useEffect(() => {
        if (user && user.user) {
            setCurrentUser({
                display_name: user.user.email,
                role: user.user.role,
                name: user.user.fullName,
            });
        }
    }, [user]);

    useEffect(() => {
        const unsub = onSnapshot(collection(db, "/helpCenter"), (snapshot) => {
            const notifications = snapshot.docs.map(doc => doc.data());
            const activeNotifications = notifications.filter(notify => !notify.isRead);
            setActiveMessageLength(activeNotifications.length);
        });
        return () => unsub();
    }, []);

    const activeItem = sidebar_items.findIndex(item => item.route === props.location.pathname);

    const toggleMenu = (index) => {
        setExpandedMenus(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const renderItem = (item, index) => {
        const isExpanded = expandedMenus[index];

        return (
            <React.Fragment key={index}>
                <SideBarItem
                    icon={item.icon}
                    active={index === activeItem}
                    title={item.display_name}
                    onClick={() => item.submenu && toggleMenu(index)}
                />
                {item.submenu && isExpanded && (
                    <div className="sidebar_submenu">
                        {item.submenu.map((sub, subIndex) => (
                            <Link to={sub.route} key={`sub-${subIndex}`}>
                                <SideBarItem
                                    icon={sub.icon}
                                    active={subIndex === activeItem}
                                    title={sub.display_name}
                                />
                            </Link>
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <div ref={props.refSidebar} className="sidebar">
            <div className='sidebar__logo'>
                <img src={logo} alt="logo" />
            </div>
            {sidebar_items.map((item, index) => (
                (item.role === "all" || item.role === currentUser.role) && renderItem(item, index)
            ))}
            <Link to={`/evgrs/notifications`}>
                <div className={`sidebar_item ${"/evgrs/notifications" === props.location.pathname ? 'active' : ''}`}>
                    <div className="sidebar__item-inner">
                        <i className="bx bx-bell">
                            {activeMessageLength > 0 && (
                                <span className='sidebar-link-badge'>{activeMessageLength}</span>
                            )}
                        </i>
                        <span>Destek Talep</span>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Sidebar;
