import { createSlice } from '@reduxjs/toolkit'

export const videoSlice = createSlice({
    name:'videos',
    initialState:{},
    reducers:{
        setVideo : (state,action) => {
            state.videos = action.payload.reduce((arr,crr)=>{
                arr[crr.videoId] = crr
                return arr
            },{})
        },        
    }

})

export const {setVideo} = videoSlice.actions
export const videosState = state => state.videos
export default videoSlice.reducer