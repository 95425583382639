import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db, store } from "../base";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FullPageLoading from "../components/fullpageloading/FullPageLoading";
import SelectLocation from "../components/SeletLocation/SelectLocation";

function formatBudget(budget) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(budget);
}

export const TbtSliderDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const [newPhoto, setNewPhoto] = useState(null);
  const [newPhotoPreview, setNewPhotoPreview] = useState(null);

  const [allSliderData, setAllSliderData] = useState(null);
  const [sliderData, setSliderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sliderProps, setSliderProps] = useState({
    sliderNumber: "",
    displayTime: "",
    transitionTime: "",
    customer: "",
    oneSliderPrice: "",
    totalBudget: "",
    driverBudget: "",
    userBudget: "",
    sliderClickCount: 0,
  });

  const [openCoordModal, setOpenCoordModal] = useState(false);
  const [sliderCoords, setSliderCoords] = useState([]);
  const [openBannerLinkInput, setOpenBannerLinkInput] = useState(false);

  const [customers, setCustomers] = useState("");
  const [customersIcon, setCustomersIcon] = useState("");

  const [updateLoading, setUpdateLoading] = useState(false);

  const [snackShow, setSnackShow] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
  };

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "customers"), (snapshot) => {
      let admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setCustomers(admins);
    });
    return unsub;
  }, []);

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const docRef = doc(db, "tabletconfig", "sliderConfig");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const slidersData = docSnap.data().sliders || [];
          const slider = slidersData.find((slider) => slider.id === id);
          setAllSliderData(slidersData);

          if (slider) {
            setSliderData(slider);

            setSliderProps({
              sliderNumber: slider.sliderNumber ? slider.sliderNumber : "",
              displayTime: slider.displayTime ? slider.displayTime : "",
              transitionTime: slider.transitionTime
                ? slider.transitionTime
                : "",
              customer: slider.customer ? slider.customer : "",
              oneSliderPrice: slider.oneSliderPrice ? slider.oneSliderPrice : "",
              totalBudget: slider.totalBudget ? slider.totalBudget : "",
              driverBudget: slider.driverBudget ? slider.driverBudget : "",
              userBudget: slider.userBudget ? slider.userBudget : "",
              sliderClickCount: slider.sliderClickCount
                ? slider.sliderClickCount
                : 0,
              qrBannerVisibility: slider.qrBannerVisibility
                ? slider.qrBannerVisibility
                : "",
              qrBanner: slider.qrBanner ? slider.qrBanner : "",
            });

            if (slider.coords) {
              setSliderCoords(slider.coords);
              setOpenCoordModal(true);
            }

            if (slider.qrBannerVisibility) {
              setOpenBannerLinkInput(true);
            }
          }
        } else {
          console.log("No such document!");
        }
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching slider data:", error);
        setIsLoading(false);
      }
    };

    fetchSliderData();
  }, [id]);

  useEffect(() => {
    if (sliderProps && sliderProps.customer) {
      console.log(sliderProps.customer);
      var cc = customers.find((e) => e.name === sliderProps.customer);
      setCustomersIcon(cc?.pp);
    }
  }, [sliderProps]);

  const normalized = (value) => {
    return (
      value
        .match(/\d{1,2}/g)
        ?.join(":")
        .substr(0, 5) ?? ""
    );
  };

  const onChangeSelected = (value) => {
    setSliderProps((prevProps) => ({
      ...prevProps,
      [value.target.name]: value.target.value,
    }));
    var cc = customers.find((e) => e.name === value.target.value);
    setCustomersIcon(cc?.pp);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "displayTime" || name === "transitionTime") {
      setSliderProps((prevProps) => ({
        ...prevProps,
        [name]: normalized(value), // Gelen değeri integer'a dönüştürüyoruz.
      }));
    } else {
      setSliderProps((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };

  const handleNewPhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setNewPhoto(selectedFile);
    setNewPhotoPreview(URL.createObjectURL(selectedFile));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!sliderData) return;

    try {
      setUpdateLoading(true);
      if (newPhoto) {
        // Step 1: Upload the new photo and get its URL
        const storageRef = ref(store, `/tabletSlider/${newPhoto.name}`);
        await uploadBytes(storageRef, newPhoto);
        const newImageUrl = await getDownloadURL(storageRef);

        // Step 2: Delete the old photo
        const photoRef = ref(store, `/tabletSlider/${sliderData.imageName}`);

        const photoSnapshot = await getDownloadURL(photoRef).catch((error) => {
          if (error.code === "storage/object-not-found") {
            console.log("Photo does not exist. No deletion needed.");
            return null;
          }
          throw error;
        });

        if (photoSnapshot !== null) {
          // Photo exists, so we can proceed with deletion
          await deleteObject(photoRef);
          console.log("Photo deleted successfully.");
        } else {
          console.log("Photo does not exist. No deletion needed.");
        }

        // Step 3: Update the slider data with the new photo URL and other properties
        const updatedSlidersData = allSliderData.map((slider) =>
          slider.id === id
            ? {
                ...slider,
                ...sliderProps,
                url: newImageUrl,
                imageTitle: `Slider ${sliderProps.sliderNumber}`,
                imageName: newPhoto.name,
                qrBannerVisibility: openBannerLinkInput,
                qrBanner: openBannerLinkInput ? sliderProps.qrBanner : "",
                coords:
                  sliderCoords && sliderCoords.length > 0 ? sliderCoords : null,
              }
            : slider
        );
        console.log(updatedSlidersData);

        // Step 4: Save the updated slider data to Firebase Firestore
        await updateDoc(doc(db, "tabletconfig", "sliderConfig"), {
          sliders: updatedSlidersData,
        });

        // Redirect or show a success message here.
      } else {
        // If no new photo is selected, update the slider data without changing the URL
        const updatedSlidersData = allSliderData.map((slider) =>
          slider.id === id
            ? {
                ...slider,
                ...sliderProps,
                imageTitle: `Slider ${sliderProps.sliderNumber}`,
                qrBannerVisibility: openBannerLinkInput,
                qrBanner: openBannerLinkInput ? sliderProps.qrBanner : "",
                coords:
                  sliderCoords && sliderCoords.length > 0 ? sliderCoords : null,
              }
            : slider
        );
        console.log("updatedSlidersData  : ", updatedSlidersData);
        await updateDoc(doc(db, "tabletconfig", "sliderConfig"), {
          sliders: updatedSlidersData,
        });

        // Redirect or show a success message here.
      }
      setUpdateLoading(false);

      history.push("/evgrs/tbtslider");
    } catch (error) {
      console.log("Error updating slider data:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!sliderData) {
    return <div>Slider not found.</div>;
  }

  return (
    <div className="card ">
      <h2 className="title">Edit Slider - {sliderData.imageTitle}</h2>
      <form onSubmit={handleSubmit}>
        <div className="slider-image-container">
          {/* Display the current photo */}
          <div className="current-img">
            <h3>Current Photo:</h3>
            <div className="image-box">
              <img
                src={sliderData.url}
                alt={`Slider ${sliderData.imageName}`}
              />
            </div>
          </div>
          {/* Display the new photo preview */}
          {newPhotoPreview && (
            <div className="new-img">
              <h3>New Photo Preview:</h3>
              <div className="image-box">
                <img src={newPhotoPreview} alt="New Slider" />
              </div>
            </div>
          )}
        </div>
        <div className="input-box">
          {/* Input for uploading a new photo */}
          <label>Upload New Photo:</label>
          <input type="file" onChange={handleNewPhotoChange} />
        </div>
        <div className="input-box">
          <label>Slider Number:</label>
          <input
            type="number"
            name="sliderNumber"
            value={sliderProps.sliderNumber}
            onChange={handleChange}
          />
        </div>
        <div className="input-box">
          <label>Display Time (in seconds):</label>
          <input
            type="text"
            name="displayTime"
            value={sliderProps.displayTime}
            onChange={handleChange}
          />
        </div>
        <div className="input-box">
          <label>Transition Time to Slider Page (in seconds):</label>
          <input
            type="text"
            name="transitionTime"
            value={sliderProps.transitionTime}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div className="input-box">
          <label>Reklam veren Sirket </label>

          <div id="customers-selected">
            <select
              name="customer"
              value={sliderProps.customer}
              onChange={onChangeSelected}
            >
              <option></option>
              {customers &&
                customers.map((e, i) => <option key={i}>{e?.name}</option>)}
            </select>
          </div>
        </div>

        <div className="input-box">
          <label>Reklam veren Icon</label>
          {customersIcon && (
            <img
              src={customersIcon}
              className="obj-fit"
              alt="images-logo"
              width="100px"
              height="100px"
            />
          )}
        </div>
        <div className="input-box">
          <label>Bir video Ücreti</label>
          <div className="dollarGroup">
            <div className="full-width">
              <div className="ds-flex">
                <span className="dollars">$</span>
                <input
                  type="number"
                  step="0.001"
                  className="form-input"
                  name="oneSliderPrice"
                  onChange={handleChange}
                  value={sliderProps.oneSliderPrice}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-box">
          <label>Reklam toplam bütce</label>
          <div className="dollarGroup">
            <div className="full-width">
              <div className="ds-flex">
                <span className="dollars">$</span>
                <input
                  type="number"
                  step="0.001"
                  className="form-input"
                  name="totalBudget"
                  onChange={handleChange}
                  value={sliderProps.totalBudget}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-box">
          <label>Şöför Alacağı Tutar</label>
          <div className="dollarGroup">
            <div className="full-width">
              <div className="ds-flex">
                <span className="dollars">P</span>
                <input
                  type="number"
                  step="0.001"
                  className="form-input"
                  name="driverBudget"
                  onChange={handleChange}
                  value={sliderProps.driverBudget}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-box">
          <label>Kullanıcı Alacağı Tutar</label>
          <div className="dollarGroup">
            <div className="full-width">
              <div className="ds-flex">
                <span className="dollars">P</span>
                <input
                  type="number"
                  step="0.001"
                  className="form-input"
                  name="userBudget"
                  onChange={handleChange}
                  value={sliderProps.userBudget}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-field">
          <label>Watched Count</label>
          <div className="dollarGroup">
            <div className="full-width">
              {sliderProps.sliderClickCount ? sliderProps.sliderClickCount : 0}
            </div>
          </div>
        </div>

        <div className="form-field">
          <label>Given Budget</label>
          <div className="dollarGroup">
            <div className="full-width">
              {Number(sliderProps.sliderClickCount) > 0
                ? formatBudget(
                    parseFloat(sliderProps.oneSliderPrice) *
                      Number(sliderProps.sliderClickCount)
                  )
                : 0}
            </div>
          </div>
        </div>

        <div className="form-field">
          <label>Remaining Budget</label>
          <div className="dollarGroup">
            <div className="full-width">
              {Number(sliderProps.sliderClickCount) > 0
                ? formatBudget(
                    Number(sliderProps.totalBudget) -
                      parseFloat(sliderProps.oneSliderPrice) *
                        Number(sliderProps.sliderClickCount)
                  )
                : 0}
            </div>
          </div>
        </div>

        <div className="input-box">
          <label htmlFor="toggleButtonBannerLink">Add QR Banner Link</label>
          <div className="full-width">
            <label htmlFor="toggleButtonBannerLink">
              <div className="toggle">
                <input
                  type="checkbox"
                  id="toggleButtonBannerLink"
                  className="form-input"
                  checked={openBannerLinkInput}
                  onChange={() => setOpenBannerLinkInput(!openBannerLinkInput)}
                />
                <span id="toggleButtonBannerLink" className="slider"></span>
              </div>
            </label>
          </div>
        </div>

        {openBannerLinkInput && (
          <div className="input-box">
            <label>Slider QR Banner Link:</label>
            <div className="full-width">
              <input
                name="qrBanner"
                type="text"
                className="form-input"
                value={sliderProps.qrBanner}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        <div className="input-box">
          <label htmlFor="toggleButton">Add Location : </label>
          <div className="full-width">
            <label htmlFor="toggleButton">
              <div className="toggle">
                <input
                  type="checkbox"
                  id="toggleButton"
                  className="form-input"
                  checked={openCoordModal}
                  onChange={() => setOpenCoordModal(!openCoordModal)}
                />
                <span id="toggleButton" className="slider"></span>
              </div>
            </label>
          </div>
        </div>

        {openCoordModal && (
          <SelectLocation
            setVideoCoordinates={setSliderCoords}
            videoCoordinates={sliderCoords}
            sliderData={sliderData}
          />
        )}

        <button type="submit" className="update-btn">
          Update
        </button>
      </form>

      {updateLoading && <FullPageLoading />}

      <Snackbar
        open={snackShow}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Slider upload Success
        </Alert>
      </Snackbar>
    </div>
  );
};
