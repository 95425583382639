import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { store } from "../base";
import { db } from "../base";
import { collection, onSnapshot, doc, setDoc } from "firebase/firestore";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import SelectLocation from "../components/SeletLocation/SelectLocation";

export const VideouUpload = () => {
  const { register, handleSubmit, reset, formState, watch } = useForm({
    defaultValues: {
      videoLangueEn: true,
    },
  });

  const [barVaL, setbarVaL] = useState();
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();

  const [fileEn, setFileEn] = useState("");
  const [fileTr, setFileTr] = useState("");
  const [fileRu, setFileRu] = useState("");

  const [adsVideo, setAdsVideoUrl] = useState("");
  const [adsVideoTr, setAdsVideoTrUrl] = useState("");
  const [adsVideoEn, setAdsVideoEnUrl] = useState("");
  const [adsVideoRu, setAdsVideoRuUrl] = useState("");

  const [customers, setCustomers] = useState("");
  const [videoCatagory, setVideoCatagory] = useState("");
  const [videoBanner, setVideoBannerUrl] = useState("");
  const [videoInBanner, setVideoInBannerUrl] = useState("");
  const [customersName, setCustomersName] = useState("");
  const [customersIcon, setCustomersIcon] = useState("");
  const [snackShow, setSnackShow] = useState(false);

  const [langueTr, setLangueTr] = useState(false);
  const [langueEn, setLangueEn] = useState(true);
  const [langueUa, setLangueUa] = useState(false);
  const [langueRu, setLangueRu] = useState(false);

  const [openDiscountCouponModal, setOpenDiscountCouponModal] = useState(false);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const [discountCouponEndDate, setDiscountCouponEndDate] = useState("");

  const [openCoordModal, setOpenCoordModal] = useState(false);
  const [videoCoords, setVideoCoords] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "customers"), (snapshot) => {
      let admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setCustomers(admins);
    });
    return unsub;
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "categoryAds"), (snapshot) => {
      let admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setVideoCatagory(admins);
    });
    return unsub;
  }, []);

  const onSubmit = async (data) => {
    let s = Date.now().toString();
    let videosPromise = [];

    const storageRef5 = ref(store, `videoBanner/${s}`);
    let banner = uploadBytes(storageRef5, file2);
    videosPromise.push(banner);

    const storageRef6 = ref(store, `vbTabletAdsClick/${s}`);
    let bannerInVideo = uploadBytes(storageRef6, file3);
    videosPromise.push(bannerInVideo);

    if (data.videoLangueUa) {
      const storageRef = ref(store, `videoAds2/${s}`);
      let uaVideo = uploadBytes(storageRef, file);
      videosPromise.push(uaVideo);
    }
    if (data.videoLangueEn) {
      const storageRef2 = ref(store, `videoAds2/EnVideo/${s}`);
      let enVideo = uploadBytes(storageRef2, fileEn);
      videosPromise.push(enVideo);
    }
    if (data.videoLangueTr) {
      const storageRef4 = ref(store, `videoAds2/TrVideo/${s}`);
      let trVideo = uploadBytes(storageRef4, fileTr);
      videosPromise.push(trVideo);
    }
    if (data.videoLangueRu) {
      const storageRef3 = ref(store, `videoAds2/RuVideo/${s}`);
      let ruVideo = uploadBytes(storageRef3, fileRu);
      videosPromise.push(ruVideo);
    }

    setbarVaL(10);
    Promise.all(videosPromise).then((values) => {
      setbarVaL(70);
      let dowloadnurl = values.map((e) => getDownloadURL(e.ref));
      Promise.all(dowloadnurl).then((ress) => {
        setbarVaL(90);
        let urlList = ress.reduce((arr, cur) => {
          if (cur.includes("EnVideo")) {
            arr["EnUrl"] = cur;
          } else if (cur.includes("RuVideo")) {
            arr["RuVideo"] = cur;
          } else if (cur.includes("TrVideo")) {
            arr["TrVideo"] = cur;
          } else if (cur.includes("videoBanner")) {
            arr["videoBanner"] = cur;
          } else if (cur.includes("vbTabletAdsClick")) {
            arr["vbTabletAdsClick"] = cur;
          } else {
            arr["url"] = cur;
          }

          return arr;
        }, {});

        setDoc(doc(db, "videoAds", s), {
          // videoLangueUa: data.videoLangueUa,
          // videoLangueRu: data.videoLangueRu,

          coords: videoCoords && videoCoords.length > 0 ? videoCoords : null,
          discountCouponCode: discountCoupon ? discountCoupon : "",
          discountCouponEndDate: discountCouponEndDate
            ? discountCouponEndDate
            : "",

          videoLangueUa: false,
          videoLangueRu: false,
          videoLangueEn: data.videoLangueEn,
          videoLangueTr: data.videoLangueTr,
          companyIcon: urlList.videoBanner,
          companyName: data.CustomersSelected,
          description: langueUa ? data.descriotion : null,
          descriptionTr: langueTr ? data.descriotionTr : null,
          descriptionRu: langueRu ? data.descriotionRu : null,
          descriptionEn: langueEn ? data.descriotionEn : null,
          // driverBudget: parseFloat(data.driverBudget),
          driverBudget: parseInt(data.driverBudget),
          reviewList: [],
          category: data.videoCatagory,
          time: Date.now(),
          title: data.videoAdsTitle ?? null,
          titleTr: data.videoAdsTitleTr ?? null,
          titleRu: data.videoAdsTitleRu ?? null,
          titleEn: data.videoAdsTitleEn ?? null,
          // totalBudget: parseFloat(data.totalBudget),
          totalBudget: parseInt(data.totalBudget),
          totalWatched: 0,
          url: urlList.url ?? null,
          enUrl: urlList.EnUrl ?? null,
          ruUrl: urlList.RuVideo ?? null,
          trUrl: urlList.TrVideo ?? null,
          // userBudget: parseFloat(data.userBudget),
          userBudget: parseFloat(data.userBudget),
          userUid: "1",
          videoAdsBanner: urlList.videoBanner,
          vbTabletAdsClick: urlList.vbTabletAdsClick ?? null,
          videoId: s,
          videoTime: data.videoTime,
          // oneVideoPrice: parseFloat(data.oneVideoPrice),
          oneVideoPrice: data.oneVideoPrice,
          videoRate: data.videoRate,
          videoAdsBannerLink: data.videoAdsBannerLink,
          videoAdsBannerText: data.videoAdsBannerText,
        }).then(() => {
          reset({ videoLangueEn: true });
          setAdsVideoUrl();
          setVideoBannerUrl();
          setVideoInBannerUrl();
          setbarVaL();
          setCustomersIcon();
          setSnackShow(true);
          setLangueTr(false);
          setLangueUa(false);
          setLangueRu(false);
        });
        // console.dir(ress);
        console.log("form : ", {
          coords: videoCoords && videoCoords.length > 0 ? videoCoords : null,
          discountCouponCode: discountCoupon ? discountCoupon : "",
          discountCouponEndDate: discountCouponEndDate
            ? discountCouponEndDate
            : "",

          videoLangueUa: data.videoLangueUa,
          videoLangueEn: data.videoLangueEn,
          videoLangueTr: data.videoLangueTr,
          videoLangueRu: data.videoLangueRu,
          companyIcon: urlList.videoBanner,
          companyName: data.CustomersSelected,
          description: langueUa ? data.descriotion : null,
          descriptionTr: langueTr ? data.descriotionTr : null,
          descriptionRu: langueRu ? data.descriotionRu : null,
          descriptionEn: langueEn ? data.descriotionEn : null,
          driverBudget: parseInt(data.driverBudget),
          reviewList: [],
          category: data.videoCatagory,
          time: Date.now(),
          title: data.videoAdsTitle ?? null,
          titleTr: data.videoAdsTitleTr ?? null,
          titleRu: data.videoAdsTitleRu ?? null,
          titleEn: data.videoAdsTitleEn ?? null,
          totalBudget: parseInt(data.totalBudget),
          totalWatched: 0,
          url: urlList.url ?? null,
          enUrl: urlList.EnUrl ?? null,
          ruUrl: urlList.RuVideo ?? null,
          trUrl: urlList.TrVideo ?? null,
          userBudget: parseInt(data.userBudget),
          userUid: "1",
          videoAdsBanner: urlList.videoBanner,
          vbTabletAdsClick: urlList.vbTabletAdsClick,
          videoId: s,
          videoTime: data.videoTime,
          oneVideoPrice: parseInt(data.oneVideoPrice),
          videoRate: data.videoRate,
          videoAdsBannerLink: data.videoAdsBannerLink,
          videoAdsBannerText: data.videoAdsBannerText,
        });
      });
    });
    // uploadTask.on('state_changed',
    //     (snapshot) => {
    //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //         setbarVaL(progress)
    //     },
    //     (error) => {
    //         return 'eror'
    //     },
    //     () => {
    //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL2) => {
    //             const storageRef2 = ref(store, `videoBanner/${s}`);
    //             uploadBytes(storageRef2, file2).then((snapshot) => {
    //                 getDownloadURL(snapshot.ref).then((downloadURL) => {
    //                     setDoc(doc(db, 'videoAds', s), {
    //                         companyIcon: downloadURL,
    //                         companyName: data.CustomersSelected,
    //                         description: data.descriotion,
    //                         driverBudget: parseInt(data.driverBudget),
    //                         reviewList: [],
    //                         category: data.videoCatagory,
    //                         time: Date.now(),
    //                         title: data.videoAdsTitle,
    //                         totalBudget: parseInt(data.totalBudget),
    //                         totalWatched: 0,
    //                         url: downloadURL2,
    //                         userBudget: parseInt(data.userBudget),
    //                         userUid: "1",
    //                         videoAdsBanner: downloadURL,
    //                         videoId: s,
    //                         videoTime: data.videoTime,
    //                     }).then(() => {
    //                         reset({ something: '' });
    //                         setAdsVideoUrl()
    //                         setVideoBannerUrl()
    //                         setbarVaL()
    //                         setCustomersIcon()
    //                         setSnackShow(true)
    //                     })
    //                 });
    //             });
    //             // return downloadURL
    //         });
    //     }
    // );
  };

  const uploadVideo = (value, filetype) => {
    if (value.target.files.length !== 0) {
      if (filetype === "En") {
        setAdsVideoEnUrl(URL.createObjectURL(value.target.files[0]));
        setFileEn(value.target.files[0]);
      } else if (filetype === "Ru") {
        setAdsVideoRuUrl(URL.createObjectURL(value.target.files[0]));
        setFileRu(value.target.files[0]);
      } else if (filetype === "Tr") {
        setAdsVideoTrUrl(URL.createObjectURL(value.target.files[0]));
        setFileTr(value.target.files[0]);
      } else {
        setAdsVideoUrl(URL.createObjectURL(value.target.files[0]));
        setFile(value.target.files[0]);
      }
    } else {
      if (file && adsVideo) {
        setAdsVideoUrl();
        // setAdsVideoEnUrl()
        setAdsVideoUrl();
        setAdsVideoRuUrl();
        setAdsVideoTrUrl();
        setFile();
      }
    }
  };

  const uploadImg = (value) => {
    if (value.target.files.length !== 0) {
      if (value.target.name === "adbBanner") {
        console.log("videoBanner ", value.target.files[0]);
        setVideoBannerUrl(URL.createObjectURL(value.target.files[0]));
        setFile2(value.target.files[0]);
      } else if (value.target.name === "vbTabletAdsClick") {
        console.log("vbTabletAdsClick ", value.target.files[0]);
        setVideoInBannerUrl(URL.createObjectURL(value.target.files[0]));
        setFile3(value.target.files[0]);
      }
    } else {
      if (file2 && videoBanner && file3) {
        setVideoBannerUrl();
        setFile2();
        setFile3();
      }
    }
  };

  const onChangeSelected = (value) => {
    setCustomersName(value.target.value);
    var cc = customers.find((e) => e.name === value.target.value);
    setCustomersIcon(cc?.pp);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
  };

  const normalized = (value) => {
    return (
      value
        .match(/\d{1,2}/g)
        ?.join(":")
        .substr(0, 5) ?? ""
    );
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <div className="card">
        <div className="ds-flex">
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-body">
                <div className="form-field">
                  <label>Video Ekleme</label>

                  <div id="customers-selected">
                    <select
                      {...register("CustomersSelected", { required: true })}
                      onChange={onChangeSelected}
                    >
                      <option></option>
                      {customers &&
                        customers.map((e, i) => (
                          <option key={i}>{e?.name}</option>
                        ))}
                    </select>
                    {formState.errors.CustomersSelected && (
                      <span>Zorunlu alan</span>
                    )}
                  </div>
                </div>

                <div className="form-field">
                  <label>Video dil </label>

                  <div className="full-width">
                    <div className="row langue-group">
                      <div>
                        <div>UA</div>
                        <input
                          type="checkbox"
                          className="form-input"
                          {...register("videoLangueUa")}
                          onChange={(e) => {
                            e.target.checked === false && setAdsVideoUrl();
                            setLangueUa(!langueUa);
                          }}
                        />
                      </div>
                      <div>
                        <div>RU</div>
                        <input
                          type="checkbox"
                          className="form-input"
                          {...register("videoLangueRu")}
                          onChange={(e) => {
                            e.target.checked === false && setAdsVideoRuUrl();
                            setLangueRu(!langueRu);
                          }}
                        />
                      </div>
                      <div>
                        <div>EN</div>
                        <input
                          type="checkbox"
                          className="form-input"
                          {...register("videoLangueEn")}
                          onChange={(e) => {
                            e.target.checked === false && setAdsVideoEnUrl();
                            setLangueEn(!langueEn);
                          }}
                        />
                      </div>
                      <div>
                        <div>TR</div>
                        <input
                          type="checkbox"
                          className="form-input"
                          {...register("videoLangueTr")}
                          onChange={(e) => {
                            e.target.checked === false && setAdsVideoTrUrl();
                            setLangueTr(!langueTr);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {langueUa && (
                  <div className="form-field">
                    <label>Video Reklam Baslık </label>
                    <div className="full-width">
                      <input
                        type="text"
                        className="form-input"
                        {...register("videoAdsTitle", { required: true })}
                      />
                      {formState.errors.videoAdsTitle && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}

                {langueTr && (
                  <div className="form-field">
                    <label>Video Reklam Baslık(Tr) </label>
                    <div className="full-width">
                      <input
                        type="text"
                        className="form-input"
                        {...register("videoAdsTitleTr", { required: true })}
                      />
                      {formState.errors.videoAdsTitleTr && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}
                {langueRu && (
                  <div className="form-field">
                    <label>Video Reklam Baslıke(Ru) </label>
                    <div className="full-width">
                      <input
                        type="text"
                        className="form-input"
                        {...register("videoAdsTitleRu", { required: true })}
                      />
                      {formState.errors.videoAdsTitleRu && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}
                {langueEn && (
                  <div className="form-field">
                    <label>Video Reklam Baslık(En) </label>
                    <div className="full-width">
                      <input
                        type="text"
                        className="form-input"
                        {...register("videoAdsTitleEn", { required: true })}
                      />
                      {formState.errors.videoAdsTitleEn && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}
                <div className="form-field">
                  <label>Video Katagori</label>
                  <div id="customers-selected">
                    <select {...register("videoCatagory", { required: true })}>
                      <option></option>
                      {videoCatagory &&
                        videoCatagory.map((e, i) => (
                          <option key={i}>{e?.name}</option>
                        ))}
                    </select>
                    {formState.errors.videoCatagory && (
                      <span>Zorunlu alan</span>
                    )}
                  </div>
                </div>

                <div className="form-field">
                  <label>Reklam veren Sirket</label>
                  <input
                    disabled={true}
                    value={customersName}
                    type="text"
                    className="form-input"
                  />
                </div>

                <div className="form-field">
                  <label>Reklam veren Icon</label>
                  {customersIcon && (
                    <img
                      src={customersIcon}
                      className="obj-fit"
                      alt="images-logo"
                      width="100%"
                      height="100px"
                    />
                  )}
                </div>

                <div className="form-field">
                  <label>Bir video Ücreti</label>
                  <div className="dollarGroup">
                    <div className="full-width">
                      <div className="ds-flex">
                        <span className="dollars">$</span>
                        <input
                          type="number"
                          step="0.001"
                          className="form-input"
                          {...register("oneVideoPrice", { required: true })}
                        />
                      </div>
                      {formState.errors.totalBudget && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                </div>

                <div className="form-field">
                  <label>Reklam toplam bütce</label>
                  <div className="dollarGroup">
                    <div className="full-width">
                      <div className="ds-flex">
                        <span className="dollars">$</span>
                        <input
                          type="number"
                          step="0.001"
                          className="form-input"
                          {...register("totalBudget", { required: true })}
                        />
                      </div>
                      {formState.errors.totalBudget && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                </div>

                <div className="form-field">
                  <label>Şöför Alacağı Tutar</label>
                  <div className="dollarGroup">
                    <div className="full-width">
                      <div className="ds-flex">
                        <span className="dollars">P</span>
                        <input
                          type="number"
                          step="0.001"
                          className="form-input"
                          {...register("driverBudget", { required: true })}
                        />
                      </div>
                      {formState.errors.driverBudget && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                </div>

                <div className="form-field">
                  <label>Kullanıcı Alacağı Tutar</label>
                  <div className="dollarGroup">
                    <div className="full-width">
                      <div className="ds-flex">
                        <span className="dollars">P</span>
                        <input
                          type="number"
                          step="0.001"
                          className="form-input"
                          {...register("userBudget", { required: true })}
                        />
                      </div>
                      {formState.errors.userBudget && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                </div>

                {langueUa && (
                  <div className="form-field">
                    <label>Açıklama</label>
                    <div className="full-width">
                      <textarea
                        className="textarea"
                        {...register("descriotion", { required: true })}
                      ></textarea>
                      {formState.errors.descriotion && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                )}

                {langueTr && (
                  <div className="form-field">
                    <label>Açıklama(tr)</label>
                    <div className="full-width">
                      <textarea
                        className="textarea"
                        {...register("descriotionTr", { required: true })}
                      ></textarea>
                      {formState.errors.descriotionTr && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}
                {langueRu && (
                  <div className="form-field">
                    <label>Açıklama(Ru)</label>
                    <div className="full-width">
                      <textarea
                        className="textarea"
                        {...register("descriotionRu", { required: true })}
                      ></textarea>
                      {formState.errors.descriotionRu && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}

                {langueEn && (
                  <div className="form-field">
                    <label>Açıklama(En)</label>
                    <div className="full-width">
                      <textarea
                        className="textarea"
                        {...register("descriotionEn", { required: true })}
                      ></textarea>
                      {formState.errors.descriotionEn && (
                        <div>Zorunlu alan</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-field">
                  <label>Video Zaman</label>
                  <div className="full-width">
                    <input
                      type="text"
                      className="form-input"
                      {...register("videoTime", {
                        required: true,
                        minLength: 5,
                        maxLength: 5,
                      })}
                      onChange={(event) => {
                        const { value } = event.target;
                        event.target.value = normalized(value);
                      }}
                    />
                    {formState.errors.videoTime && <div>Zorunlu alan</div>}
                  </div>
                </div>

                <div className="form-field">
                  <label>Video Banner Baslık </label>
                  <div className="full-width">
                    <input
                      type="text"
                      className="form-input"
                      {...register("videoAdsBannerText", { required: true })}
                    />
                    {formState.errors.videoAdsTitle && <div>Zorunlu alan</div>}
                  </div>
                </div>

                <div className="form-field">
                  <label>Video Banner Link </label>
                  <div className="full-width">
                    <input
                      type="text"
                      className="form-input"
                      {...register("videoAdsBannerLink", { required: true })}
                    />
                    {formState.errors.videoAdsTitle && <div>Zorunlu alan</div>}
                  </div>
                </div>

                <div className="form-field">
                  <label htmlFor="toggleButton">Video Rate</label>
                  <div className="full-width">
                    <label htmlFor="toggleButton">
                      <div className="toggle">
                        <input
                          type="checkbox"
                          id="toggleButton"
                          className="form-input"
                          {...register("videoRate")}
                        />
                        <span id="toggleButton" className="slider"></span>
                      </div>
                      {formState.errors.videoAdsTitle && (
                        <div>Zorunlu alan</div>
                      )}
                    </label>
                  </div>
                </div>

                <div className="form-field">
                  <label>Video Banner</label>
                  <div className="full-width">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      {...register("adbBanner", { required: true })}
                      onChange={uploadImg}
                      className="form-input"
                    />
                    {formState.errors.adbBanner && <div>Zorunlu alan</div>}
                  </div>
                </div>

                {videoBanner && (
                  <img
                    src={videoBanner}
                    alt="selammd"
                    width="100%"
                    height="200px"
                  />
                )}

                <div className="form-field">
                  <label>Banner in Video</label>
                  <div className="full-width">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      {...register("vbTabletAdsClick", { required: true })}
                      onChange={uploadImg}
                      className="form-input"
                    />
                    {formState.errors.vbTabletAdsClick && (
                      <div>Zorunlu alan</div>
                    )}
                  </div>
                </div>

                {videoInBanner && (
                  <img
                    src={videoInBanner}
                    alt="selammd"
                    width="100%"
                    height="200px"
                  />
                )}

                {langueUa && (
                  <div className="form-field">
                    <label>Video</label>
                    <div className="full-width">
                      <input
                        type="file"
                        {...register("video", { required: true })}
                        onChange={(e) => {
                          uploadVideo(e, "Ua");
                        }}
                        accept="video/*"
                      />
                      {formState.errors.video && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                )}

                {adsVideo && (
                  <video src={adsVideo} width="100%" height="240" controls>
                    Video <source type="video/mp4"></source>
                  </video>
                )}

                {langueTr && (
                  <div className="form-field">
                    <label>Video(tr)</label>
                    <div className="full-width">
                      <input
                        type="file"
                        {...register("videoTr", { required: true })}
                        onChange={(e) => {
                          uploadVideo(e, "Tr");
                        }}
                        accept="video/*"
                      />
                      {formState.errors.videoTr && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                )}

                {adsVideoTr && (
                  <video src={adsVideoTr} width="100%" height="240" controls>
                    Video <source type="video/mp4"></source>
                  </video>
                )}

                {langueRu && (
                  <div className="form-field">
                    <label>Video(Ru)</label>
                    <div className="full-width">
                      <input
                        type="file"
                        {...register("videoRu", { required: true })}
                        onChange={(e) => {
                          uploadVideo(e, "Ru");
                        }}
                        accept="video/*"
                      />
                      {formState.errors.videoRu && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                )}

                {adsVideoRu && (
                  <video src={adsVideoRu} width="100%" height="240" controls>
                    Video <source type="video/mp4"></source>
                  </video>
                )}

                {langueEn && (
                  <div className="form-field">
                    <label>Video(En)</label>
                    <div className="full-width">
                      <input
                        type="file"
                        {...register("videoEn", { required: true })}
                        onChange={(e) => {
                          uploadVideo(e, "En");
                        }}
                        accept="video/*"
                      />
                      {formState.errors.videoEn && <div>Zorunlu alan</div>}
                    </div>
                  </div>
                )}

                {adsVideoEn && (
                  <video src={adsVideoEn} width="100%" height="240" controls>
                    Video <source type="video/mp4"></source>
                  </video>
                )}
              </div>

              {/* discount coupon */}
              <div className="form-field">
                <label htmlFor="discount-btn">Add Discount Coupon :</label>
                <div className="full-width">
                  <label htmlFor="discount-btn">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        id="discount-btn"
                        className="form-input"
                        checked={openDiscountCouponModal}
                        onChange={() =>
                          setOpenDiscountCouponModal(!openDiscountCouponModal)
                        }
                      />
                      <span id="discount-btn" className="slider"></span>
                    </div>
                  </label>
                </div>
              </div>
              {openDiscountCouponModal && (
                <>
                  <div className="form-field">
                    <label>Discount Coupon Code:</label>
                    <input
                      type="text"
                      value={discountCoupon}
                      onChange={(e) => setDiscountCoupon(e.target.value)}
                    />
                  </div>
                  <div className="form-field">
                    <label>Discount Coupon End Date</label>
                    <input
                      type="date"
                      value={discountCouponEndDate}
                      onChange={(e) => setDiscountCouponEndDate(e.target.value)}
                    />
                  </div>
                </>
              )}
              {/* google maps */}
              <div className="form-field">
                <label htmlFor="add-location">Add Location</label>
                <div className="full-width">
                  <label htmlFor="add-location">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        id="add-location"
                        className="form-input"
                        checked={openCoordModal}
                        onChange={() => setOpenCoordModal(!openCoordModal)}
                      />
                      <span id="add-location" className="slider"></span>
                    </div>
                  </label>
                </div>
              </div>
              {openCoordModal && (
                <SelectLocation
                  setVideoCoordinates={setVideoCoords}
                  videoCoordinates={videoCoords}
                />
              )}

              {barVaL && (
                <div>
                  Yükeleme İlerlemesi :{" "}
                  <progress id="file" value={barVaL} max="100"></progress>
                </div>
              )}
              <input className="denemes" type="submit" />
            </form>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackShow}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Video upload Success
        </Alert>
      </Snackbar>
    </div>
  );
};
