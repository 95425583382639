import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/features/AuthSlice';

import { db, auth } from '../base';
import { doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function Login() {
    const { register, handleSubmit } = useForm();
    const [err, setErr] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const checkLogin = async (data) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
            console.log(userCredential);
            const uid = userCredential.user.uid;

            // Koleksiyonları sırayla kontrol et
            const collections = ['admins', 'drivers', 'customers', 'users'];
            let rolePath = ''; // Rolüne göre yönlendirme yapacağımız yol

            for (const collectionName of collections) {
                const docRef = doc(db, collectionName, uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    console.log(`${collectionName} girişi başarılı`);

                    // Rol bilgisine göre rolePath'i güncelle
                    if (collectionName === 'admins') rolePath = '/evgrs';
                    else if (collectionName === 'drivers') rolePath = 'dashboardDriver';
                    else if (collectionName === 'customers') rolePath = 'dashboardCustomer';
                    else if (collectionName === 'users') rolePath = 'dashboardUser';

                    dispatch(setUser({ ...userData, uid }));
                    break; // Kullanıcı bulundu, döngüden çık
                }
            }

            // Rol yolu belirlenmişse, kullanıcıyı ilgili dashboar'a yönlendir
            if (rolePath) {
                history.push(rolePath);
            } else {
                throw new Error('Kullanıcı rolü bulunamadı');
            }
        } catch (error) {
            console.error(error);
            setErr('Giriş bilgileriniz hatalı veya kullanıcı bulunamadı');
            setTimeout(() => setErr(''), 2000);
        }
    };

    return (
        <div className="formCenter">
            {err && <div className='err-text' >{err}</div>}
            <form className="formFields" onSubmit={handleSubmit(checkLogin)}>
                <div className="formField">
                    <label className="formFieldLabel" htmlFor="email">
                        E-Mail Address
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="formFieldInput"
                        placeholder="Enter your email"
                        name="email"
                        {...register("email", { required: true })}
                    />
                </div>

                <div className="formField">
                    <label className="formFieldLabel" htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="formFieldInput"
                        placeholder="Enter your password"
                        name="password"
                        {...register("password", { required: true })}
                    />
                </div>
                <div className="formField">
                    {/* <button onClick={(e) => checkLogin(e)} className="formFieldButton">Sign In</button>{" "} */}
                    <input className="formFieldButton" type="submit" value='Sign In' />
                    {/* <Link to="/" className="formFieldLink">
                        Create an account
                    </Link> */}
                </div>
            </form>
        </div>
    )
}

export default Login
