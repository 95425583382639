import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
    name: 'companyData',
    initialState: { tezgah: {} },
    reducers: {
        setCompanyData: (state, action) => {
            state.tezgah = action.payload
        }

    }

})

export const { setCompanyData } = dataSlice.actions
export const companyDataState = state => state.companyData
export default dataSlice.reducer