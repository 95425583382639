import "./UserDetailCard.css";

import React from "react";
import image from "../../assets/images/tuat.png";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "../modal/Modal";

import { store, db } from "../../base";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

import FullPageLoading from "../../components/fullpageloading/FullPageLoading";

function UserDetailCard({
  userData,
  logoutUser,
  videoSayisi,
  yolculuk,
  setmodalOpen,
  shopHistory,
  shopH,
}) {
  const [editLoading, setEditLoading] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [totalShop, setTotalShop] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(0);

  const [userInfo, setUserInfo] = useState({
    fullName: userData.fullName ? userData.fullName : "",
    phoneNumber: userData.phoneNumber ? userData.phoneNumber : "",
    pp: userData.pp ? userData.pp : "",
  });
  const [newPhoto, setNewPhoto] = useState(null);

  useEffect(() => {
    if (userData) {
      setUserInfo({
        fullName: userData.fullName,
        phoneNumber: userData.phoneNumber,
        pp: userData.pp,
      });
    }
  }, [userData]);

  const handleProfileUpdate = async (newUsername, newPhoneNumber) => {
    setUserInfo((prevData) => ({
      ...prevData,
      fullName: newUsername,
      phoneNumber: newPhoneNumber,
    }));
    setEditModal(false);

    // Bu noktada Firebase veya herhangi bir API kullanarak verileri güncelleyebilirsiniz.ç
    try {
      setEditLoading(true);
      if (newPhoto) {
        // Step 2: Delete the old photo
        if (userData.pp) {
          const photoRef = ref(store, `/profilresimleri/${userData.uid}`);

          const photoSnapshot = await getDownloadURL(photoRef).catch(
            (error) => {
              if (error.code === "storage/object-not-found") {
                console.log("Resim bulunamadı, silme işlemi gerekli değil.");
                return null; // Resim bulunamadığı durumda fonksiyonu burada bitir
              }
              throw error; // Başka bir hata durumunda hatayı yukarı iletebilirsiniz
            }
          );

          if (photoSnapshot !== null) {
            await deleteObject(photoRef);
            console.log("Resim başarıyla silindi.");
          }
        }

        //Step 1: Upload the new photo and get its URL
        const storageRef = ref(store, `/profilresimleri/${userData.uid}`);
        await uploadBytes(storageRef, newPhoto);
        const newImageUrl = await getDownloadURL(storageRef);
        console.log("newImageUrl : ", newImageUrl);
        // Step 3: Update the user data with the new photo URL and other properties
        const updatedUserData = {
          ...userData,
          fullName: newUsername,
          phoneNumber: newPhoneNumber,
          pp: newImageUrl,
        };

        // Step 4: Save the updated user data to Firebase Firestore
        await updateDoc(doc(db, "users", userData.uid), updatedUserData);

        window.location.reload();
      } else {
        // Step 3: Update the user data with the new photo URL and other properties
        const updatedUserData = {
          ...userData,
          fullName: newUsername,
          phoneNumber: newPhoneNumber,
        };

        // Step 4: Save the updated user data to Firebase Firestore
        await updateDoc(doc(db, "users", userData.uid), updatedUserData);
      }

      console.log("User profile updated successfully!");
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
    setEditLoading(false);
  };

  useEffect(() => {
    if (shopH) {
      const totalSpent = shopH
        .filter((shop) => shop.productPrice) // Filtreleme: productPrice değeri olanları al
        .reduce((total, shop) => total + shop.productPrice, 0); // Toplama işlemi: productPrice'ları topla

      setTotalShop(totalSpent);

      // Harcanan puan ve mevcut puanın farkının oranını hesapla
      const remainingRatio =
        ((userData.totalEarnedGain - totalSpent) / userData.totalEarnedGain) *
        100;

      // Oranı barın genişliğine yansıt
      const progressBar = document.querySelector(".progress-fill");
      if (progressBar) {
        progressBar.style.width = `${remainingRatio}%`;
      }

      setCurrentPoint(userData.totalEarnedGain - totalSpent);
    }
  }, [shopH, userData]);

  return (
    <div className="user-detail-card">
      <div className="user-detail">
        <div className="user-detail-1">
          <img
            className="br-15"
            src={userData.pp !== "null" ? userData.pp : image}
            alt="pps"
            width={125}
            height={125}
          />
        </div>
        <div className="user-detail-2">
          <h2>{userData.fullName}</h2>
          <div className="user-defail-group">
            <p>
              <i className="bx bx-mail-send"></i>
              <span>{userData.email}</span>
            </p>
            {userData.phoneNumber && (
              <p>
                <i className="bx bx-phone"></i>
                <span>{userData.phoneNumber}</span>
              </p>
            )}
            {userData.country && (
              <p>
                <i className="bx bx-globe"></i>
                <span>{userData.country}</span>
              </p>
            )}

            {userData.city && (
              <p>
                <i className="bx bx-globe"></i>
                <span>{userData.city}</span>
              </p>
            )}

            {userData.address && (
              <p>
                <i className="bx bx-globe"></i>
                <span>{userData.address}</span>
              </p>
            )}
          </div>
        </div>
        <div className="user-detail-3">
          <div className="button-groups">
            {userData.matchedDriverId !== "null" ? (
              <button
                className="logout-btn"
                onClick={() => setLogoutModal(true)}
              >
                LOGOUT
              </button>
            ) : null}
            <button className="edit-btn" onClick={() => setEditModal(true)}>
              DÜZENLE
            </button>
          </div>
          <div className="kalan-puan">
            <small>Kalan Puan : {currentPoint}</small>
            <div className="progress-bar">
              <div className="progress-fill"></div>
            </div>
          </div>
        </div>
      </div>

      <hr></hr>

      <div className="user-detail-bot">
        <div className="user-detail-bot-step-1">
          <div className="user-detail-bot-icon">
            <i className="bx bx-globe"></i>
          </div>
          <div>
            <span>İzelen video</span>
            <p>{videoSayisi}</p>
          </div>
        </div>
        <div className="user-detail-bot-step-1">
          <div className="user-detail-bot-icon">
            <i className="bx bx-globe"></i>
          </div>
          <div>
            <span>Yolculuk</span>
            <p>{yolculuk}</p>
          </div>
        </div>
        <div className="user-detail-bot-step-1">
          <div className="user-detail-bot-icon">
            <i className="bx bx-globe"></i>
          </div>
          <div>
            <span>Toplanan Puan</span>
            <p>{userData.totalEarnedGain}</p>
          </div>
        </div>
        <div className="user-detail-bot-step-1">
          <div className="user-detail-bot-icon">
            <i className="bx bx-globe"></i>
          </div>
          <div>
            <span>Harcanan Puan</span>
            <p>{totalShop}</p>
          </div>
        </div>
        <div className="user-detail-bot-step-1">
          <div className="user-detail-bot-icon">
            <i className="bx bx-globe"></i>
          </div>
          <div>
            <span>Market Geçmişi</span>
            <div>
              <p
                className="text-button"
                onClick={() => {
                  setmodalOpen(true);
                  shopHistory(userData.uid);
                }}
              >
                Görüntüle
              </p>
            </div>
          </div>
        </div>
        <div className="user-detail-bot-step-1">
          <div className="user-detail-bot-icon">
            <i className="bx bx-globe"></i>
          </div>
          <div>
            <span>Sürücü</span>
            <div>
              {userData.matchedDriverId !== "null"
                ? userData.matchedDriverId
                : "-"}
            </div>
          </div>
        </div>
      </div>

      {logoutModal && (
        <Modal
          close={() => setLogoutModal(false)}
          children={
            <div className="logout-modal-container">
              <p>Sürücü ile bağlantınızı koparmak istediğinize emin misiniz?</p>

              <div className="edit-buttons">
                <button onClick={() => setLogoutModal(false)}>Hayır</button>
                <button
                  onClick={async () => {
                    await logoutUser(userData.matchedDriverId, userData.uid);
                    alert("Sürücü ile bağlantınız koparıldı.");
                  }}
                >
                  Evet
                </button>
              </div>
            </div>
          }
        />
      )}

      {editLoading && <FullPageLoading />}
      {editModal && (
        <Modal
          close={() => setEditModal(false)}
          children={
            <div className="edit-modal-container">
              <h2>Bilgileri Güncelle</h2>
              <label className="image-box" htmlFor="image-input">
                {newPhoto ? (
                  <img
                    src={URL.createObjectURL(newPhoto)}
                    alt="Profil Resmi"
                    className="edit-profile-image"
                  />
                ) : (
                  <img
                    src={userInfo.pp}
                    alt="Profil Resmi"
                    className="edit-profile-image"
                  />
                )}

                <div className="edit-icon">
                  <i className="bx bx-edit"></i>
                </div>
              </label>
              <input
                id="image-input"
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => setNewPhoto(e.target.files[0])}
              />
              <input
                type="text"
                placeholder="Kullanıcı Adı"
                value={userInfo.fullName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, fullName: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Telefon Numarası"
                value={userInfo.phoneNumber}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, phoneNumber: e.target.value })
                }
              />
              <button
                onClick={async () =>
                  await handleProfileUpdate(
                    userInfo.fullName,
                    userInfo.phoneNumber
                  )
                }
              >
                Güncelle
              </button>
            </div>
          }
        ></Modal>
      )}
    </div>
  );
}

export default UserDetailCard;
