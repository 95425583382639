import billBoardIcon from "./billboard-icon.png";
import carImg from "./car-img.png";
import headerBackground from "./header-background.jpg";
import headerBackgroundMobile from "./header-background-mobile.jpg";
import headerMauseScroll from "./header-mause-scroll.png";
import humanIcon from "./human-icon.png";
import newspaperIcon from "./newspaper-icon.png";
import onlineAdsIcon from "./online-ads-icon.png";
import passengerIcon from "./passenger-icon.png";
import taxiIcon from "./taxi-icon.png";
import timeIcon from "./time-icon.png";
import tvIcon from "./tv-icon.png";
import mobileButton from "./menu.png";
import logo from "./logo.png";
import appDowload from "./appdowload.png";
import googleDowload from "./googleplay-dowload.png";
import ipadMini from "./iPad-Mini.png";
import iphone from "./iPhone.png";
import blackLogo from "./black-logo-footer.png"
import appStore from "./Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

const images = {
  billBoardIcon,
  carImg,
  headerBackground,
  headerBackgroundMobile,
  headerMauseScroll,
  humanIcon,
  newspaperIcon,
  onlineAdsIcon,
  passengerIcon,
  taxiIcon,
  timeIcon,
  tvIcon,
  mobileButton,
  logo,
  appDowload,
  googleDowload,
  ipadMini,
  iphone,
  blackLogo,
  appStore,
};
export default images;
