import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'



const app = initializeApp({
    apiKey: "AIzaSyAYXaqtAkqhz_Q6Ib8JIucUWbc3dmPE95k",
    authDomain: "vimitv-admin.firebaseapp.com",
    databaseURL: "https://vimitv-admin.firebaseio.com",
    projectId: "vimitv-admin",
    storageBucket: "vimitv-admin.appspot.com",
    messagingSenderId: "65463927597",
    appId: "1:65463927597:web:5dc062adeb99ee46e4c9a4"
})

export const db = getFirestore(app)
export const auth = getAuth(app)
export const store = getStorage(app)