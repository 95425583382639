import React, { useState, useEffect } from 'react'

import { collection, onSnapshot, updateDoc, doc } from "firebase/firestore";

import { db } from '../base'
import 'react-datepicker/dist/react-datepicker.css'
// import Chart from 'react-apexcharts'
import { useForm } from "react-hook-form";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import image from '../assets/images/tuat.png';
import Table from '../components/table/Table';
import {  useHistory } from 'react-router-dom';



const topCustomers = {
    head: [
        'User id',
        'User name',
        'Tarih'
    ],
}



const ProducDetails = props => {
    const [userData] = useState(props.location.state)
    const [buyers, setBuyers] = useState()
    // const [statedatam, setstatedatam] = useState({})
    const [isEdit, setIsEdit] = useState(true)
    const [snackShow, setSnackShow] = useState(false)
    const { register, handleSubmit, formState } = useForm(
        {
            defaultValues: {
                title: userData.title,
                driverBudget: userData.driverBudget,
                quantity: userData.quantity,
                descriotion: userData.description,
                price: userData.price

            }
        }
    );
    let history = useHistory();

    // const [videoCatagory, setVideoCatagory] = useState()
    // const nows = new Date(Date.now());
    // const week = new Date(nows.getFullYear(), nows.getMonth(), nows.getDate() - 7)
    // const [barVaL] = useState()

    const renderCustomer = (item, index) => (
        <th key={index}>
            {item}</th>
    );

    const renderBody = (item, index) => (

        <tr key={index} onClick={()=> history.push({
            pathname:  `/users/${item.userId}`,
            state: {  }
        })}>
            <td>
                {item.userId}
            </td>
            <td>
                {item.userName}
            </td>
            <td>
                {new Date(item.time).toLocaleDateString().replace(/\./g, '/')}
            </td>
        </tr>


    );


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackShow(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const onSubmit = async (data) => {
        console.log(data)
        const refDoc = doc(db, "products", userData.uid);
        await updateDoc(refDoc, {
            description: data.descriotion,
            price: parseInt(data.price),
            title: data.title,
            quantity: parseInt(data.quantity)

        }).then((e) => {
            setSnackShow(true)
            setIsEdit(!isEdit)
        });
    }
    useEffect(() => {

        const unsub = onSnapshot(collection(db, `/products/${userData.uid}/buyers`), (snapshot) => {
            let admins = snapshot.docs.map((e) => {
                let a = e.data()
                return a
            })
            admins = admins.sort((a, b) => b.time - a.time)
            setBuyers(admins)
        })
        return unsub
    }, [userData.uid])
    return (
        <div>
            <div className="card mn-content">
                <div className="card__body ">
                    <div className='user-details'>
                        <div className='user-details-1'>
                            <img className='br-15 obj-fit'  src={userData.banner !== 'null' ? userData.banner : image} alt={"pps"} width={125} height={125} />
                        </div>
                        <div className='user-details-2'>
                            {/* <div>{userData.fullName}</div> */}
                            <div >
                                <div>
                                    <span>{userData.title}</span>
                                </div>
                                <div>
                                    <span>{userData.description}</span>
                                </div>
                                {/* <div>
                                <i class='bx bx-globe' ></i><span>Kiev</span>
                            </div> */}
                            </div>

                            {/* <div>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est fugit minus id officia consectetur repellat quidem distinctio quos, modi, beatae quibusdam voluptate
                        </div> */}
                        </div>
                        <div className='user-details-3'>
                            <div>
                                {/* <button className='btn-2' >LOGOUT</button> */}
                                <button className='btn-3' onClick={() => { setIsEdit(!isEdit) }}>{isEdit ? 'DÜZENLE' : 'İptal'}</button>
                            </div>
                            <div>

                            </div>
                        </div>

                    </div>
                    <hr></hr>
                    <div className='user-details-bot'>
                        <div className='user-details-bot-step-1'>
                            <div className='user-details-bot-icon'>
                                <i className='bx bx-globe' ></i>
                            </div>
                            <div>
                                <span>Total Alınma Sayısı</span>
                                <div>{buyers?.length}</div>
                            </div>

                        </div>
                        <div className='user-details-bot-step-1'>
                            {/* <div className='user-details-bot-icon'>
                                <i className='bx bx-globe' ></i>
                            </div> */}
                            <div>
                                {/* <span>Tablet İzlenme Sayisi</span>
                                <div>{statedatam?.tablet?.length}</div> */}
                            </div>

                        </div>
                        <div className='user-details-bot-step-1'>
                            {/* <div className='user-details-bot-icon'>
                                <i className='bx bx-globe' ></i>
                            </div> */}
                            <div>
                                {/* <span>Mobil izlenme sayisi</span>
                                <div>{statedatam?.mobil?.length}</div> */}
                            </div>

                        </div>
                        <div className='user-details-bot-step-1'>
                            {/* <div className='user-details-bot-icon'>
                                <i className='bx bx-globe' ></i>
                            </div> */}
                            <div>
                                {/* <span>Rate / ort</span>
                                <div>{(statedatam?.ratetop / statedatam?.tablet?.length).toFixed(2)}</div> */}
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div className='row'>
                <div className='col-7 col-md-12'>
                    <div className='card'>
                        <div className='user-details-card-header'>
                            <div>
                                <div>Reklam Bilgiler</div>

                            </div>
                            <div >
                                {/* <button className={`button-5 mg-left-5 ${tabindex === 0 ? 'activebutton' : ''}`} onClick={() => { settabindex(0) }} >Aylık</button>
                            <button className={`button-5 mg-left-5 ${tabindex === 1 ? 'activebutton' : ''}`} onClick={() => { settabindex(1) }}>Haftalık</button>
                            <button className={`button-5 mg-left-5 ${tabindex === 2 ? 'activebutton' : ''}`} onClick={() => { settabindex(2) }}>Günlük</button>
                            <button className={`button-5 mg-left-5 ${tabindex === 3 ? 'activebutton' : ''}`} onClick={() => { settabindex(3) }}>Özel</button> */}
                            </div>

                        </div>
                        <div>

                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="form-field">
                                    <div className='formlabel'>Title  </div>
                                    <input className='frominput' disabled={isEdit}  {...register("title", { required: true, })} ></input>
                                    {formState.errors.title && <span>This field is required</span>}
                                </div>

                                <div className="form-field">
                                    <label >Descriotion</label>
                                    <div className='full-width'>
                                        <textarea className='textarea' disabled={isEdit}  {...register("descriotion", { required: true })}></textarea>
                                        {formState.errors.descriotion && <div>Zorunlu alan</div>}
                                    </div>
                                </div>


                                <div className="form-field">
                                    <label >Price</label>
                                    <div className='dollarGroup'>
                                        <div className='full-width'>
                                            <div className='ds-flex'>
                                                <span className='dollars'>$</span>
                                                <input type="number" className='form-input' disabled={isEdit} {...register("price", { required: true })} />
                                            </div>
                                            {formState.errors.price && <div>Zorunlu alan</div>}
                                        </div>
                                    </div>
                                </div>


                                <div className="form-field">
                                    <label >Quantity</label>
                                    <div className='dollarGroup'>
                                        <div className='full-width'>
                                            <div className='ds-flex'>
                                                <input type="number" className='form-input' disabled={isEdit} {...register("quantity", { required: true })} />
                                            </div>
                                            {formState.errors.quantity && <div>Zorunlu alan</div>}
                                        </div>
                                    </div>
                                </div>

                                {
                                    isEdit && <div className="form-field">
                                        <div className='formlabel'> banner</div>
                                        <div>
                                            <img src={userData.banner} alt='sd' width='100%' height='215px' className='obj-fit' />
                                        </div>
                                    </div>
                                }
                                {
                                    isEdit && <div className="form-field">
                                        <div className='formlabel'> icon</div>
                                        <div>
                                            <img src={userData.icon} alt='sd' width='100%' height='215px' className='obj-fit' />
                                        </div>
                                    </div>
                                }
                                {
                                    isEdit && userData.images[0] && <div className="form-field">
                                        <div className='formlabel'> banner</div>
                                        <div>
                                            <img src={userData.images[0]?.image} alt='sd' width='100%' height='215px' className='obj-fit' />
                                        </div>
                                    </div>
                                }
                                {
                                    isEdit && userData.images[1] && <div className="form-field">
                                        <div className='formlabel'> banner</div>
                                        <div>
                                            <img src={userData.images[1]?.image} alt='sd' width='100%' height='215px' className='obj-fit' />
                                        </div>
                                    </div>
                                }
                                {
                                    isEdit && userData.images[2] && <div className="form-field">
                                        <div className='formlabel'> banner</div>
                                        <div>
                                            <img src={userData.images[2]?.image} alt='sd' width='100%' height='215px' className='obj-fit' />
                                        </div>
                                    </div>
                                }

                                {!isEdit && <input className='denemes' type="submit" />}

                            </form>

                        </div>


                    </div>
                </div>

                <div className='col-5 col-md-12'>

                    <div className='card'>
                        <div>Son alanlar</div>

                        <div>
                            {buyers && <Table
                                headData={topCustomers.head}
                                bodyData={buyers.map((e) => e)}
                                renderHead={(item, index) => renderCustomer(item, index)}
                                renderBody={(item, index) => renderBody(item, index)}
                                limit={5}

                            />}
                        </div>

                    </div>
                </div>
                <Snackbar open={snackShow} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Güncelleme yapıldı.
                    </Alert>
                </Snackbar>
            </div>

        </div>
    )
}


export default ProducDetails
