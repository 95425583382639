import React from 'react'
import { useRef,useState,useEffect } from 'react'
import './thememenu.css'
import { useDispatch } from 'react-redux'
import {setMode,setColor} from '../../redux/features/themeSlice'

const mode_setting = [
    {
        id: 'light',
        name: 'Light',
        background: 'light-background',
        class: 'theme-mode-light'
    },
    {
        id: 'dark',
        name: 'Dark',
        background: 'dark-background',
        class: 'theme-mode-dark'
    },
]

const color_settings = [
    {
        id: 'blue',
        name: 'Blue',
        background: 'blue-color',
        class: 'theme-color-blue'
    },
    {
        id: 'red',
        name: 'Red',
        background: 'red-color',
        class: 'theme-color-red'
    },
    {
        id: 'cyan',
        name: 'Cyan',
        background: 'cyan-color',
        class: 'theme-color-cyan'
    }
]

const clickOutSideRef = (content_ref, toggle_ref) => {
    document.addEventListener('mousedown', (e) => {
        if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
            content_ref.current.classList.toggle('active')
        } else {
            if (content_ref.current && !content_ref.current.contains(e.target)) {
                content_ref.current.classList.remove('active')
            }
        }

    })
}


function ThemeMenu() {

    const menu_ref = useRef(null)
    const menu_toogle = useRef(null)
    clickOutSideRef(menu_ref,menu_toogle)

     const [currMode, setcurrMode] = useState('light')

    const [currColor, setcurrColor] = useState('blue')

    const dispatch = useDispatch()
    
    const setModes = mode=>{
     setcurrMode(mode.id)
         localStorage.setItem('themeMode',mode.class)
         dispatch(setMode(mode.class))
    }
    const setColors = color=>{
         setcurrColor(color.id)
         localStorage.setItem('colorMode',color.class)
         dispatch(setColor(color.class))
    }

    const setActiveMenu=()=>menu_ref.current.classList.add('active')
    const setCloseMenu=()=>menu_ref.current.classList.remove('active')

    useEffect(() => {
        const themeClass = mode_setting.find(e=>e.class === localStorage.getItem('themeMode','theme-mode-light'))
        const themeColor = color_settings.find(e=>e.class === localStorage.getItem('colorMode','theme-mode-light'))
        if(themeClass !== undefined) setcurrMode(themeClass.id)
        if(themeColor !== undefined) setcurrColor(themeColor.id)
    }, [])



    return (
        <div >
            <button ref={menu_toogle} className='dropdown__toggle' onClick={()=>setActiveMenu()}>
                <i className='bx bx-palette'></i>
            </button>
            <div className='theme-menu' ref={menu_ref}>
                <h4>Theme Menu</h4>
                <button onClick={()=>setCloseMenu()} className='theme_menu__close'>
                    <i className='bx bx-x'></i>
                </button>
                <div className='theme-menu__select'>
                    <span>Choose mode</span>

                    <ul className='mode-list'>
                        {
                            mode_setting.map((item, index) => (
                                <li key={index} onClick={()=>setModes(item)}>
                                    <div className={`mode-list__color ${item.background} ${item.id === currMode ? 'active' :''}`}>
                                        <i className='bx bx-check'></i>
                                    </div>
                                    <span>{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>

                </div>
                <div className='theme-menu__select'>
                    <span>Choose mode</span>

                    <ul className='mode-list'>
                        {
                            color_settings.map((item, index) => (
                                <li key={index} onClick={()=>setColors(item)}>
                                    <div  className={`mode-list__color ${item.background} ${item.id === currColor ? 'active' :''}` }>
                                        <i className='bx bx-check'></i>
                                    </div>
                                    <span>{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>

                </div>

            </div>

        </div>
    )
}

export default ThemeMenu
