import { Modal } from "@mui/material";
import "./CustomerCreditBalanceCard.css";
import { useState, useEffect } from "react";
import { db } from "../../base";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import CountUp from "react-countup";

function CustomerCreditBalanceCard({ customer }) {
  const [loaded, setLoaded] = useState(false);

  const [videos, setVideos] = useState([]);

  const [totalBudget, setTotalBudget] = useState(0); // Örnek bir başlangıç değeri
  const [activeBudget, setActiveBudget] = useState(0); // Örnek bir başlangıç değeri
  const [remainingBudget, setRemainingBudget] = useState(0); // Örnek bir başlangıç değeri

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (customer) {
      const q = query(
        collection(db, "videoAds"),
        where("companyName", "==", `${customer?.name}`)
      );
      getDocs(q)
        .then((data) => {
          const arry = data.docs.map((e) => e.data()); // Sadece video verilerini döndür
          setVideos(arry);
        })
        .catch((error) => {
          console.error("Firestore sorgusu hatası:", error);
        });
    }
    if (customer.budgets) {
      setTotalBudget(customer.budgets.totalBudget);
    }
  }, [customer]);

  useEffect(() => {
    if (videos && videos.length > 0) {
      setLoaded(true);
      //Videolara verilen bütçe toplamı
      const videosTotalBudget = videos.reduce((total, video) => {
        const totalBudget = parseFloat(video.totalBudget);
        return total + (isNaN(totalBudget) ? 0 : totalBudget);
      }, 0);

      setActiveBudget(videosTotalBudget);
      setLoaded(false);
    }
  }, [videos]);

  useEffect(() => {
    if (totalBudget && activeBudget) {
      const calc = Number(totalBudget) - Number(activeBudget);
      setRemainingBudget(calc);
    }
  }, [activeBudget, totalBudget]);

  const handleSave = async (newTotalBudget) => {
    const updatedCustomer = {
      ...customer,
      budgets: {
        ...customer.budgets,
        totalBudget: newTotalBudget,
      },
    };

    const customerDocRef = doc(db, "customers", customer.id);
    console.log(updatedCustomer.budgets);
    try {
      await updateDoc(customerDocRef, {
        budgets: updatedCustomer.budgets,
      });

      setTotalBudget(Number(newTotalBudget));
      closeModal();
    } catch (error) {
      console.error("Firestore güncelleme hatası:", error);
    }
  };

  console.log(customer);

  return (
    <div className="card customer-credit-balance-container">
      <div className="title">
        <h3>Credit Balance</h3>
        <button className="add-button" onClick={openModal}>
          Adjust Balance
        </button>
        {isModalOpen && (
          <Modal
            onClose={closeModal}
            open={isModalOpen}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <AdjustBalanceModal
                totalBudget={totalBudget}
                setTotalBudget={setTotalBudget}
                closeModal={closeModal}
                handleSave={handleSave}
              />
            </div>
          </Modal>
        )}
      </div>
      <div className={`content ${loaded ? "loaded" : ""}`}>
        <div className="price-box">
          <p className="price-title">Total Budget : </p>{" "}
          <p className="price">
            $
            <CountUp start={0} end={totalBudget} duration={2} decimals={2} />
          </p>
          <span className="currency">USD</span>
        </div>
        <div className="price-box">
          <p className="price-title">Active Budget : </p>{" "}
          <p className="price">
            $
            <CountUp start={0} end={activeBudget} duration={2} decimals={2} />
          </p>
          <span className="currency">USD</span>
        </div>
        <div className="price-box">
          <p className="price-title">Remaining Budget : </p>{" "}
          <p className="price">
            $
            <CountUp
              start={0}
              end={remainingBudget}
              duration={2}
              decimals={2}
            />
          </p>
          <span className="currency">USD</span>
        </div>
        <p className="credit-info">
          Balance will increase the amount due on the customer's next invoice.
        </p>
      </div>
    </div>
  );
}

export default CustomerCreditBalanceCard;

function AdjustBalanceModal({ totalBudget, handleSave, closeModal }) {
  const [newTotalBudget, setNewTotalBudget] = useState(totalBudget);

  function onSave() {
    handleSave(Number(newTotalBudget));
  }

  return (
    <div className="adjust-balance-modal-container">
      <h3>Adjust Balance</h3>
      <div className="input-container">
        <div className="form-field">
          <label>Total Budget</label>
          <div className="dollarGroup">
            <div className="full-width">
              <div className="ds-flex">
                <span className="dollars">$</span>
                <input
                  type="number"
                  step="0.001"
                  className="form-input"
                  value={newTotalBudget}
                  onChange={(e) => setNewTotalBudget(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <button onClick={onSave}>Save</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
}
