import React, { useState, useEffect,useCallback } from 'react'
import Table2 from '../../components/table2/Table2'
// import { useSelector } from 'react-redux'
// import { authState } from '../redux/features/AuthSlice'
import { collection,  onSnapshot, updateDoc, doc } from "firebase/firestore";
// import defauldImage from '../assets/images/favicon.png'
// import { Link } from 'react-router-dom'
import { db } from '../../base'
// import { Button } from '@mui/material';

import { Link } from 'react-router-dom';

export const Drivers = () => {


  // const auth = useSelector(authState)
  const [coll, setColl] = useState([])
  const [roww, setroww] = useState([])
  const [initialColumn] = useState([])
  const localStorageName = 'benchesHidecolumn'


  const goToDetails = (driverId) => {
    // Driver id'si ile DriversDetails sayfasına yönlendirme yapın.
    window.location.href = `/driversdetails/${driverId}`;
  }
  const DriverLink = useCallback(({ row }) => {
    return <Link to={`./driversdetails/${row.original.uid}`}>Detayları Gör</Link>;
  }, []);

  const MyCell4 = useCallback(({ row }) => {
    return (
      <button 
        className='button-1' 
        onClick={() => goToDetails(row.original.uid)}
      >
        Detayları Gör
      </button>
    );
  }, []);


  const  MyCell2 = useCallback(({ row })=> {
    if (row.original.matchedUserId !== 'null') {
      return <button className='button-1' onClick={() => logoutUser(row.original.uid, row.original.matchedUserId)}>Logut User</button>
    }
    return null;
  },[]);
  const MyCell3 = useCallback(({ row }) => {
    if (row.original.logout) {
      // Sürücü aktif, bu yüzden düğmeyi yeşil yap
      return (
        <button 
        className='button-1' 
        disabled={false} 
      >
        Sürücü aktif değil
      </button>
      );
    } else {
      // Sürücü aktif değil, bu yüzden düğmeyi varsayılan renkte bırak
      return (
        <button
        className='button-1' 
          color='#00ff00' 
          onClick={() => logoutDriver(row.original.uid)}
        >
          Driver aktif
        </button>
       
      );
    }
  }, []);
  
  


  async function logoutUser(driverId, userId) {
    const refDoc = doc(db, "drivers", driverId);
    const refDoc2 = doc(db, "users", userId);
    await updateDoc(refDoc, {
      matchedUserId: 'null',
      matchedUserName: 'null'
    })
    await updateDoc(refDoc2, {
      matchedDriverId: 'null'
    })
  }
  async function logoutDriver(driverId) {
    const refDoc = doc(db, "drivers", driverId);
    await updateDoc(refDoc, {
      logout: true,
    })
  }

  useEffect(() => {
    var admins
    var coll = [
      { Header: 'Email', accessor: 'email' },
      { Header: 'Name', accessor: 'fullName' },
      { Header: 'matchedUser', accessor: 'matchedUserName' },
      { Header: '', accessor: 'Settinsgs', Cell: MyCell2},
      { Header: '', accessor: 'Logout', Cell: MyCell3},
      { Header: 'Details', accessor: 'Details', Cell: DriverLink}
    ]
    const unsub = onSnapshot(collection(db, 'drivers'), (snapshot) => {
      admins = snapshot.docs.map((e) => {
        let a = e.data()
        return a
      })
      setColl(coll)
      setroww(admins)
    })
    return unsub
  }, [MyCell2,MyCell3, MyCell4])

  return (
    <div>
      <h2 className="page-header" >
        Sürücüler
      </h2>
      <Table2 propsColumn={coll} propsRow={roww}
        localStorageName={localStorageName}
        hiddenColumnsPage={initialColumn}
      />

    </div>
  )
}
