import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import "../../assets/css/customerDetails.css";
import { db } from "../../base";
import { doc, getDoc } from "firebase/firestore";

// Alt bileşenlerinizi import edin
import CustomerInfoCard from "./CustomerInfoKart/CustomerInfoKart";
import CustomerCreditBalanceCard from "./CustomerCreditBalanceKart/CustomerCreditBalanceKart";
import CustomerLastVideosCard from "./CustomerLastVideosKart/CustomerLastVideosKart";
import CustomerLastSlidersCard from "./CustomerLastSlidersKart/CustomerLastSlidersKart";

function DashboardCustomer() {
  const user = useSelector(state => state.auth.user);
  const [customerDetails, setCustomerDetails] = useState({});
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (user.uid) {
        const customerDocRef = doc(db, "customers", user.uid);
        try {
          const customerDocSnap = await getDoc(customerDocRef);
          if (customerDocSnap.exists()) {
            setCustomerDetails(customerDocSnap.data());
          } else {
            console.log("Müşteri bilgisi bulunamadı");
          }
        } catch (error) {
          console.error("Müşteri bilgileri alınamadı", error);
        }
      }
    };

    fetchCustomerDetails();
  }, [user.uid]);
 
  

  return (
    <div className="customer-detail-content">
    
      <h2 className="page-header">Customer Detail</h2>
      <div className="customer-detail-container row ">
        <div className="customer-info-card-container card col-5 col-md-12">
        <CustomerInfoCard customer={user} setCustomer={() => {}} />
        </div>
        <div className="customer-tabs-card-container col-7 col-md-12 ">
          <div className="tab-menu">
            <div
              className={`tab-item ${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              Overview
            </div>
            <div
              className={`tab-item ${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Events & Logs
            </div>
            <div
              className={`tab-item ${activeTab === 3 ? "active" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Statements
            </div>
          </div>
          <div className="tab-content">
          <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`}>
            <CustomerCreditBalanceCard customer={customerDetails} setCustomer={() => {}}/>
            <CustomerLastVideosCard customer={customerDetails} setCustomer={() => {}}/>
            <CustomerLastSlidersCard customer={customerDetails} setCustomer={() => {}}/>
            </div>

            <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`}>
              Events & Logs Tab Content
            
         
            </div>

            <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`}>
              Statements Tab Content
             
            </div>
          </div>
        </div>
      </div>

      <div className="customer-detail-container row ">
     
        </div>
    </div>
  );
}

export default DashboardCustomer;
