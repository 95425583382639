import React, { useState, useEffect } from "react";
import Table2 from "../components/table2/Table2";
// import { useSelector } from 'react-redux'
// import { authState } from '../redux/features/AuthSlice'
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import defauldImage from "../assets/images/favicon.png";
import { Link } from "react-router-dom";
import { db, store } from "../base";
import { useDispatch } from "react-redux";
import { setVideo } from "../redux/features/videoSlice";
import { Modal } from "../components/modal/Modal";
import { deleteObject, ref } from "firebase/storage";

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FullPageLoading from "../components/fullpageloading/FullPageLoading";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function formatBudget(budget) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(budget);
}

function Videos() {
  const [deleteLoading, setDeleteLoading] = useState(false);
  /* alert */
  const [snackShow, setSnackShow] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
  };

  // const auth = useSelector(authState)
  const [coll, setColl] = useState([]);
  const [roww, setroww] = useState([]);
  const [initialColumn] = useState([]);

  const [modalOpen, setmodalOpen] = useState(false);
  const [deleteVideoId, setDeleteVideoId] = useState();

  const localStorageName = "benchesHidecolumn";

  const dispatch = useDispatch();

  function MyCell({ value }) {
    return (
      <img
        alt={"imagess"}
        src={
          value !== "null" && value !== ""
            ? value ?? defauldImage
            : defauldImage
        }
        width={40}
        height={40}
      />
    );
  }
  function MyCell2({ row }) {
    // return <button className='button-1'>Details</button>
    return (
      <div className="button-group">
        <Link
          to={{
            pathname: `videos/${row.original.videoId}`,
            state: row.original,
          }}
        >
          <button className="button-1">Details</button>
        </Link>
        <button
          className="button-1"
          onClick={() => {
            setDeleteVideoId(row.original.videoId);
            console.log(row.original.videoId);
            setmodalOpen(true);
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  function GivenBudgetCell({ row }) {
    return (
      <>
        {Number(row.original.totalWatched) > 0
          ? formatBudget(
              parseFloat(row.original.oneVideoPrice) *
                Number(row.original.totalWatched)
            )
          : 0}
      </>
    );
  }
  function RemainingBudgetCell({ row }) {
    return (
      <>
        {Number(row.original.totalWatched) > 0
          ? formatBudget(
              Number(row.original.totalBudget) -
                parseFloat(row.original.oneVideoPrice) *
                  Number(row.original.totalWatched)
            )
          : 0}
      </>
    );
  }

  function TotalBudgetCell({ row }) {
    return <>{formatBudget(Number(row.original.totalBudget))}</>;
  }

  useEffect(() => {
    const coll = [
      { Header: "", accessor: "videoAdsBanner", Cell: MyCell },
      { Header: "title", accessor: "displayTitle" },
      { Header: "totalWatched", accessor: "totalWatched" },
      { Header: "givenBudget", accessor: "", Cell: GivenBudgetCell },
      { Header: "remainingBudget", accessor: "", Cell: RemainingBudgetCell },
      { Header: "totalBudget", accessor: "totalBudget", Cell: TotalBudgetCell },
      { Header: "companyName", accessor: "companyName" },
      { Header: "", accessor: "Settinsgs", Cell: MyCell2 },
    ];

    const unsub = onSnapshot(collection(db, "videoAds"), (snapshot) => {
      const admins = snapshot.docs.map((doc) => {
        const data = doc.data();
        const displayTitle = data.titleEn || data.titleTr;
        return {
          ...data,
          displayTitle,
        };
      });
      dispatch(setVideo(admins));
      setColl(coll);
      setroww(admins);
    });

    return unsub;
  }, [dispatch]);

  // Fonksiyon, verilen klasördeki mevcut fotoğrafı siler
  const deleteCurrentPhoto = async (folder, currentPhoto) => {
    try {
      const photoRef = ref(store, `${folder}/${currentPhoto}`);
      console.log(photoRef);
      await deleteObject(photoRef);
      console.log(`Mevcut fotoğraf silindi: ${currentPhoto}`);
    } catch (error) {
      console.error(`Mevcut fotoğrafı silerken bir hata oluştu: ${error}`);
    }
  };

  /* Video silme işlemi */
  async function deleteVideo(videoId) {
    setmodalOpen(false);
    setDeleteLoading(true);
    try {
      await deleteDoc(doc(db, "videoAds", videoId)); // "videoAds" koleksiyonundan belirtilen belge silinir

      /* storagetaki video ve fotoğrafları siler */
      await deleteCurrentPhoto("videoBanner", videoId);
      await deleteCurrentPhoto("vbTabletAdsClick", videoId);
      await deleteCurrentPhoto("videoAds2", videoId);
      await deleteCurrentPhoto("videoAds2/EnVideo", videoId);
      await deleteCurrentPhoto("videoAds2/TrVideo", videoId);
      await deleteCurrentPhoto("videoAds2/RuVideo", videoId);

      console.log("Belge başarıyla silindi");
      setSnackShow(true);
    } catch (error) {
      console.error("Hata oluştu: ", error);
    }
    setDeleteLoading(false);
  }

  return (
    <div>
      <h2 className="page-header">Videolar</h2>
      <Table2
        propsColumn={coll}
        propsRow={roww}
        localStorageName={localStorageName}
        hiddenColumnsPage={initialColumn}
      />

      {modalOpen && (
        <Modal close={setmodalOpen}>
          <div className="form-field ">
            <label>Uyarı : </label>
            <div>
              <p>
                {" "}
                {deleteVideoId}'idli video silinecek bu işlemi geri alamassınız
                emin misiniz ?{" "}
              </p>
            </div>
          </div>
          <button
            className="button-1"
            onClick={() => {
              deleteVideo(deleteVideoId);
            }}
          >
            Onay
          </button>
        </Modal>
      )}
      {deleteLoading && <FullPageLoading />}
      <Snackbar
        open={snackShow}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Video başarıyla silindi
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Videos;
