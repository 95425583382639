import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./CustomerLastVideosCard.css";
import { db } from "../../base";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

function formatBudget(budget) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(budget);
}

function CustomerLastVideosCard({ customer }) {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (customer) {
      const q = query(
        collection(db, "videoAds"),
        where("companyName", "==", `${customer?.name}`)
      );
      getDocs(q)
        .then((data) => {
          const promises = data.docs.map(async (e) => {
            const querySnapshot = await getDocs(
              collection(db, `/videoAds/${e.id}/notification`)
            );
            const clickData = querySnapshot.docs.map((doc) => doc.data());

            return {
              ...e.data(),
              videoClickCount: clickData[0]?.sendNotification,
            };
          });

          Promise.all(promises)
            .then((arry) => {
              setVideos(arry);
            })
            .catch((error) => {
              console.error("Promise hata:", error);
            });
        })
        .catch((error) => {
          console.error("Firestore sorgusu hatası:", error);
        });
    }
  }, [customer]);

  return (
    <div className="customer-last-videos-card-container card">
      <div className="header">
        <h2>Last Videos</h2>
        <p className="subtitle">Live Update</p>
        <div className="divider"></div>
      </div>
      <div className="video-table-container">
        <table>
          <thead>
            <tr>
              <th>Video</th>
              <th>Total Budget</th>
              <th>Given Budget</th>
              <th>Remaining Budget</th>
              <th>Watch Count</th>
              <th>Click Count</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video, index) => (
              <tr key={index}>
                <td className="video-td">
                  <img
                    src={video.companyIcon} // Video banner resmi
                    alt={`Video ${index + 1}`}
                    className="video-banner"
                  />
                  <div className="video-title">
                    <p className="video-name">{video.titleEn || video.title}</p>
                    <p className="video-category">{video.category}</p>
                  </div>
                </td>
                <td className="budget">{formatBudget(video.totalBudget)}</td>
                <td className="budget">
                  {Number(video.totalWatched) > 0
                    ? formatBudget(
                        parseFloat(video.oneVideoPrice) *
                          Number(video.totalWatched)
                      )
                    : 0}
                </td>
                <td className="budget">
                  {Number(video.totalWatched) > 0
                    ? formatBudget(
                        Number(video.totalBudget) -
                          parseFloat(video.oneVideoPrice) *
                            Number(video.totalWatched)
                      )
                    : 0}
                </td>
                <td className="watch-count">{video.totalWatched}</td>
                <td className="click-count">
                  {video.videoClickCount ? video.videoClickCount : "-"}
                </td>

                <td>
                  <Link
                    to={{
                      pathname: `/evgrs/videos/${video.videoId}`,
                      state: video,
                    }}
                    className="details-button"
                  >
                    <i className="bx bx-right-arrow-alt"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerLastVideosCard;
