import { useFormik } from 'formik';
import { useRef } from 'react';
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import images from "../../Asset/Img/image"
import "./Footer.css"

function Footer() {
  const form = useRef()

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      message: "",

    },
    onSubmit: (values) => {
      emailjs.sendForm('service_djfxpje', 'template_64t4k1l', form.current, '5ANF71UTgiQQtr9ty')
        .then((result) => {
          console.log(result.text);

        }, (error) => {
          console.log(error.text);
        });

        formik.resetForm();
    }

  })

  return (
    <div className="footer-container" id="footer-container">
    <br></br>
    <br></br>
      <div className="footer-content">
      <br></br>
        <div className="footer-header-text">
          <h2>Contact</h2>
        </div>
        <br></br>
        <div className="footer-header-text">
          <h3>
254 Chapman Rd, Ste 208 #11224
Newark, Delaware 19702 Us</h3>
        </div>
        <br></br>
        <form ref={form} onSubmit={formik.handleSubmit} className="form-container">
          <div className="form-info">
            <input type="name" placeholder="Name" id='name' name='name' onChange={formik.handleChange} value={formik.values.name} />
            <input placeholder='E-mail' type="email" id='email' name='email' onChange={formik.handleChange} value={formik.values.email} />
          </div>
          <div className="message">
            <textarea cols="100" rows="4" placeholder="Your Message" id="message" name='message' onChange={formik.handleChange}></textarea>
            <button type='submit'>Send</button>
          </div>
 
        </form>
      
      </div>
      <div className="copyright">
      <br></br>
        <img src={images.blackLogo} alt="Logo" />
        <br></br>
        <p>All rights reserved © 2023</p>
        <br></br>

       
      </div>
    </div>
  )
}

export default Footer