import "../assets/css/customerDetails.css"; // Import the CSS file
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomerInfoCard from "../components/CustomerInfoCard/CustomerInfoCard";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../base";
import CustomerCreditBalanceCard from "../components/CustomerCreditBalanceCard/CustomerCreditBalanceCard";
import CustomerLastVideosCard from "../components/CustomerLastVideosCard/CustomerLastVideosCard";
import CustomerLastSlidersCard from "../components/CustomerLastSlidersCard/CustomerLastSlidersCard";

function CustomerDetail() {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(1);

  const [customer, setCustomer] = useState({
    pp: "",
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    const fetchCustomer = async () => {
      const docRef = doc(db, "customers", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCustomer({
          ...docSnap.data(),
          id: docSnap.id,
        });
      } else {
        console.log("No such document!");
      }
    };

    if (id) {
      fetchCustomer();
    }
  }, [id]);

  return (
    <div className="customer-detail-content">
      <h2 className="page-header">Customer Detail</h2>
      <div className="customer-detail-container row ">
        <div className="customer-info-card-container card col-5 col-md-12">
          <CustomerInfoCard customer={customer} setCustomer={setCustomer} />
        </div>
        <div className="customer-tabs-card-container col-7 col-md-12 ">
          <div className="tab-menu">
            <div
              className={`tab-item ${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              Overview
            </div>
            <div
              className={`tab-item ${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Events & Logs
            </div>
            <div
              className={`tab-item ${activeTab === 3 ? "active" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Statements
            </div>
          </div>
          <div className="tab-content">
            <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`}>
              <CustomerCreditBalanceCard customer={customer} />
              <CustomerLastVideosCard customer={customer} />
              <CustomerLastSlidersCard customer={customer} />
            </div>

            <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`}>
              Events & Logs Tab Content
            </div>

            <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`}>
              Statements Tab Content
            </div>
          </div>
        </div>
      </div>
      <div className="customer-detail-container row ">
        </div>
    </div>
  );
}

export default CustomerDetail;
