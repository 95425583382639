import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../dropdown/Dropdown";
import "./topbar.css";

import user_image from "../../assets/images/tuat.png";

import user_menu from "../../assets/JsonData/user_menus.json";
import { Link } from "react-router-dom";
import ThemeMenu from "../thememenu/ThemeMenu";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, authState } from "../../redux/features/AuthSlice";

// import notifications from '../../assets/JsonData/notification.json'

// import { collection, onSnapshot } from "firebase/firestore";
// import { db } from '../../base'

// const curr_user = {
//   display_name: "Welcome Admin",
//   image: user_image,
// };

// const renderNotificationItem = (item, index) => (
//     <div key={index} className="notification-item">
//         <i className={item.icon}></i>
//         <span>{item.content}</span>
//     </div>
// )

const renderUserToggle = (user) => (
<div className="topnav__right-user">
    <div className="topnav__right-user__image">
        <img src={user.image} alt="user image" />
    </div>

    <div className="user-details">
      <p/> 
      
        <div className="topnav__right-user__name">{user.name}
        <p>{user.user_role}</p></div>
       
    </div>
</div>


);

function Topnav(props) {
  const dispatch = useDispatch();
  const user = useSelector(authState);
  console.log(user);
  const topnavrightRef = useRef();
  const burgerRef = useRef();
  const [isActive, setActive] = useState("");

  const [currentUser, setCurrentUser] = useState({
    display_name: "Welcome Admin",
    image: user_image,
    role:"user_role",
    name:"fullName"
  });

  
  const mouseOut = (event) => {
    if (
      !props.sidebarRef.current.contains(event.target) &&
      !burgerRef.current.contains(event.target)
    ) {
      toggleFunc(event);
    }
  };

  function cleanMouseout() {
    document.removeEventListener("mousedown", mouseOut);
  }

  function toggleFunc(event) {
    props.sidebarRef.current.classList.toggle("sidebarhidden");
    topnavrightRef.current.classList.toggle("togindex");
    setActive(() => !isActive);

    if (
      props.sidebarRef.current.contains(event.target) ||
      burgerRef.current.contains(event.target)
    ) {
      document.addEventListener("mousedown", mouseOut);
    } else {
      setActive(() => false);
      cleanMouseout();
    }
  }
  const deletuser = () => {
    dispatch(deleteUser());
    localStorage.removeItem("user");
  };

 // ...
const getProfileRouteForRole = (role) => {
  const profileItem = user_menu.find(item => item.role === role && item.content === "Profile");
  return profileItem ? profileItem.route : null;
}

const rendeUserMenu = (item, index) => {
  // Eğer menü öğesi "Profile" ise ve bu kullanıcının rolü için değilse render etme
  if(item.content === "Profile" && item.route !== getProfileRouteForRole(currentUser.user_role)) {
      return null;
  }

  const route = determineRoute(item.route, currentUser.user_role);

  return (
      <Link to={`/evgrs${route}`} key={route}>
          {item.cikis ? (
              <div className="notification-item">
                  <i className={item.icon}></i>
                  <span>{item.content}</span>
              </div>
          ) : (
              <div onClick={() => deletuser()} className="notification-item">
                  <i className={item.icon}></i>
                  <span>{item.content}</span>
              </div>
          )}
      </Link>
  );
}
// ...


  useEffect(() => {
    if (user && user.user) {
      setCurrentUser({
        display_name: user.user.email,
        image: user.user.photoURL ? user.user.photoURL : user_image,
        user_role: user.user.role,
        name: user.user.fullName,
      });
    }
  }, [user]);



  const determineRoute = (itemRole, userRole) => {
    if (itemRole === "Profile") {
        switch(userRole) {
            case "Admin":
                return "/adminDetails";
            case "User":
                return "/userDetails";
            case "Driver":
                return "/driverDetails";
            case "Customer":
                return "/customerDetails";  // Burayı "customerDetails" veya başka bir rota ile güncelleyebilirsiniz.
            default:
                return itemRole;
        }
    } else {
        return itemRole;
    }
}

  /* mesaj verileri (okunmamış mesaj uzunluğu) */
  // const [activeMessageLength, setActiveMessageLength] = useState(null)

  // useEffect(() => {
  //     const unsub = onSnapshot(collection(db, "/helpCenter"), (snapshot) => {
  //         const notification = snapshot.docs.map((doc) => {
  //             return doc.data()
  //         });
  //         console.log("notif :", notification);
  //         const activeNotify = notification.filter(notify => notify.isRead === false)
  //         setActiveMessageLength(activeNotify.length);

  //     });
  //     return unsub;

  // }, []);

  return (
    <div className="topnav">
      <button
        ref={burgerRef}
        className={`hamburger ${isActive ? "is-active" : ""}`}
        onClick={(event) => toggleFunc(event)}
      >
        <div className="bar"></div>
      </button>
      <div className="topnav__search">
        {/*removed search bar and icon 
                <input type="text" placeholder='Search here...' />
                <i className='bx bx-search' ></i>
                */}
      </div>
      <div ref={topnavrightRef} className="topnav__right">
        <div className="topnav__right-item">
          <Dropdown
            customToogle={() => renderUserToggle(currentUser)}
            contentData={user_menu}
            renderItems={(item, index) => rendeUserMenu(item, index)}
          />
        </div>
        {/* <div className="topnav__right-item">

                    <Link to="/evgrs/notifications">
                        <Dropdown
                            icon='bx bx-bell'
                            badge={activeMessageLength?.toString()}
                        // contentData={notifications}
                        // renderItems={(item, index) => renderNotificationItem(item, index)}

                        />
                    </Link>

                </div> */}
        <div className="topnav__right-item ok">
          <ThemeMenu />
        </div>
      </div>
    </div>
  );
}

export default Topnav;
