import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Modal } from "../modal/Modal";
import "./selectLocation.css";
import React, { useEffect, useState, useRef } from "react";
import { db } from "../../base";

// Bölge adı girişini yapmak için kullanılacak modal bileşeni
function RegionNameModal({ isOpen, onClose, onSave, handleCancel }) {
  const [regionName, setRegionName] = useState("");

  const handleSave = () => {
    if (regionName.trim() !== "") {
      onSave(regionName);
      setRegionName("");
      onClose();
    }
  };

  const handleClose = () => {
    setRegionName("");
    handleCancel();
    onClose();
  };

  return (
    isOpen && (
      <Modal
        close={handleClose}
        children={
          <form className="region-name-modal">
            <input
              type="text"
              value={regionName}
              onChange={(e) => setRegionName(e.target.value)}
              placeholder="Enter region name"
            />
            <div className="region-name-modal-btns">
              <button
                type="submit"
                className="confirm-btn"
                onClick={handleSave}
              >
                Confirm
              </button>
              <button
                type="button"
                className="cancel-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        }
      />
    )
  );
}

function MapWithPolygonDrawing({
  videoCoordinates,
  setVideoCoordinates,
  sliderData,
  videoId,
}) {
  const mapRef = useRef(null);

  const [isRegionNameModalOpen, setIsRegionNameModalOpen] = useState(false);
  const [isHandleCancel, setIsHandleCancel] = useState(false);
  const [tempCoords, setTempCoords] = useState([]);

  const [selectedRegions, setSelectedRegions] = useState(
    videoCoordinates ? videoCoordinates : []
  );

  useEffect(() => {
    const mapOptions = {
      center:
        videoCoordinates && videoCoordinates.length > 0
          ? videoCoordinates[0].coords[0]
          : { lat: 40.17230962689747, lng: 28.83182955325589 },
      zoom: 13,
    };

    const map = new window.google.maps.Map(mapRef.current, mapOptions);

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
    });

    drawingManager.setMap(map);
    //  (event) => {
    //         if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
    //           const polygon = event.overlay;
    //           const coords = polygon.getPath().getArray();
    //           const region = {
    //             name: `Region ${selectedRegions.length + 1}`,
    //             coords: coords.map((coord) => ({
    //               lat: coord.lat(),
    //               lng: coord.lng(),
    //             })),
    //           };

    //           setSelectedRegions((prevRegions) => [...prevRegions, region]);
    //           setVideoCoordinates((prevCoordinates) => [
    //             ...prevCoordinates,
    //             region,
    //           ]);
    //         }
    //       }

    // overlaycomplete olayı içinde kullanılacak fonksiyon
    const handleOverlayComplete = (event) => {
      if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
        const polygon = event.overlay;
        const coords = polygon.getPath().getArray();

        // Bölge adı için modal açma işlemi
        setIsRegionNameModalOpen(true);
        setTempCoords(coords);
      }
    };

    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      handleOverlayComplete
    );

    // Burada seçilen bölgeleri haritada gösterme işlemini yapabilirsiniz.
    selectedRegions.forEach((region) => {
      const regionPolygon = new window.google.maps.Polygon({
        paths: region.coords,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      regionPolygon.setMap(map);
    });
  }, [selectedRegions, isHandleCancel]);

  // Bölge adını onayladığınızda çağrılacak fonksiyon
  const handleSaveRegionName = (name) => {
    const region = {
      name: name,
      coords: tempCoords.map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      })),
    };

    setSelectedRegions((prevRegions) =>
      prevRegions.length > 0 ? [...prevRegions, region] : [region]
    );
    setVideoCoordinates(
      videoCoordinates && videoCoordinates.length > 0
        ? [...videoCoordinates, region]
        : [region]
    );
  };

  const handleCancel = () => {
    setIsHandleCancel(!isHandleCancel);
    setIsRegionNameModalOpen(false); // Modal kapatma
    setTempCoords([]); // Geçici koordinatları sıfırlama
  };

  const removeRegion = async (regionName) => {
    const currentRegions = selectedRegions.filter(
      (region) => region.name !== regionName
    );

    console.log("currentRegions : ", currentRegions);
    setSelectedRegions(currentRegions);

    // Aynı zamanda videoCoordinates'dan da ilgili bölgeyi silmelisiniz.
    setVideoCoordinates(currentRegions);

    if (sliderData) {
      const docRef = doc(db, "tabletconfig", "sliderConfig");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const allSlider = docSnap.data().sliders || [];
        const updatedSlidersData = allSlider.map((slider) =>
          slider.id === sliderData.id
            ? {
                ...sliderData,
                coords:
                  currentRegions && currentRegions.length > 0
                    ? currentRegions
                    : null,
              }
            : slider
        );

        // Step 4: Save the updated slider data to Firebase Firestore
        await updateDoc(doc(db, "tabletconfig", "sliderConfig"), {
          sliders: updatedSlidersData,
        });
      }
    } else {
      console.log("No such document!");
    }

    if (videoId) {
      const refDoc = doc(db, "videoAds", videoId);
      await updateDoc(refDoc, {
        coords:
          currentRegions && currentRegions.length > 0 ? currentRegions : null,
      });
    }
  };

  return (
    <div className="select-location-container">
      <div ref={mapRef} style={{ width: "100%", height: "400px" }} />
      {selectedRegions.map((region) => (
        <div key={region.name} className="coord-box">
          <div className="coord-title">
            Region Name: {region.name}
            <button
              className="delete-btn"
              onClick={() => removeRegion(region.name)}
            >
              Delete
            </button>
          </div>
          {region.coords.map((coord, index) => (
            <div className="coord" key={index}>
              <p>
                <strong>Coord {index + 1}:</strong>
              </p>
              <p>
                <span className="color-coord">Lat: </span>
                {coord.lat}, <span className="color-coord">Lng: </span>
                {coord.lng}
              </p>
            </div>
          ))}
        </div>
      ))}
      {/* RegionNameModal bileşenini kullanarak bölge adını girmeyi sağlayın */}
      <RegionNameModal
        isOpen={isRegionNameModalOpen}
        onClose={() => setIsRegionNameModalOpen(false)}
        handleCancel={handleCancel}
        onSave={handleSaveRegionName}
      />
    </div>
  );
}

export default MapWithPolygonDrawing;
