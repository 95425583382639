import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import image from "../../../assets/images/tuat.png";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

import { db, store } from "../../../base";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { useForm } from "react-hook-form";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import SelectLocation from "../../../components/SeletLocation/SelectLocation";

function formatBudget(budget) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(budget);
}

export const MusteriVideolari = (props) => {
  const [userData] = useState(props.location.state);

  const { id } = useParams();

  function getFormattedToday() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate() + 1).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  console.log(getFormattedToday());
  const [openDiscountCouponModal, setOpenDiscountCouponModal] = useState(false);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const [discountCouponEndDate, setDiscountCouponEndDate] = useState("");

  const [openCoordModal, setOpenCoordModal] = useState(false);
  const [videoCoords, setVideoCoords] = useState([]);

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      // videoAdsTitle: userData?.title,
      // driverBudget: userData?.driverBudget,
      // totalBudget: userData?.totalBudget,
      // userBudget: userData?.userBudget,
      // descriotion: userData?.description,
      // videoTime: userData?.videoTime,

      coords: userData?.coords,
      discountCouponCode: userData?.discountCouponCode,
      discountCouponEndDate: userData?.discountCouponEndDate
        ? userData.discountCouponEndDate
        : getFormattedToday(),

      videoLangueUa: userData?.videoLangueUa,
      videoLangueRu: userData?.videoLangueRu,
      videoLangueEn: userData?.videoLangueEn,
      videoLangueTr: userData?.videoLangueTr,
      companyIcon: userData?.companyIcon,
      companyName: userData?.companyName,
      description: userData?.description,
      descriptionTr: userData?.descriptionTr,
      descriptionRu: userData?.descriptionRu,
      descriptionEn: userData?.descriptionEn,
      driverBudget: userData?.driverBudget,
      reviewList: userData?.reviewList,
      category: userData?.category,
      time: Date.now(),
      title: userData?.title,
      titleTr: userData?.titleTr,
      titleRu: userData?.titleRu,
      titleEn: userData?.titleEn,
      totalBudget: userData?.totalBudget,
      totalWatched: userData?.totalWatched,
      url: userData?.url,
      enUrl: userData?.enUrl,
      ruUrl: userData?.ruUrl,
      trUrl: userData?.trUrl,
      userBudget: userData?.userBudget,
      userUid: userData?.userUid,
      videoAdsBanner: userData?.videoAdsBanner,
      vbTabletAdsClick: userData?.vbTabletAdsClick,
      videoId: userData?.videoId,
      videoTime: userData?.videoTime,
      oneVideoPrice: userData?.oneVideoPrice,
      videoRate: userData?.videoRate,
      videoAdsBannerLink: userData?.videoAdsBannerLink,
      videoAdsBannerText: userData?.videoAdsBannerText,
    },
  });
  useEffect(() => {
    const fetchVideo = async () => {
      const videoRef = doc(db, "videoAds", id);
      const videoDoc = await getDoc(videoRef);
      if (videoDoc.exists()) {
        const videoData = videoDoc.data();
        // Update the form values using setValue
        setVideoCoords(videoData.coords);
        setDiscountCoupon(videoData.discountCouponCode);
        setDiscountCouponEndDate(
          videoData.discountCouponEndDate
            ? videoData.discountCouponEndDate
            : getFormattedToday()
        );
        setValue("coords", videoData.coords);
        setValue("discountCouponCode", videoData.discountCouponCode);
        setValue(
          "discountCouponEndDate",
          videoData.discountCouponEndDate
            ? videoData.discountCouponEndDate
            : getFormattedToday()
        );

        setValue("videoLangueUa", videoData.videoLangueUa);
        setValue("videoLangueRu", videoData.videoLangueRu);
        setValue("videoLangueEn", videoData.videoLangueEn);
        setValue("videoLangueTr", videoData.videoLangueTr);
        setValue("companyIcon", videoData.companyIcon);
        setValue("companyName", videoData.companyName);
        setValue("description", videoData.description);
        setValue("descriptionTr", videoData.descriptionTr);
        setValue("descriptionRu", videoData.descriptionRu);
        setValue("descriptionEn", videoData.descriptionEn);
        setValue("driverBudget", videoData.driverBudget);
        setValue("reviewList", videoData.reviewList);
        setValue("category", videoData.category);
        setValue("title", videoData.title);
        setValue("titleTr", videoData.titleTr);
        setValue("titleRu", videoData.titleRu);
        setValue("titleEn", videoData.titleEn);
        setValue("totalBudget", videoData.totalBudget);
        setValue("totalWatched", videoData.totalWatched);
        setValue("url", videoData.url);
        setValue("enUrl", videoData.enUrl);
        setValue("ruUrl", videoData.ruUrl);
        setValue("trUrl", videoData.trUrl);
        setValue("userBudget", videoData.userBudget);
        setValue("userUid", videoData.userUid);
        setValue("videoAdsBanner", videoData.videoAdsBanner);
        setValue("vbTabletAdsClick", videoData.vbTabletAdsClick);
        setValue("videoId", videoData.videoId);
        setValue("videoTime", videoData.videoTime);
        setValue("oneVideoPrice", videoData.oneVideoPrice);
        setValue("videoRate", videoData.videoRate);
        setValue("videoAdsBannerLink", videoData.videoAdsBannerLink);
        setValue("videoAdsBannerText", videoData.videoAdsBannerText);

        console.log("videoData : ", videoData);

        if (videoData.coords && videoData.coords.length > 0) {
          setOpenCoordModal(true);
        }
        if (videoData.discountCouponCode) {
          setOpenDiscountCouponModal(true);
        }
      } else {
        console.log("No such document!");
      }
    };
    fetchVideo();
  }, [id, setValue]);

  const [statedatam, setstatedatam] = useState({});
  const [filterRatingData, setFilterRatingData] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [snackShow, setSnackShow] = useState(false);

  /* enes */
  const [videoClickedsAndWatched, setVideoClickedsAndWatched] = useState();
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();

  const [fileEn, setFileEn] = useState();
  const [fileTr, setFileTr] = useState();
  const [fileRu, setFileRu] = useState();

  const [videoAdsBanner, setVideoAdsBanner] = useState(
    formState.defaultValues.videoAdsBanner
  );
  const [vbTabletAdsClick, setVbTabletAdsClick] = useState(
    formState.defaultValues.vbTabletAdsClick
  );

  const [adsVideo, setAdsVideoUrl] = useState(formState.defaultValues.url);
  const [adsVideoTr, setAdsVideoTrUrl] = useState(
    formState.defaultValues.trUrl
  );
  const [adsVideoEn, setAdsVideoEnUrl] = useState(
    formState.defaultValues.enUrl
  );
  const [adsVideoRu, setAdsVideoRuUrl] = useState(
    formState.defaultValues.ruUrl
  );

  const [langueTr, setLangueTr] = useState(
    formState.defaultValues.titleTr ? true : false
  );
  const [langueEn, setLangueEn] = useState(
    formState.defaultValues.titleEn ? true : false
  );
  const [langueUa, setLangueUa] = useState(false);
  const [langueRu, setLangueRu] = useState(false);

  const [dataUpdate, setDataUpdate] = useState({
    bannerUpdated: false,
    bannerInVideoUpdated: false,
    uaVideoUpdated: false,
    enVideoUpdated: false,
    trVideoUpdated: false,
    ruVideoUpdated: false,
  });

  const normalized = (value) => {
    return (
      value
        .match(/\d{1,2}/g)
        ?.join(":")
        .substr(0, 5) ?? ""
    );
  };

  const [videoCatagory, setVideoCatagory] = useState();
  const nows = useMemo(() => new Date(Date.now()), []);
  const week = useMemo(
    () => new Date(nows.getFullYear(), nows.getMonth(), nows.getDate() - 7),
    [nows]
  );
  const [barVaL] = useState();

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "categoryAds"), (snapshot) => {
      let admins = snapshot.docs.map((e) => {
        let a = e.data();
        return a;
      });
      setVideoCatagory(admins);
      setValue("category", formState.defaultValues.category);
    });
    return unsub;
  }, [setValue, formState.defaultValues.category]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackShow(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Fonksiyon, verilen klasördeki mevcut fotoğrafı siler
  const deleteCurrentPhoto = async (folder, currentPhoto) => {
    try {
      const photoRef = ref(store, `${folder}/${currentPhoto}`);
      console.log(photoRef);
      await deleteObject(photoRef);
      console.log(`Mevcut fotoğraf silindi: ${currentPhoto}`);
    } catch (error) {
      console.error(`Mevcut fotoğrafı silerken bir hata oluştu: ${error}`);
    }
  };

  const onSubmit = async (data) => {
    const refDoc = doc(db, "videoAds", formState.defaultValues.videoId);

    // let s = Date.now().toString()
    let s = formState.defaultValues.videoId.toString();
    let videosPromise = [];

    if (dataUpdate.bannerUpdated) {
      await deleteCurrentPhoto("videoBanner", formState.defaultValues.videoId);

      // banner güncellendiyse yükle
      const storageRef5 = ref(store, `videoBanner/${s}`);
      let banner = uploadBytes(storageRef5, file2);
      videosPromise.push(banner);
    }

    if (dataUpdate.bannerInVideoUpdated) {
      await deleteCurrentPhoto(
        "vbTabletAdsClick",
        formState.defaultValues.videoId
      );

      // bannerInVideo güncellendiyse yükle
      const storageRef6 = ref(store, `vbTabletAdsClick/${s}`);
      let bannerInVideo = uploadBytes(storageRef6, file3);
      videosPromise.push(bannerInVideo);
    }

    if (data.videoLangueUa && dataUpdate.uaVideoUpdated) {
      await deleteCurrentPhoto("videoAds2", formState.defaultValues.videoId);

      // uaVideo güncellendiyse yükle
      const storageRef = ref(store, `videoAds2/${s}`);
      let uaVideo = uploadBytes(storageRef, file);
      videosPromise.push(uaVideo);
    }

    if (data.videoLangueEn && dataUpdate.enVideoUpdated) {
      await deleteCurrentPhoto(
        "videoAds2/EnVideo",
        formState.defaultValues.videoId
      );

      // enVideo güncellendiyse yükle
      const storageRef2 = ref(store, `videoAds2/EnVideo/${s}`);
      let enVideo = uploadBytes(storageRef2, fileEn);
      videosPromise.push(enVideo);
    }

    if (data.videoLangueTr && dataUpdate.trVideoUpdated) {
      await deleteCurrentPhoto(
        "videoAds2/TrVideo",
        formState.defaultValues.videoId
      );

      // trVideo güncellendiyse yükle
      const storageRef4 = ref(store, `videoAds2/TrVideo/${s}`);
      let trVideo = uploadBytes(storageRef4, fileTr);
      videosPromise.push(trVideo);
    }

    if (data.videoLangueRu && dataUpdate.ruVideoUpdated) {
      await deleteCurrentPhoto(
        "videoAds2/RuVideo",
        formState.defaultValues.videoId
      );

      // ruVideo güncellendiyse yükle
      const storageRef3 = ref(store, `videoAds2/RuVideo/${s}`);
      let ruVideo = uploadBytes(storageRef3, fileRu);
      videosPromise.push(ruVideo);
    }

    // setbarVaL(10)
    Promise.all(videosPromise).then((values) => {
      // setbarVaL(70)
      let dowloadnurl = values.map((e) => getDownloadURL(e.ref));
      Promise.all(dowloadnurl).then((ress) => {
        // setbarVaL(90)
        let urlList = ress.reduce((arr, cur) => {
          if (cur.includes("EnVideo")) {
            arr["EnUrl"] = cur;
          } else if (cur.includes("RuVideo")) {
            arr["RuVideo"] = cur;
          } else if (cur.includes("TrVideo")) {
            arr["TrVideo"] = cur;
          } else if (cur.includes("videoBanner")) {
            arr["videoBanner"] = cur;
          } else if (cur.includes("vbTabletAdsClick")) {
            arr["vbTabletAdsClick"] = cur;
          } else {
            arr["url"] = cur;
          }
          return arr;
        }, {});

        // console.log("formState.url : ", formState.defaultValues.url);
        console.log("updateDoc : ", {
          coords: videoCoords && videoCoords.length > 0 ? videoCoords : null,
          discountCouponCode: openDiscountCouponModal
            ? discountCoupon
              ? discountCoupon
              : ""
            : null,
          discountCouponEndDate: openDiscountCouponModal
            ? discountCouponEndDate
              ? discountCouponEndDate
              : getFormattedToday()
            : null,

          videoLangueEn: data.videoLangueEn,
          videoLangueTr: data.videoLangueTr,
          description: data.description,
          descriptionTr: data.descriptionTr,
          descriptionRu: data.descriptionRu,
          descriptionEn: data.descriptionEn,
          driverBudget: parseInt(data.driverBudget),
          category: data.category,
          title: data.title,
          titleTr: data.titleTr,
          titleRu: data.titleRu,
          titleEn: data.titleEn,
          totalBudget: parseInt(data.totalBudget),
          url: urlList.url ? urlList.url : formState.defaultValues.url,
          enUrl: urlList.EnUrl ? urlList.EnUrl : formState.defaultValues.EnUrl,
          ruUrl: urlList.RuVideo
            ? urlList.RuVideo
            : formState.defaultValues.RuVideo,
          trUrl: urlList.TrVideo
            ? urlList.TrVideo
            : formState.defaultValues.TrVideo,
          userBudget: parseInt(data.userBudget),
          videoAdsBanner: urlList.videoBanner
            ? urlList.videoBanner
            : formState.defaultValues.videoBanner,
          vbTabletAdsClick: urlList.vbTabletAdsClick
            ? urlList.vbTabletAdsClick
            : formState.defaultValues.vbTabletAdsClick,
          videoTime: data.videoTime,
          oneVideoPrice: data.oneVideoPrice,
          videoRate: data.videoRate,
          videoAdsBannerLink: data.videoAdsBannerLink,
          videoAdsBannerText: data.videoAdsBannerText,
        });
        updateDoc(refDoc, {
          // description: data.descriotion,
          // driverBudget: data.driverBudget,
          // totalBudget: data.totalBudget,
          // userBudget: data.userBudget,
          // category: data.category,
          // title: data.videoAdsTitle,
          // videoTime: data.videoTime,

          // videoLangueUa: data.videoLangueUa,
          // videoLangueRu: data.videoLangueRu,

          coords: videoCoords && videoCoords.length > 0 ? videoCoords : null,
          discountCouponCode: openDiscountCouponModal
            ? discountCoupon
              ? discountCoupon
              : ""
            : null,
          discountCouponEndDate: openDiscountCouponModal
            ? discountCouponEndDate
              ? discountCouponEndDate
              : getFormattedToday()
            : null,

          videoLangueEn: data.videoLangueEn,
          videoLangueTr: data.videoLangueTr,
          description: data.description,
          descriptionTr: data.descriptionTr,
          descriptionRu: data.descriptionRu,
          descriptionEn: data.descriptionEn,
          driverBudget: parseInt(data.driverBudget),
          category: data.category,
          title: data.title,
          titleTr: data.titleTr,
          titleRu: data.titleRu,
          titleEn: data.titleEn,
          totalBudget: parseInt(data.totalBudget),
          url: urlList.url ? urlList.url : formState.defaultValues.url,
          enUrl: urlList.EnUrl ? urlList.EnUrl : formState.defaultValues.enUrl,
          ruUrl: urlList.RuVideo
            ? urlList.RuVideo
            : formState.defaultValues.ruUrl,
          trUrl: urlList.TrVideo
            ? urlList.TrVideo
            : formState.defaultValues.trUrl,
          userBudget: parseInt(data.userBudget),
          videoAdsBanner: urlList.videoBanner
            ? urlList.videoBanner
            : formState.defaultValues.videoAdsBanner,
          vbTabletAdsClick: urlList.vbTabletAdsClick
            ? urlList.vbTabletAdsClick
            : formState.defaultValues.vbTabletAdsClick,
          videoTime: data.videoTime,
          oneVideoPrice: data.oneVideoPrice,
          videoRate: data.videoRate,
          videoAdsBannerLink: data.videoAdsBannerLink,
          videoAdsBannerText: data.videoAdsBannerText,
        }).then((e) => {
          setSnackShow(true);
          setIsEdit(!isEdit);
        });
      });
    });
  };

  useEffect(() => {
    const q = query(
      collection(db, "analysis"),
      where("videoAdsId", "==", `${formState.defaultValues.videoId}`)
    );
    getDocs(q).then((data) => {
      let arry = data.docs.map((e) => e.data());
      let datam = arry.reduce(
        (acc, curr) => {
          let date = new Date(curr.time).toLocaleDateString();
          let date2 = new Date(curr.time);

          if (curr.label === "watch") {
            acc.tablet.push(curr);
            acc.totalwatch += 1;
            acc.ratetop += curr.rate;
            if (curr.rate === 0) {
              acc.minuslength += 1;
            }
            if (date2 >= week && date2 <= nows) {
              if (date in acc.haftalık) {
                acc.haftalık[date].push(curr);
              } else {
                acc.haftalık[date] = [];
                acc.haftalık[date].push(curr);
              }
            }
          } else {
            acc.mobil.push(curr);
            acc.totalwatch += 1;
          }
          return acc;
        },
        {
          mobil: [],
          tablet: [],
          totalwatch: 0,
          ratetop: 0,
          haftalık: {},
          minuslength: 0,
        }
      );

      setstatedatam(datam);

      const filteredRating = datam.tablet.filter((data) => data.rate !== null);
      setFilterRatingData(filteredRating);
    });
  }, [formState.defaultValues.videoId, week, nows]);

  /* banner link tıklama ve izleme verileri */
  useEffect(() => {
    onSnapshot(
      collection(
        db,
        `/videoAds/${formState.defaultValues.videoId}/notification`
      ),
      (snapshot) => {
        let notification = snapshot.docs.map((e) => {
          let a = e.data();
          return a;
        });

        setVideoClickedsAndWatched(notification[0]);
      }
    );
  }, [formState.defaultValues.videoId]);

  let tiklanmaOran;
  let LinkeGitmeOran;

  if (statedatam && videoClickedsAndWatched) {
    LinkeGitmeOran = (
      (videoClickedsAndWatched.openNotification /
        videoClickedsAndWatched.sendNotification) *
      100
    ).toFixed(2);
    tiklanmaOran = (
      (videoClickedsAndWatched.sendNotification / statedatam.tablet?.length) *
      100
    ).toFixed(2);
  }

  const uploadVideo = (value, filetype) => {
    if (value.target.files.length !== 0) {
      if (filetype === "En") {
        setDataUpdate({ ...dataUpdate, enVideoUpdated: true });

        setAdsVideoEnUrl(URL.createObjectURL(value.target.files[0]));
        setFileEn(value.target.files[0]);
      } else if (filetype === "Ru") {
        setDataUpdate({ ...dataUpdate, ruVideoUpdated: true });

        setAdsVideoRuUrl(URL.createObjectURL(value.target.files[0]));
        setFileRu(value.target.files[0]);
      } else if (filetype === "Tr") {
        setDataUpdate({ ...dataUpdate, trVideoUpdated: true });

        setAdsVideoTrUrl(URL.createObjectURL(value.target.files[0]));
        setFileTr(value.target.files[0]);
      } else {
        setDataUpdate({ ...dataUpdate, uaVideoUpdated: true });

        setAdsVideoUrl(URL.createObjectURL(value.target.files[0]));
        setFile(value.target.files[0]);
      }
    } else {
      if (file && adsVideo) {
        setAdsVideoUrl();
        setAdsVideoEnUrl();
        setAdsVideoRuUrl();
        setAdsVideoTrUrl();
        setFile();
      }
    }
  };

  /* Video Banner onChange */
  const handleBannerInputChange = (event) => {
    if (event.target.files.length !== 0) {
      // setVideoAdsBanner(URL.createObjectURL(event.target.files[0]))
      // setFile2(event.target.files[0])

      if (event.target.name === "videoAdsBanner") {
        setDataUpdate({ ...dataUpdate, bannerUpdated: true });

        setVideoAdsBanner(URL.createObjectURL(event.target.files[0]));
        setFile2(event.target.files[0]);
      } else if (event.target.name === "vbTabletAdsClick") {
        setDataUpdate({ ...dataUpdate, bannerInVideoUpdated: true });

        setVbTabletAdsClick(URL.createObjectURL(event.target.files[0]));
        setFile3(event.target.files[0]);
      }
    } else {
      if (file2 && videoAdsBanner && file3) {
        setVideoAdsBanner();
        setFile2();
        setVbTabletAdsClick();
        setFile3();
      }
    }
  };

  if (!id || id === "undefined") {
    console.log(id);
    return <div>Video Yok</div>;
  }

  if (!userData) {
    console.log("userData : ", userData);
    return <div>Video Silinmiş</div>;
  }

  return (
    <div>
      <div className="card mn-content">
        <div className="card__body ">
          <div className="user-details">
            <div className="user-details-1">
              <img
                className="br-15 obj-fit"
                src={
                  formState.defaultValues.videoAdsBanner?.pp !== "null"
                    ? formState.defaultValues.videoAdsBanner
                    : image
                }
                alt="pps"
                width={125}
                height={125}
              />
            </div>
            <div className="user-details-2">
              <div>{formState.defaultValues.companyName}</div>
              <div>
                {/* <div>
                                    <span>Başlık: {formState.defaultValues.titleEn || formState.defaultValues.titleTr}</span>
                                </div>
                                <div>
                                    <span>Açıklama: {formState.defaultValues.descriptionEn || formState.defaultValues.descriptionTr}</span>
                                </div> */}
                {/* <div>
                                    <i class='bx bx-globe' ></i><span>Kiev</span>
                                </div> */}
              </div>

              {/* <div>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est fugit minus id officia consectetur repellat quidem distinctio quos, modi, beatae quibusdam voluptate
                            </div> */}
            </div>
            <div className="user-details-3">
              <div>
                {/* <button className='btn-2' >LOGOUT</button> */}
{/*                 <button
                  className="btn-3"
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  {isEdit ? "DÜZENLE" : "İptal"}
                </button> */}
              </div>
              <div></div>
            </div>
          </div>
          <hr></hr>
          <div className="user-details-bot">
            <div className="user-details-bot-step-1">
              <div className="user-details-bot-icon">
                <i className="bx bx-globe"></i>
              </div>
              <div>
                <span>Total İzlenme Sayısı</span>
                <div>{statedatam?.totalwatch}</div>
              </div>
            </div>
            <div className="user-details-bot-step-1">
              <div className="user-details-bot-icon">
                <i className="bx bx-globe"></i>
              </div>
              <div>
                <span>Tablet İzlenme Sayisi</span>
                <div>{statedatam?.tablet?.length}</div>
              </div>
            </div>
            <div className="user-details-bot-step-1">
              <div className="user-details-bot-icon">
                <i className="bx bx-globe"></i>
              </div>
              <div>
                <span>Mobil izlenme sayisi</span>
                <div>{statedatam?.mobil?.length}</div>
              </div>
            </div>
            <div className="user-details-bot-step-1">
              <div className="user-details-bot-icon">
                <i className="bx bx-globe"></i>
              </div>
              <div>
                <span>Rate / ort</span>
                {/* <div>{(statedatam?.ratetop / (statedatam?.tablet?.length - statedatam?.minuslength)).toFixed(2)}</div> */}
                <div>
                  {statedatam?.ratetop /
                    (filterRatingData?.length - statedatam?.minuslength) >
                  0
                    ? (
                        statedatam?.ratetop /
                        (filterRatingData?.length - statedatam?.minuslength)
                      ).toFixed(2)
                    : 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mn-content">
        <div className="card__body ">
          <hr></hr>
          <div
            className="user-details-bot"
            style={{ justifyContent: "flex-start", gap: "1rem" }}
          >
            <div className="user-details-bot-step-1">
              <div className="user-details-bot-icon">
                <i className="bx bx-globe"></i>
              </div>
              <div>
                <span>Linke Gitme Sayısı</span>
                <div>{videoClickedsAndWatched?.openNotification}</div>
              </div>
            </div>
            <div className="user-details-bot-step-1">
              <div className="user-details-bot-icon">
                <i className="bx bx-globe"></i>
              </div>
              <div>
                <span>Banner Tıklama Sayısı</span>
                <div>{videoClickedsAndWatched?.sendNotification}</div>
              </div>
            </div>
          </div>
          {tiklanmaOran && LinkeGitmeOran && (
            <Chart
              options={{
                chart: {
                  id: "basic-bar",
                },
                xaxis: {
                  categories: [
                    "Banner Linke Tıklanma Oranı",
                    "Linke Gitme Oranı",
                  ],
                  labels: {
                    style: {
                      colors: "var(--txt-color)",
                    },
                  },
                },
                yaxis: {
                  max: 100,
                  min: 0,
                  labels: {
                    style: {
                      colors: "var(--txt-color)",
                    },
                  },
                },
              }}
              series={[
                {
                  name: "%",
                  data: [tiklanmaOran, LinkeGitmeOran],
                },
              ]}
              type="bar"
              width="100%"
              height={300}
            />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-7 col-md-12">
          <div className="card">
            <div className="user-details-card-header">
              <div>
                <div>Reklam Bilgiler</div>
              </div>
              <div>
                {/* <button className={`button-5 mg-left-5 ${tabindex === 0 ? 'activebutton' : ''}`} onClick={() => { settabindex(0) }} >Aylık</button>
                                <button className={`button-5 mg-left-5 ${tabindex === 1 ? 'activebutton' : ''}`} onClick={() => { settabindex(1) }}>Haftalık</button>
                                <button className={`button-5 mg-left-5 ${tabindex === 2 ? 'activebutton' : ''}`} onClick={() => { settabindex(2) }}>Günlük</button>
                                <button className={`button-5 mg-left-5 ${tabindex === 3 ? 'activebutton' : ''}`} onClick={() => { settabindex(3) }}>Özel</button> */}
              </div>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-body">
                  <div className="form-field">
                    <label>Video dil </label>

                    <div className="full-width">
                      <div className="row langue-group">
                        {/* <div>
                                                <div>UA</div>
                                                <input type="checkbox" className='form-input'  {...register("videoLangueUa")} onChange={(e) => {
                                                    e.target.checked === false && setAdsVideoUrl()
                                                    setLangueUa(!langueUa)
                                                }
                                                }
                                                />
                                            </div> */}
                        {/* <div>
                                                <div>RU</div>
                                                <input type="checkbox" className='form-input' {...register("videoLangueRu")} onChange={(e) => {
                                                    e.target.checked === false && setAdsVideoRuUrl()
                                                    setLangueRu(!langueRu)
                                                }
                                                }
                                                />
                                            </div> */}
                        <div>
                          <div>EN</div>
                          <input
                            type="checkbox"
                            disabled={isEdit}
                            className="form-input"
                            {...register("videoLangueEn")}
                            onChange={(e) => {
                              e.target.checked === false && setAdsVideoEnUrl();
                              setLangueEn(!langueEn);
                            }}
                          />
                        </div>
                        <div>
                          <div>TR</div>
                          <input
                            type="checkbox"
                            disabled={isEdit}
                            className="form-input"
                            {...register("videoLangueTr")}
                            onChange={(e) => {
                              e.target.checked === false && setAdsVideoTrUrl();
                              setLangueTr(!langueTr);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 
                                    <div className="form-field">
                                        <label >Video Ads Title(UA) </label>

                                        <div className='full-width'>
                                            <input type="text" className='form-input' disabled={isEdit} {...register("title", { required: true })} />
                                            {formState.errors.title && <div>Zorunlu alan</div>}
                                        </div>

                                    </div>

                                    <div className="form-field">
                                        <label >Video Ads Title(RU) </label>

                                        <div className='full-width'>
                                            <input type="text" className='form-input' disabled={isEdit} {...register("titleRu", { required: true })} />
                                            {formState.errors.titleRu && <div>Zorunlu alan</div>}
                                        </div>

                                    </div> */}

                  {langueTr && (
                    <div className="form-field">
                      <label>Video Ads Title(TR) </label>

                      <div className="full-width">
                        <input
                          type="text"
                          className="form-input"
                          disabled={isEdit}
                          {...register("titleTr", { required: true })}
                        />
                        {formState.errors.titleTr && <div>Zorunlu alan</div>}
                      </div>
                    </div>
                  )}

                  {langueEn && (
                    <div className="form-field">
                      <label>Video Ads Title(EN) </label>

                      <div className="full-width">
                        <input
                          type="text"
                          className="form-input"
                          disabled={isEdit}
                          {...register("titleEn", { required: true })}
                        />
                        {formState.errors.titleEn && <div>Zorunlu alan</div>}
                      </div>
                    </div>
                  )}

                  <div className="form-field">
                    <label>Video Category</label>
                    <div id="customers-selected">
                      <select
                        disabled={isEdit}
                        {...register("category", { required: true })}
                      >
                        <option></option>
                        {videoCatagory &&
                          videoCatagory.map((e, i) => (
                            <option key={i}>{e?.name}</option>
                          ))}
                      </select>
                      {formState.errors.category && <span>Zorunlu alan</span>}
                    </div>
                  </div>

          

            

             

       

          

   
               

                  {adsVideoTr && (
                    <>
                      <div className="form-field">
                       <label>Video TR</label>
                        <div className="full-width">
                          {
                
                          }
                          {formState.errors.adsVideoTr && (
                            <div>Zorunlu alan</div>
                          )}
                        </div>
                      </div>
                      {adsVideoTr && (
                        <div>
                          <video width="100%" controls>
                            <source src={adsVideoTr} type="video/mp4" />
                          </video>
                        </div>
                      )}
                    </>
                  )}

                  {langueEn && (
                    <>
                      <div className="form-field">
                        <label>Video EN</label>
                        <div className="full-width">
              
                          {formState.errors.adsVideoEn && (
                            <div>Zorunlu alan</div>
                          )}
                        </div>
                      </div>
                      {adsVideoEn && (
                        <div>
                          <video src={adsVideoEn} width="100%" controls>
                            <source type="video/mp4" />
                          </video>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* discount coupon */}
   
               

                {barVaL && (
                  <div>
                    Yükeleme İlerlemesi :{" "}
                    <progress id="file" value={barVaL} max="100"></progress>
                  </div>
                )}
                {!isEdit && <input className="denemes" type="submit" />}
              </form>
            </div>
          </div>
        </div>

        <div className="col-5 col-md-12">
          <div className="card">
            <div>Haftalık izlenme</div>

            <div>
              {statedatam && statedatam.haftalık && (
                <Chart
                  series={[
                    {
                      data: Object.keys(statedatam.haftalık)
                        .map((e) => statedatam["haftalık"][e].length)
                        .reverse(),
                      name: "İzlenme Sayısı",
                    },
                  ]}
                  type="line"
                  options={{
                    xaxis: {
                      categories: Object.keys(statedatam.haftalık).reverse(),
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <Snackbar
          open={snackShow}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Video update Success
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
