import { useEffect } from "react"
import images from "../../Asset/Img/image";
import "./Navbar.css"


function Navbar() {

  useEffect(() => {
    const handleClick = (event) => {
      event.preventDefault();
      const targetId = event.target.getAttribute("href");
      const targetElement = document.querySelector(targetId);
      targetElement.scrollIntoView({ behavior: "smooth" });
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener("click", handleClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleClick);
      });
    };
  }, []);

  return (
    <div className="navbar-container" id="navbar-container">
      <div className="navbar-content">
        <div className="logo">
          <img src={images.logo} width={250} height={230} alt="vimi-logo" />
        </div>
        <div className="nav-info-text">
          <h1>Most Enjoyable and
            <br />
            Profitable way of traveling
          </h1>
        </div>

        <ul className="nav-link">
          <li><a href="#tablet-video-container">What is Vimi ?</a></li>
          <li><a href="#car-container">How does it works ?</a></li>
          <li><a href="#mobile-app-container">Download</a></li>
          <li><a href="#footer-container">Contact</a></li>

        </ul>


      </div>
    </div>
  )
}

export default Navbar